import { useOutletContext } from 'react-router';
import { TColumn, TColumnFilter } from '@typedefs/app';
import { Category, Tag } from '@shared/types';
import type { DonorListHeader, DonorListItem } from '@pages/donors/List/config';
import type {
  DonationListHeader,
  DonationListItem,
} from '@pages/donations/tab/AllDonations/List/config';

// ----------------------------------------------------------------------
interface BaseContext {
  readonly dateFrom?: Date;
  readonly dateTo?: Date;
  readonly order: 'asc' | 'desc';
  readonly orderBy: TColumn;

  readonly filterCategories: Category.Category[];
  readonly filterTags: Tag.Tag[];
  readonly filters: TColumnFilter[];
}
interface DonorContext extends BaseContext {
  readonly type: 'donor';
  readonly columns: TColumn<DonorListHeader>[];
  readonly data: DonorListItem[];
}
interface DonationContext extends BaseContext {
  readonly type: 'donation';
  readonly columns: TColumn<DonationListHeader>[];
  readonly data: DonationListItem[];
}
export type DonorsOutletContext = DonorContext | DonationContext;

// ----------------------------------------------------------------------
export function useContext() {
  return useOutletContext<DonorsOutletContext>();
}
