import { useCallback, useEffect, useState } from 'react';
import { Alert, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { isEqual } from 'lodash';
import PATHS, { getPath } from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import Dialog from '@components/Dialog';
import StepActions from './StepActions';
import { BankDepositSchemaForm } from '@/schemas/bankDeposit';
import StepSummary from './Summary';
import StepDonations from './Donations';
import useDonation from '@hooks/useDonation';
import DuplicateConfirmDialog from './DuplicateConfirmDialog';
import useOrg from '@hooks/useOrg';
import { BankDeposit } from '@shared/types';
import useFormat from '@hooks/useFormat';

export type TStepProps = {
  readonly stepData: BankDepositSchemaForm;
  readonly updateStepData: (newData: Partial<BankDepositSchemaForm>) => void;
  readonly submittingData: {
    readonly isSubmitting: boolean;
    readonly progress: number;
  };
  readonly error: string;
  readonly setError: (error: string) => void;
  readonly isValid: boolean;
  readonly setIsValid: (valid: boolean) => void;
};
export enum StepId {
  donations,
  summary,
}
export type TStep = {
  readonly id: StepId;
  readonly title: string;
  readonly Component: (props: TStepProps) => JSX.Element | null;
};

type TUseSteps = {
  onComplete: (data: BankDepositSchemaForm) => void;
  title: string;
  actionText: string;
};

export const emptyStepData: Partial<BankDepositSchemaForm> = {
  date: new Date(),
  startDate: null,
  bankAccount: '',
  accountNumber: '',
  description: '',
  advantageAccount: '',
  advantageClass: '',
  donations: [],
  selectedDonationIds: [],
};

export default function useSteps({ onComplete, title, actionText }: TUseSteps) {
  const { bankDeposits } = useDonation();
  const { fDate } = useFormat();
  const { org } = useOrg();
  const navigate = useNavigate();
  const [stepData, setStepData] = useState<BankDepositSchemaForm>({
    ...emptyStepData,
    step: 0,
    date: new Date(),
  } as BankDepositSchemaForm);

  // --------------- state ---------------
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [existingDeposit, setExistingDeposit] = useState<BankDeposit.BankDeposit | null>(null);
  const [isDirty, setDirty] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [submittingData, setSubmittingData] = useState({
    isSubmitting: false,
    progress: 0,
  });
  const [error, setError] = useState<string>('');
  const [isValid, setIsValid] = useState(false);

  const steps: TStep[] = [
    {
      id: StepId.donations,
      title: 'Donations',
      Component: StepDonations,
    },
    {
      id: StepId.summary,
      title: 'Summary',
      Component: StepSummary,
    },
  ];

  const findExistingBankDeposit = useCallback(
    (params: {
      date: Date;
      bankAccount: string;
      accountNumber?: string;
      description?: string;
    }): BankDeposit.BankDeposit | undefined => {
      const searchDate = fDate(params.date);

      return bankDeposits.find((deposit) => {
        if (fDate(deposit.date) !== searchDate) return false;
        if (deposit.bankAccount.toLowerCase() !== params.bankAccount.toLowerCase()) return false;
        if (params.accountNumber && deposit.accountNumber !== params.accountNumber) return false;
        if (params.description && deposit.description !== params.description) return false;

        return true;
      });
    },
    [bankDeposits, fDate]
  );

  // --------------- actions ---------------
  const updateStepData = useCallback(
    (newData: Partial<BankDepositSchemaForm>) => {
      const update = { ...stepData, ...newData };
      if (isEqual(stepData, update)) return;

      setDirty(true);
      setStepData(update);
    },
    [stepData]
  );

  // --------------- navigation ---------------
  const handleBack = () => {
    setError('');
    setIsValid(true);
    updateStepData({ step: stepData.step - 1 });
  };

  const handleNext = () => {
    if (stepData.step === StepId.donations) {
      updateStepData({ step: stepData.step + 1 });
      return;
    }
    if (stepData.step === StepId.summary) {
      const existingDeposit = findExistingBankDeposit({
        date: stepData.date,
        bankAccount: stepData.bankAccount,
        accountNumber: stepData.accountNumber,
        description: stepData.description,
      });

      if (existingDeposit) {
        setShowDuplicateDialog(true);
        setExistingDeposit(existingDeposit);
        return;
      }

      onComplete(stepData);
      return;
    }
  };

  const handleClose = () => {
    navigate(PATHS.org.donations.bankDeposits.root);
  };

  // --------------- validation ---------------
  useEffect(() => {
    try {
      switch (stepData.step) {
        case StepId.donations:
          setIsValid(stepData.selectedDonationIds.length > 0);
          break;
        case StepId.summary:
          setIsValid(!!stepData.date && !!stepData.bankAccount);
          break;
        default:
          setIsValid(false);
      }
      setError('');
    } catch (e) {
      setError(e.errors?.[0] || 'Validation error');
      setIsValid(false);
    }
  }, [stepData]);

  // --------------- props ---------------
  const stepProps: TStepProps = {
    stepData,
    updateStepData,
    submittingData,
    error,
    setError,
    isValid,
    setIsValid,
  };

  // --------------- ui ---------------
  const ui = (
    <Dialog
      title={title}
      onClose={handleClose}
      maxWidth="xl"
      isDirty={isDirty}
      isLoading={submittingData.isSubmitting}
      contentProps={{ sx: { pt: 1, height: '90vh' } }}
      confirmText="Leave and lose progress?"
      actions={
        <StepActions
          stepData={stepData}
          handleNext={handleNext}
          handleBack={stepData.step !== 0 ? handleBack : undefined}
          disabled={!!error || !isValid}
          hasFailed={hasFailed}
          isSubmitting={submittingData.isSubmitting}
          error={error}
          actionText={actionText}
        />
      }
    >
      <Stack spacing={3} width="100%" height="100%" flexDirection="column">
        <Stepper activeStep={stepData.step} sx={{ px: 1 }}>
          {steps.map((step) => (
            <Step key={step.title} completed={stepData.step > steps.indexOf(step)}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {steps.map(
          (step, index) => stepData.step === index && <step.Component key={index} {...stepProps} />
        )}

        {!!error && <Alert severity="error">{error}</Alert>}
      </Stack>
      {showDuplicateDialog && existingDeposit && (
        <DuplicateConfirmDialog
          open={showDuplicateDialog}
          onClose={() => setShowDuplicateDialog(false)}
          onEdit={() => {
            setShowDuplicateDialog(false);
            navigate(
              getPath(PATHS.org.donations.bankDeposits.bankDepositsEdit, {
                orgId: org!.id,
                bankDepositId: existingDeposit.id,
              })
            );
          }}
          onContinue={() => {
            setShowDuplicateDialog(false);
            onComplete(stepData);
          }}
          existingDeposit={existingDeposit}
        />
      )}
    </Dialog>
  );

  return {
    ui,
    stepData,
    updateStepData,
    handleClose,
    setFailed: () => setFailed(true),
    setError,
    setSubmittingData,
  };
}
