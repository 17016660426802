import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/react';
import { groupBy } from 'lodash';

import * as analytics from '@fire/analytics';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { ReceiptReissueBatchSchema, ReceiptReissueBatchSchemaForm } from '@/schemas';
import { Receipt, ReceiptAction } from '@shared/types';
import { PageSize } from '@typedefs/app';
import { TReceiptDonorGroup, ReceiptDocumentProps } from '@typedefs/donation';

import useSteps, { StepId } from '../useSteps';
import StepReissueType from '../Reissue/steps/ReissueType';
import StepSendType from '../Create/steps/SendType';
import StepReceipts from './steps/Receipts';
import StepReviewReceipt from './steps/ReviewReceipt';
import StepReviewEmail from './steps/ReviewEmail';
import {
  renderTemplate,
  renderSubject,
  defaultState,
  defaultSubject,
} from '../Create/steps/ReviewEmail/EmailTemplate';
import StepOverview from './steps/Overview';

import { getReceiptPdfWorker } from '@workers/receiptPdfWorker';
// ----------------------------------------------------------------------

const pdfWorker = getReceiptPdfWorker();

export default function ReceiptsReissueBatch() {
  const { org } = useOrg();
  const { enqueueSnackbar } = useSnackbar();
  const { fDateToISO, fFullName } = useFormat();
  const {
    sendReceiptEmail,
    getReceiptReissueType,
    getReceiptDonorGroup,
    getReceiptDonations,
    reissueReceipts,
    getDonorById,
  } = useDonation();

  // --------------- effects ---------------
  const onComplete = (stepData: ReceiptReissueBatchSchemaForm) => onSubmit(stepData);
  const renderSteps = (stepData: ReceiptReissueBatchSchemaForm) => [
    {
      id: StepId.reissueType,
      title: 'Reissue Type',
      Component: StepReissueType,
    },
    {
      id: StepId.sendType,
      title: 'Send Type',
      Component: StepSendType,
    },
    {
      id: StepId.receiptsList,
      title: 'Receipt List',
      Component: StepReceipts,
    },
    {
      id: StepId.receiptReview,
      title: 'Receipt Review',
      Component: StepReviewReceipt,
    },
    ...(stepData.actionType === 'email'
      ? [
          {
            id: StepId.emailReview,
            title: 'Email Review',
            Component: StepReviewEmail,
          },
        ]
      : []),
    {
      id: StepId.overview,
      title: 'Final Submission',
      Component: StepOverview,
    },
  ];
  const { ui, setErrors, setFailed, setSubmittingData, handleClose } = useSteps(
    {
      Schema: ReceiptReissueBatchSchema,
      onComplete,
      renderSteps,
      title: 'Reissue Receipts',
      actionText: 'Reissue',
    },
    {
      rangeConfirmed: false,
      email: {
        subject: org?.receiptEmailSubject || defaultSubject,
        html: '',
        state: org?.receiptEmailBody || defaultState,
      },
    }
  );

  // --------------- actions ---------------
  const generatePdfAsync = async (props: ReceiptDocumentProps): Promise<{ blob: Blob; url: string }> => {  
    try {
      return await pdfWorker.generateReceiptPDF(props);
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };

  const onSubmit = async (stepData: ReceiptReissueBatchSchemaForm) => {
    setSubmittingData({ isSubmitting: true, progress: 1 });

    try {
      // this throws an error in validation
      await ReceiptReissueBatchSchema.validate(stepData, { abortEarly: false });

      const { actionType, letter, email, receipts, receiptingYear } = stepData;
      if (
        !org ||
        !actionType ||
        !receipts.length ||
        !receiptingYear ||
        !letter.size ||
        !letter.Doc
      ) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
        throw { errors: ['Missing required data!'] };
      }

      if (actionType === 'email') {
        if (!email.subject || !email.html) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
          throw { errors: ['Missing email data!'] };
        }
      }

      // const t = Sentry.startTransaction({
      //   name: 'Reissue receipts',
      //   op: 'processReceipts',
      //   data: { type: actionType },
      // }) as Sentry.Transaction;

      // generate receipts
      const { donorGroups } = await reissue(actionType, stepData);

      // give outcome
      if (actionType === 'email') {
        await processReceiptsEmail(donorGroups, stepData);
      } else {
        await processReceiptsPrint(donorGroups, stepData);
      }

      enqueueSnackbar('Receipt reissued!');
      analytics.donation.receiptReissue(donorGroups.length, actionType);
      handleClose();
    } catch (e) {
      enqueueSnackbar(`Error re-issuing receipts! ${e.message}`, { variant: 'error' });
      Sentry.captureException(e, {
        extra: {
          receiptFlow: 'reissueBatch',
          ...stepData,
          receipts: stepData.receipts.length,
        },
      });
      setErrors(e.errors);
      setFailed();
    }

    setSubmittingData({ isSubmitting: false, progress: 0 });
  };

  // --------------- issuing ---------------
  const reissue = async (
    issueType: ReceiptAction.ReceiptActionType,
    { receipts: dataReceipts, reissue, receiptingYear }: ReceiptReissueBatchSchemaForm
  ) => {
    setSubmittingData({ isSubmitting: true, progress: 2 });

    const reissues = dataReceipts.map((r) => {
      const { isNewReceipt, type } = getReceiptReissueType(r, reissue);
      const donationIds = getReceiptDonations(r).map((d) => d.id);
      return {
        donorId: r.donorId,
        receiptId: r.id,
        issueType,
        reissueType: type,
        issueNew: isNewReceipt,
        donationIds,
      };
    });

    const issueNew = reissues.some((r) => r.issueNew);
    const { oldReceipts, newReceipts } = await reissueReceipts({
      orgId: org!.id,
      year: receiptingYear!,
      date: fDateToISO(),
      issueNew,
      reissues,
    });

    const receiptsForGrouping = newReceipts.length ? newReceipts : oldReceipts;
    // We can sort by receipt number here, as we already have the ungrouped receipts
    const sortedReceiptsForGrouping = receiptsForGrouping.sort((a, b) => a.number - b.number);
    const receiptsGrouped = groupBy(sortedReceiptsForGrouping, 'donorId');
    const donorGroups = Object.values(receiptsGrouped).map((receipts) =>
      getReceiptDonorGroup(org!, receipts)
    );

    return { donorGroups };
  };

  // --------------- email ---------------
  const processReceiptsEmail = async (
    donorGroups: TReceiptDonorGroup[],
    data: ReceiptReissueBatchSchemaForm
  ) => {
    const { email, letter } = data;
    if (!org || !letter.Doc) return;

    // -------------------- generation --------------------
    setSubmittingData({ isSubmitting: true, progress: 3 });

    let batch: Receipt.ReceiptEmail[] = [];
    let batchSize = 0;

    // NOTE: issues emails and resets the batch
    const issueEmailBatch = async () => {
      await sendReceiptEmail({
        orgId: org.id,
        emailSignature: org.emailSignature!,
        emails: [...batch],
      });
      batch = [];
      batchSize = 0;
    };

    // iterate each donor and add to the batch
    let i = donorGroups.length;
    while (i--) {
      const donorGroup = donorGroups[i];
      const donor = getDonorById(donorGroup.donorId);

      const receiptDocument: ReceiptDocumentProps = {
        title: 'Receipt',
        description: 'Receipt',
        size: PageSize[letter.size!],
        donorGroups: JSON.stringify([donorGroup]),
        withLetter: data.letter.withLetter,
        options: data.options,
        year: donorGroup.groups[0].year,
        orientation: 'portrait',
      }

      const { blob, url } = await generatePdfAsync(receiptDocument);

      batch.push({
        to: `${fFullName(donor)} <${donor!.email!}>`,
        subject: renderSubject(donorGroup.vars, email.subject),
        body: renderTemplate(donorGroup.vars, email.html),
        attachment: url,
      });

      const { size } = blob; // bytes
      batchSize += size;
      // in case we get big in size, issue a separate batch
      // firebase functions have a limit of 10mb body size, so we cap on 5mb
      // 1000000 = 1mb
      if (batchSize >= 5 * 1e6) {
        await issueEmailBatch();
      }
    }

    // -------------------- send emails --------------------
    setSubmittingData({ isSubmitting: true, progress: 4 });

    // batching done, check if batch is not empty
    // if not empty, execute batch
    if (batch.length) {
      await issueEmailBatch();
    }

    // if on top of emailing, user wants to print
    if (data.emailAndPrint) {
      await processReceiptsPrint(donorGroups, data);
    }
  };

  // --------------- print ---------------
  const processReceiptsPrint = async (
    donorGroups: TReceiptDonorGroup[],
    data: ReceiptReissueBatchSchemaForm
  ) => {
    const { letter } = data;
    if (!org || !letter.Doc) return;
    if (donorGroups.length === 0) return;

    // -------------------- generation --------------------
    setSubmittingData({ isSubmitting: true, progress: 3 });

    const receiptDocument: ReceiptDocumentProps = {
      title: 'Receipt letter',
      description: 'Receipt letter',
      size: PageSize[letter.size],
      donorGroups: JSON.stringify(donorGroups),
      withLetter: data.letter.withLetter,
      options: data.options,
      year: donorGroups[0].groups[0].year,
      orientation: 'portrait',
    }

    try {
      const { blob } = await generatePdfAsync(receiptDocument);

      // -------------------- zipping --------------------
      setSubmittingData({ isSubmitting: true, progress: 4 });

      saveAs(blob, `${fDateToISO()}-receipts.pdf`);
    } catch (e) {
      console.error('Error generating PDF:', e);
      throw e;
    }
  };

  // --------------- UI ---------------
  return ui;
}
