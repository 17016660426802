import { useCallback, useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { filter, groupBy } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportLayout from '../../ReportLayout';
import SortDialog from '@pages/reports/Dialogs/Sort';
import { TableDateRange } from '@components/table';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import ReportViewer from '@pages/reports/ReportViewer';
import {
  AvailableSortOrder,
  SortOrder,
  sortByOrders,
  orderDescription,
} from '@pages/reports/reportSorting';
import { Donation } from '@shared/types';
import useLocales from '@hooks/useLocales';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const { t } = useLocales();
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    fDate,
    fCurrency,
    fDateToISO,
    fReversedName,
  } = useFormat();
  const { donors, donations, getCategoryById, getPaymentMethodById, bankDeposits } = useDonation();
  const { org } = useOrg();

  // ---------- state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('landscape');
  const [size, setSize] = useState<PageSize>('letter');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([
    { availablesIndex: 1 },
    { availablesIndex: 0 },
  ]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Name', field: (d) => d.name.toUpperCase() },
      { label: 'Date', field: (d) => fDateToISO(d.date) },
      { label: 'Amount', field: 'amount' },
      { label: 'Category', field: (d) => d.category.toUpperCase() },
      { label: 'Payment Method', field: (d) => d.paymentMethod.toUpperCase() },
      { label: 'Deposit Status', field: (d) => d.depositStatus.toUpperCase() },
    ],
    [fDateToISO]
  );

  const getDepositStatus = useCallback(
    (donation: Donation.Donation) => {
      if (donation.bankDepositId) {
        const deposit = bankDeposits.find((bd) => bd.id === donation.bankDepositId);
        return deposit ? fDate(deposit.date) : 'Error: Bank Deposit not found';
      }

      if (donation.amount <= 0) {
        return 'Omitted: Amount not > 0';
      }

      const paymentMethod = getPaymentMethodById(donation.paymentMethodId);
      if (paymentMethod && !paymentMethod.bankDeposit) {
        return 'Omitted: Payment Method excluded';
      }

      return `OK for Cash & ${t('Check')}s`;
    },
    [getPaymentMethodById, t, bankDeposits, fDate]
  );

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 22 },
      { name: 'Date', width: 10 },
      { name: 'Amount ($)', width: 10, align: 'right' },
      { name: 'Category', width: 15 },
      { name: 'Payment Method', width: 15 },
      { name: 'Deposit Status', width: 26 },
    ];

    let filtered = filter(donations, (d) =>
      isDateBetween(
        new Date(d.date),
        fDateToDayStart(dateFrom || minDateFrom),
        fDateToDayEnd(dateTo || maxDateTo)
      )
    );

    const grouped = groupBy(filtered, 'donorId');
    const itemsUnsorted = Object.keys(grouped)
      .map((donorId) => {
        const donor = donors.find((d) => d.id === donorId);
        return grouped[donorId].map((donation) => ({
          name: fReversedName(donor),
          date: new Date(donation.date),
          amount: donation.amount,
          category: getCategoryById(donation.categoryId)?.name || '',
          paymentMethod: getPaymentMethodById(donation.paymentMethodId)?.name || '',
          depositStatus: getDepositStatus(donation),
        }));
      })
      .flat();

    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((d) => [
      d.name,
      fDate(d.date),
      fCurrency(d.amount),
      d.category,
      d.paymentMethod,
      d.depositStatus,
    ]);

    const total = filtered.reduce((sum, d) => sum + d.amount, 0);
    const summary: PdfTableSummaryField[] = [
      { columns: 2, text: `Totals: ${items.length} donations` },
      { columns: 1, text: fCurrency(total) },
    ];

    return { header, items, summary };
  }, [
    donations,
    donors,
    sortOrders,
    availableSortOrders,
    dateFrom,
    dateTo,
    minDateFrom,
    maxDateTo,
    fCurrency,
    fDate,
    fReversedName,
    getCategoryById,
    getPaymentMethodById,
    getDepositStatus,
    isDateBetween,
    fDateToDayStart,
    fDateToDayEnd,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleReportLoading = (isLoading: boolean) => {
    setReportLoading(isLoading);
  };

  const handleComplete = (pdfBlob: Blob) => {
    setPdfBlob(pdfBlob);
  };

  return (
    <Dialog
      title="All Donations with Deposit Status report"
      maxWidth="lg"
      sx={{ '& .MuiPaper-root': { height: '100vh' } }}
      onClose={handleClose}
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          disabled={
            !isDateValid(dateFrom || minDateFrom) || !isDateValid(dateTo || maxDateTo) || !pdfBlob
          }
          loading={reportLoading}
          onClick={handleOpenMenu}
        >
          Download
        </LoadingButton>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'all_donations_with_deposit_status'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" height="100%">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9} height="100%">
          <ReportViewer
            onComplete={handleComplete}
            onLoading={handleReportLoading}
            documentProps={{
              title: name,
              description,
              dateReport: new Date(),
              dateFrom: dateFrom,
              dateTo: dateTo,
              orderBy: orderDescription(sortOrders, availableSortOrders),
              orgName: org!.name,
              data,
              orientation: orientation,
              size: PageSize[size],
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
