import { useMemo, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider, Document, Page, StyleSheet, View } from '@react-pdf/renderer';
import { orderBy, sumBy } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTable, PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportLayout from '../../ReportLayout';
import ReportHeader from '@pages/reports/ReportHeader';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import {
  CashCountMoneyType,
  CashCountOptionsCanada,
  CashCountOptionsUSA,
} from '@shared/types/cashCount';
import { BankDeposit } from '@shared/types';

const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Lato',
    fontSize: 10,
  },
  tableRow: {
    flexDirection: 'row',
    minHeight: 18,
  },
  tableRowItem: {
    padding: '2px 4px 0',
    width: '50%',
  },
});

interface BankDepositSlipProps extends ReportProps {
  bdId?: string;
  onClose?: () => void;
}

export default function Report({ id, name, description, bdId, onClose }: BankDepositSlipProps) {
  const navigate = useNavigate();
  const { fDate, fLongDate, fCurrency, fReversedName } = useFormat();
  const { bankDeposits, donors, donations, getPaymentMethodById, cashCounts } = useDonation();
  const { org } = useOrg();

  // State management
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [bankDepositId, setBankDepositId] = useState<string | undefined>(bdId);

  // Get ordered bank deposits and current deposit
  const orderedBankDeposits: BankDeposit.BankDeposit[] = useMemo(() => {
    const filtered = bankDeposits.filter((deposit) => {
      if (deposit.type !== BankDeposit.BankDepositType.cash) return false;

      const cashDonations = donations.filter(
        (d) =>
          d.bankDepositId === deposit.id && getPaymentMethodById(d.paymentMethodId)?.type === 'cash'
      );

      const expectedCashTotal = sumBy(cashDonations, 'amount');

      if (expectedCashTotal === 0) return true;

      const depositCashCount = cashCounts.find((count) => count.bankDepositId === deposit.id);

      if (!depositCashCount) return false;

      const options = org?.address.country === 'ca' ? CashCountOptionsCanada : CashCountOptionsUSA;

      const counts = depositCashCount.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.optionsId]: item.count,
        }),
        {} as Record<string, number>
      );

      const coinTotal = sumBy(
        options.filter((opt) => opt.type === CashCountMoneyType.coin),
        (opt) => (counts[opt.id] || 0) * opt.denomination
      );

      const billTotal = sumBy(
        options.filter((opt) => opt.type === CashCountMoneyType.bill),
        (opt) => (counts[opt.id] || 0) * opt.denomination
      );

      const total = coinTotal + billTotal;
      return total === expectedCashTotal;
    });

    const ordered = orderBy(filtered, 'date', 'desc');
    if (!bankDepositId && ordered.length) setBankDepositId(ordered[0].id);

    return ordered;
  }, [
    bankDepositId,
    bankDeposits,
    donations,
    cashCounts,
    org?.address.country,
    getPaymentMethodById,
  ]);

  const bankDeposit = useMemo(() => {
    if (bankDepositId) {
      return orderedBankDeposits.find((b) => b.id === bankDepositId);
    }
    return orderedBankDeposits[0];
  }, [bankDepositId, orderedBankDeposits]);

  // Get cash count for current deposit
  const cashCount = useMemo(() => {
    if (!bankDeposit?.id) return null;
    return cashCounts.find((count) => count.bankDepositId === bankDeposit.id);
  }, [bankDeposit, cashCounts]);

  const nonCashDonations = useMemo(
    () =>
      donations.filter((d) => {
        const paymentMethod = getPaymentMethodById(d.paymentMethodId);
        return d.bankDepositId === bankDepositId && paymentMethod?.type !== 'cash';
      }),
    [bankDepositId, donations, getPaymentMethodById]
  );

  const nonCashTotal = useMemo(() => sumBy(nonCashDonations, 'amount'), [nonCashDonations]);

  // Generate checks table data
  const checksTableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 70 },
      { name: 'Amount ($)', width: 30, align: 'right' },
    ];

    const items = nonCashDonations.map((d) => {
      const donor = donors.find((don) => don.id === d.donorId);
      return [fReversedName(donor), fCurrency(d.amount)];
    });

    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `Total ${org?.address.country === 'ca' ? 'Cheques' : 'Checks'}:` },
      { columns: 1, text: fCurrency(nonCashTotal) },
    ];

    return { header, items, summary };
  }, [donors, fCurrency, fReversedName, nonCashDonations, nonCashTotal, org?.address.country]);

  // Generate cash table data
  const cashTableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: '# Coins/Bills', width: 30 },
      { name: '', width: 5, align: 'center' },
      { name: 'Denomination', width: 35 },
      { name: '', width: 5, align: 'center' },
      { name: 'Total', width: 25, align: 'right' },
    ];

    const options = org?.address.country === 'ca' ? CashCountOptionsCanada : CashCountOptionsUSA;

    // Create a counts object from cashCount.items
    const counts =
      cashCount?.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.optionsId]: item.count,
        }),
        {} as Record<string, number>
      ) || {};

    const filteredOptions = options.filter((option) => counts[option.id] !== undefined);
    const items = filteredOptions.map((option) => {
      const count = counts[option.id];
      return [
        count ? count.toString() : '',
        'X',
        option.name ||
          `${fCurrency(option.denomination).replace('.00', '')} ${option.type === CashCountMoneyType.coin ? 'Coins' : 'Bills'}`,
        '=',
        count ? fCurrency(count * option.denomination) : '',
      ];
    });

    // Calculate totals using the same approach as CashCount component
    const coinsTotal = sumBy(
      options.filter((opt) => opt.type === CashCountMoneyType.coin),
      (opt) => (counts[opt.id] || 0) * opt.denomination
    );

    const billsTotal = sumBy(
      options.filter((opt) => opt.type === CashCountMoneyType.bill),
      (opt) => (counts[opt.id] || 0) * opt.denomination
    );

    const summary: PdfTableSummaryField[][] = [
      [
        { columns: 4, text: 'Total Coins:' },
        { columns: 1, text: fCurrency(coinsTotal) },
      ],
      [
        { columns: 4, text: 'Total Bills:' },
        { columns: 1, text: fCurrency(billsTotal) },
      ],
      [
        { columns: 4, text: 'Total Cash:' },
        { columns: 1, text: fCurrency(coinsTotal + billsTotal) },
      ],
      [
        { columns: 4, text: `Total ${org?.address.country === 'ca' ? 'Cheques' : 'Checks'}:` },
        { columns: 1, text: fCurrency(nonCashTotal) },
      ],
      [
        { columns: 4, text: 'TOTAL DEPOSIT:' },
        { columns: 1, text: fCurrency(coinsTotal + billsTotal + nonCashTotal) },
      ],
    ];

    return { header, items, summary };
  }, [cashCount, nonCashTotal, org?.address.country, fCurrency]);

  // Handle actions
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(PATHS.org.reports.root);
    }
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const { width, height } = PageSize[size];

  // Generate PDF document
  const ReportDoc = useMemo(() => {
    const pageSize =
      orientation === 'landscape' ? { width: height, height: width } : { width, height };
    const longDate = !!bankDeposit?.date ? fLongDate(new Date(bankDeposit.date)) : '';

    return (
      <Document
        title={`${name} - ${fLongDate(new Date())}`}
        subject={description}
        author="Software4Nonprofits"
        creator="s4np web-app"
        producer="s4np web-app"
        pdfVersion="1.7"
      >
        <Page style={styles.page} size={pageSize} wrap>
          <ReportHeader
            title={`Bank Deposit Slip for: ${longDate}`}
            filterDescription={`${bankDeposit?.description ? `Description: ${bankDeposit?.description}` : ''}`}
            dateReport={new Date()}
            dateFrom={null}
            orgName={org!.name}
          />
          <View style={styles.tableRow}>
            <View style={styles.tableRowItem}>
              <PdfTable data={checksTableData} />
            </View>
            <View style={styles.tableRowItem}>
              <PdfTable data={cashTableData} />
            </View>
          </View>
        </Page>
      </Document>
    );
  }, [
    orientation,
    width,
    height,
    name,
    description,
    bankDeposit,
    fLongDate,
    org,
    checksTableData,
    cashTableData,
  ]);

  const formatForSelect = (bd: (typeof bankDeposits)[0]): string =>
    `${fDate(bd.date)} ${bd.bankAccount}${bd.accountNumber ? ` ${bd.accountNumber}` : ''}${bd.description ? ` ${bd.description}` : ''}`;

  return (
    <Dialog
      title="Bank Deposit Slip"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!blob || !!error}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={[checksTableData, cashTableData]}
        pdfBlob={pdfBlob}
        basename={'bank_deposit_slip'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <FormControl>
                <InputLabel id="select-deposit">Select Deposit</InputLabel>
                <Select
                  value={bankDeposit?.id}
                  size="small"
                  labelId="select-deposit"
                  label="Select Deposit"
                  onChange={(e) => setBankDepositId(e.target.value)}
                  disabled={!!bdId}
                >
                  {orderedBankDeposits.map((bd) => (
                    <MenuItem key={bd.id} value={bd.id}>
                      {formatForSelect(bd)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
