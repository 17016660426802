import { addMonths } from 'date-fns';

import { parseDateTimezone } from '@redux/slices/donation';
import { calcNextOccurrence } from '@utils/recurringDonation';
import { RecurringDonationSchemaForm } from '@/schemas/recurringDonation';
import {
  Category,
  Organization,
  PaymentMethod as PM,
  RecurringDonation as RD,
} from '@shared/types';
import { TDonorWithDonations } from '@typedefs/donation';

// ----------------------------------------------------------------------
export default function getDefaultValues(
  getDonorById: (id: string) => TDonorWithDonations | undefined,
  getCategoryById: (id: string) => Category.Category | undefined,
  getPaymentMethodById: (id: string) => PM.PaymentMethod | undefined,
  recurringDonation?: RD.RecurringDonation,
  org?: Organization.Organization
): RecurringDonationSchemaForm {
  const donor = (recurringDonation && getDonorById(recurringDonation.donorId)) || null;

  const category =
    (recurringDonation?.categoryId && getCategoryById(recurringDonation.categoryId)) ||
    (org?.defaultCategoryId && getCategoryById(org.defaultCategoryId)) ||
    null;

  const paymentMethod =
    (recurringDonation?.paymentMethodId &&
      getPaymentMethodById(recurringDonation.paymentMethodId)) ||
    (org?.defaultPaymentMethodId && getPaymentMethodById(org.defaultPaymentMethodId)) ||
    null;

  const startDate =
    (recurringDonation && parseDateTimezone(recurringDonation.startDate)) || new Date();
  const period = recurringDonation?.period || RD.RecurringPeriod.monthly;
  const nextOccurrence = recurringDonation
    ? new Date(recurringDonation.nextOccurrence)
    : calcNextOccurrence(startDate, period);

  return {
    donor,
    amount: recurringDonation?.amount || '',
    category: category,
    paymentMethod: paymentMethod,
    nonReceiptable: recurringDonation?.nonReceiptable || false,

    state: recurringDonation?.state || RD.RecurringState.active,
    startDate,
    period,
    nextOccurrence,
    endingOption: recurringDonation?.endingOption || RD.RecurringEnding.never,
    endDate:
      (recurringDonation?.endDate && parseDateTimezone(recurringDonation.endDate)) ||
      addMonths(startDate, 2),
    occurrenceLimit: recurringDonation?.occurrenceLimit || '',
    occurrenceCount: recurringDonation?.occurrenceCount || 0,
  };
}
