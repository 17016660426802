import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

type FilterStackProps = {
  isDeleteRange?: boolean;
  receiptingYears: number[];
  receiptingYear: number | undefined;
  rangeFrom: number | undefined;
  rangeTo: number | undefined;
  rangeConfirmed: boolean;
  onReceiptingYear: (event: SelectChangeEvent<number>) => void;
  onFromChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onToChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onConfirm: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function FilterStack(props: FilterStackProps) {
  const { 
    isDeleteRange,
    receiptingYears,
    receiptingYear,
    rangeFrom,
    rangeTo,
    rangeConfirmed,
    onReceiptingYear,
    onFromChange,
    onToChange,
    onConfirm
  } = props;
  return (
    <Stack spacing={2} direction="row" width="100%" alignItems="center">
      <Card sx={{ py: 1, px: 2 }}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="button" sx={{ textTransform: 'inherit' }}>
            Receipting year
          </Typography>

          <Select
            required
            size="small"
            value={receiptingYear || ''}
            onChange={onReceiptingYear}
            sx={{ minWidth: 100 }}
          >
            {receiptingYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Card>

      <Card sx={{ py: 1, px: 2 }}>
        <Stack direction="row" spacing={2}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="button" sx={{ textTransform: 'inherit' }}>
              Receipt #s included
            </Typography>

            <TextField
              required
              size="small"
              type="number"
              value={rangeFrom || ''}
              inputProps={{ maxLength: 6 }}
              sx={{ width: 85 }}
              onChange={onFromChange}
            />
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="button" sx={{ textTransform: 'inherit' }}>
              To
            </Typography>

            {isDeleteRange
            ? <Typography>{rangeTo}</Typography>
            : <TextField
              size="small"
              type="number"
              value={rangeTo || ''}
              inputProps={{ maxLength: 6 }}
              sx={{ width: 85 }}
              onChange={onToChange}
            />}
          </Stack>
        </Stack>
      </Card>

      {!isDeleteRange && rangeFrom && rangeTo && (
        <Card sx={{ py: 1, px: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={rangeConfirmed} onChange={onConfirm} />}
              label={`Confirm reissue #${rangeFrom}-${rangeTo}`}
            />
          </FormGroup>
        </Card>
      )}
    </Stack>
  )
}