import { useState } from 'react';
import { Stack, Typography, Card, Box, Button } from '@mui/material';
import { TStepProps } from './useSteps';
import DonationsTable from './DonationsTable';
import { TColumn } from '@typedefs/app';
import useDonation from '@hooks/useDonation';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import useFormat from '@hooks/useFormat';

export default function StepDonations({ stepData, updateStepData }: TStepProps) {
  const { getEligibleBankDepositDonations } = useDonation();
  const { getDateFormat } = useFormat();
  const { selectedDonationIds, donations } = stepData;
  const [showSelectAll, setShowSelectAll] = useState(false);

  const handleDateChange = (date: Date | null) => {
    updateStepData({
      startDate: date || null,
      donations: date ? getEligibleBankDepositDonations(date) : [],
      selectedDonationIds: [],
    });
  };

  const handleToggleAll = () => {
    updateStepData({
      selectedDonationIds: showSelectAll ? [] : donations.map((d) => d.id),
    });
    setShowSelectAll(!showSelectAll);
  };

  const tableCols: TColumn[] = [
    {
      id: 'select',
      type: 'checkbox',
      label: '',
      align: 'center',
      width: 50,
      visible: true,
    },
    {
      id: 'reversedName',
      type: 'string',
      label: 'Name',
      align: 'left',
      visible: true,
    },
    {
      id: 'date',
      type: 'date',
      label: 'Date',
      align: 'left',
      visible: true,
    },
    {
      id: 'amount',
      type: 'currency',
      label: 'Amount ($)',
      align: 'right',
      visible: true,
    },
    {
      id: 'category',
      label: 'Category',
      align: 'left',
      visible: true,
    },
    {
      id: 'paymentMethod',
      type: 'string',
      label: 'Payment Method',
      align: 'left',
      visible: true,
    },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography>Include Donations starting from :</Typography>
        <DesktopDatePicker
          format={getDateFormat()}
          value={stepData.startDate}
          onChange={(value) => handleDateChange(value)}
        />
      </Stack>

      <Typography variant="body2" color="text.secondary">
        Note: We will display donations made on or after the date you specify, up to the end of that
        calendar year.
      </Typography>

      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          {donations.length > 0 && (
            <Box sx={{ alignSelf: 'flex-start' }}>
              <Button onClick={handleToggleAll} variant="outlined">
                {showSelectAll ? 'Unselect All' : 'Select All'}
              </Button>
            </Box>
          )}
          <DonationsTable
            donations={donations}
            selectedIds={selectedDonationIds}
            onSelectionChange={(id: string) => {
              const newSelected = selectedDonationIds.includes(id)
                ? selectedDonationIds.filter((sid) => sid !== id)
                : [...selectedDonationIds, id];
              updateStepData({ selectedDonationIds: newSelected });
            }}
            columns={tableCols}
          />
        </Stack>
      </Card>
    </Stack>
  );
}
