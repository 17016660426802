import { useMemo } from 'react';
import { orderBy as _orderBy } from 'lodash';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { RecurringDonationListHeader, RecurringDonationListItem } from './config';
import { calcFinalOccurrence, getFrequencyLabel, PeriodLabel } from '@utils/recurringDonation';
import { parseDateTimezone } from '@redux/slices/donation';
import { RecurringDonation as RD } from '@shared/types';

// ----------------------------------------------------------------------
type Props = {
  readonly order: 'asc' | 'desc';
  readonly orderBy: RecurringDonationListHeader;
};
// ----------------------------------------------------------------------
export default function useData({ order, orderBy }: Props) {
  const { recurringDonations, getDonorById } = useDonation();
  const { fReversedName, fDate, fCurrency } = useFormat();
  const currentYear = new Date().getFullYear();

  // ----- PARSING -------
  const parsedData = useMemo(
    () =>
      recurringDonations.map((rd: RD.RecurringDonation): RecurringDonationListItem => {
        const donor = getDonorById(rd.donorId);
        const isExpired = rd.state === RD.RecurringState.expired;
        const finalOccurrence = isExpired
          ? [rd.nextOccurrence]
          : calcFinalOccurrence(parseDateTimezone(rd.startDate), rd.period, {
              endingOption: rd.endingOption,
              occurrenceLimit: rd.occurrenceLimit,
              endDate: rd.endDate ? parseDateTimezone(rd.endDate) : undefined,
            });

        return {
          id: rd.id,
          name: fReversedName(donor, currentYear),
          frequency: `${PeriodLabel[rd.period]} ${getFrequencyLabel(parseDateTimezone(rd.startDate), rd.period)}`,
          amount: fCurrency(rd.amount),
          finalOccurrence: finalOccurrence[0] ? fDate(finalOccurrence[0]) : 'No End Date',
          nextOccurrence: isExpired ? 'n/a' : rd.nextOccurrence ? fDate(rd.nextOccurrence) : null,
          isExpired,
        };
      }),
    [recurringDonations, getDonorById, currentYear, fReversedName, fDate, fCurrency]
  );

  // ----- ORDERING -------
  const orderedData = useMemo(
    () => _orderBy(parsedData, orderBy, order),
    [parsedData, orderBy, order]
  );

  return orderedData;
}
