import { useMemo } from 'react';
import { orderBy as _orderBy } from 'lodash';

import useFormat from '@hooks/useFormat';
import { BankDepositListItem } from './config';
import { BankDeposit } from '@shared/types';
import useDonation from '@hooks/useDonation';

// ----------------------------------------------------------------------
export default function useData() {
  const { calcBankDepositTotal, bankDeposits } = useDonation();
  const { fDate, fCurrency } = useFormat();

  // ----- PARSING -------
  const parsedData = useMemo(
    () =>
      bankDeposits?.map((bankDeposit: BankDeposit.BankDeposit): BankDepositListItem => {
        const totalDeposit = calcBankDepositTotal(bankDeposit?.id);
        return {
          id: bankDeposit.id,
          date: fDate(bankDeposit?.date),
          bankAccount: bankDeposit?.bankAccount,
          description: bankDeposit?.description || null,
          accountNumber: bankDeposit?.accountNumber || null,
          totalDeposit: fCurrency(totalDeposit),
          type: bankDeposit?.type,
        };
      }),
    [bankDeposits, calcBankDepositTotal, fDate, fCurrency]
  );

  // ----- ORDERING -------
  const orderedData = useMemo(() => _orderBy(parsedData, 'date', 'desc'), [parsedData]);

  return orderedData;
}
