export type ReceiptAction = {
  readonly userId: string;
  readonly createdAt?: string;
  readonly type: ReceiptActionType;
  readonly context?: Record<string, unknown>;
};

export type ReceiptActionsForReceipt = {
  receiptId?: string;
  actionType: ReceiptActionType;
  reissueType?: ReceiptActionType;
  userId: string;
  donationIds?: string[];
  receiptRefId?: string;
  isOldReceipt?: boolean;
  isDeleting?: boolean;
};

export enum ReceiptActionType {
  // issue actions
  email = 'email',
  print = 'print',

  // reissue states
  replaced = 'replaced',
  corrected = 'corrected',
  replacement = 'replacement',
  correction = 'correction',
  deleted = 'deleted',
  revalidated = 'revalidated',

  // receipt (or under the hood) actions
  donationsAdded = 'donations_added', // donations added to the receipt (in re-issue flow)
  donationCorrected = 'donation_corrected', // donation under receipt changed, so we "invalidate" receipt
  donationDeleted = 'donation_deleted', // donation under receipt deleted, so we "invalidate" receipt
}

export type DefaultReceiptActionParams = {
  readonly userId: string;
  readonly now: string;
};

export type RevalidatedReceiptActionParams = DefaultReceiptActionParams & {
  readonly receiptId: string;
};

export type ReissueReceiptActionParams = DefaultReceiptActionParams & {
  readonly issueType: ReceiptActionType;
  readonly reissueType: ReceiptActionType;
  readonly receiptRefId: string;
  readonly donationIds?: string[];
  readonly updateExisting?: boolean;
  readonly isOldReceipt?: boolean;
};

export type DonationActionContextParams = DefaultReceiptActionParams & {
  readonly donationIds?: string[];
};
