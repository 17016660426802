import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { addYears, isWithinInterval, subYears, subDays, addDays } from 'date-fns';
import { Donor, Category, PaymentMethod, RecurringDonation as RD } from '@shared/types';
import { Limits } from '@shared/limits';

const dateTimeframeLimitTest = (val: Date) =>
  val
    ? isWithinInterval(val, {
        start: subDays(subYears(new Date(), 10), 1),
        end: addDays(addYears(new Date(), 1), 1),
      })
    : true;

// ----------------------------------------------------------------------
const RecurringDonationSchema = Yup.object().shape({
  donor: Yup.object().nullable().required('Donor is required'),

  amount: Yup.number()
    .typeError('Amount is required')
    .required('Amount is required')
    .moreThan(0, 'Amount must be greater than zero'),
  category: Yup.object().nullable().required('Category is required'),

  startDate: Yup.date()
    .typeError('Start Date is required')
    .required('Start Date is required')
    .test(
      'timeframe-invalid',
      'Timeframe is invalid, must be between -10/+1 year',
      dateTimeframeLimitTest
    ),

  period: Yup.string<RD.RecurringPeriod>()
    .oneOf(
      Object.values(RD.RecurringPeriod),
      'Period must be one of "Weekly", "Bi-Weekly" or "Monthly"'
    )
    .required('Period is required'),

  endingOption: Yup.string<RD.RecurringEnding>()
    .oneOf(Object.values(RD.RecurringEnding), 'Field must be one of "never", "on", "after"')
    .required('End is required'),

  endDate: Yup.date().when('endingOption', (endingOption, schema) => {
    if (endingOption && endingOption[0] === RD.RecurringEnding.on) {
      return schema
        .typeError('Ending Date is required')
        .required('Ending Date is required')
        .test(
          'timeframe-invalid',
          'Timeframe is invalid, must be between -10/+1 year',
          dateTimeframeLimitTest
        )
        .test('endDate-after-startDate', 'Ending Date must be after Start Date', function (value) {
          const { startDate } = this.parent;
          return !startDate || !value || value >= startDate;
        });
    }
    return schema.nullable();
  }),

  occurrenceLimit: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when('endingOption', (endingOption, schema) => {
      if (endingOption && endingOption[0] === RD.RecurringEnding.after) {
        return schema
          .typeError('Number of occurrences must be a valid number')
          .required('Number of occurrences is required')
          .test(
            'is-in-range',
            `Number of occurrences must be between ${Limits.recurringDonationOccurrencesMin} and ${Limits.recurringDonationOccurrencesMax}`,
            (value: any) => {
              const number = parseInt(value, 10);
              return (
                number >= Limits.recurringDonationOccurrencesMin &&
                number <= Limits.recurringDonationOccurrencesMax
              );
            }
          );
      }
      return schema.nullable();
    }),

  paymentMethod: Yup.object().nullable(),
});

export const recurringDonationSchemaResolver = yupResolver(RecurringDonationSchema);

export type RecurringDonationSchemaForm = {
  donor: Donor.Donor | null;
  category: Category.Category | null;
  paymentMethod: PaymentMethod.PaymentMethod | null;
  amount: number | string;
  nonReceiptable: boolean;

  state: RD.RecurringState;
  startDate: Date;
  period: RD.RecurringPeriod;
  nextOccurrence: Date;
  occurrenceCount: number;

  endingOption: RD.RecurringEnding;
  endDate: Date;
  occurrenceLimit: number | string;

  afterSubmit?: string;
};
