import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

import useDonation from '@hooks/useDonation';
import Iconify from '@components/Iconify';
import FeedbackDialog from '@/layouts/AppLayout/Navbar/Feedback/FeedbackDialog';
import { Limits } from '@shared/limits';
import DefaultPaymentMethod from './DefaultPaymentMethod';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';
import { TableHeadCustom } from '@components/table';
import Scrollbar from '@components/Scrollbar';
import useTable from '@hooks/useTable';
import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------
export default function PaymentMethods() {
  const { paymentMethods, hasBankDeposits } = useDonation();
  const [feedbackOpen, feedbackSetOpen] = useState(false);
  const navigate = useNavigate();
  const { org } = useOrg();

  const TABLE_HEAD: TColumn[] = [
    { id: 'name', type: 'string', label: 'Name', align: 'left', visible: true },
    {
      id: 'bankDeposit',
      type: 'string',
      label: 'Bank Deposit',
      align: 'left',
      visible: hasBankDeposits,
    },
  ];
  const { columns } = useTable({
    defaultRowsPerPage: 50,
    defaultColumns: TABLE_HEAD,
    // defaultOrderBy: 'dateStart',
    // defaultOrder: 'desc',
  });
  // --------------- effects ---------------
  const donationPaymentMethods = useMemo(
    () => sortBy(paymentMethods, (p) => p.name.toUpperCase()),
    [paymentMethods]
  );

  // --------------- actions ---------------
  const handleAdd = () => {
    navigate(getPath(PATHS.org.donations.settings.PaymentMethodsCreate, { orgId: org!.id }));
  };

  const handleEdit = (paymentMethodId: string) => {
    navigate(
      getPath(PATHS.org.donations.settings.PaymentMethodsEdit, { orgId: org!.id, paymentMethodId })
    );
  };

  return (
    <Stack marginTop={3}>
      <DefaultPaymentMethod />
      <Divider sx={{ my: 1, borderStyle: 'solid' }} />

      <Stack>
        <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
          <Scrollbar>
            <Table stickyHeader size="small">
              <TableHeadCustom
                // order={order}
                // orderBy={orderBy}
                columns={columns}
                rowCount={donationPaymentMethods.length}
                // onSort={onSort}
              />

              <TableBody>
                {donationPaymentMethods.map((pm) => (
                  <TableRow
                    key={pm.id}
                    hover
                    sx={{ cursor: 'pointer', height: 44 }}
                    onClick={() => handleEdit(pm.id)}
                  >
                    <TableCell align="left">{pm.name}</TableCell>
                    {hasBankDeposits && (
                      <TableCell align="left">{pm.bankDeposit ? 'Yes' : 'No'}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <Stack alignItems="flex-end">
          {paymentMethods.length < Limits.paymentMethods ? (
            <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleAdd()}>
              New Payment Method
            </Button>
          ) : (
            <Typography>
              You're reached the limit of {Limits.paymentMethods} Payment Methods. Please{' '}
              <Link sx={{ cursor: 'pointer' }} onClick={() => feedbackSetOpen(true)}>
                contact us
              </Link>{' '}
              if this is a problem for you.
            </Typography>
          )}
        </Stack>
      </Stack>

      <FeedbackDialog open={feedbackOpen} setOpen={feedbackSetOpen} />
    </Stack>
  );
}
