import { ChangeEvent, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router';
import { Grid, SelectChangeEvent } from '@mui/material';

import useDonation from '@hooks/useDonation';

import { TDonorWithDonations } from '@typedefs/donation';
import { ReceiptReissueBatchSchemaForm } from '@/schemas';
import FilterStack from '@pages/receipts/shared/FilterStack';
import { TStepProps } from '../../../useSteps';
import ReceiptList from '../../../shared/ReceiptList';
import { inRange, isNumber, maxBy, uniq, orderBy } from 'lodash';

// ----------------------------------------------------------------------
export default function StepReceipts({
  stepData,
  updateStepData,
}: TStepProps<ReceiptReissueBatchSchemaForm>) {
  const { getDonorById, getDonorReceiptIssues, receipts: allReceipts } = useDonation();

  // --------------- variables ---------------
  // filter all receipts that match this receipting year + fit the date range
  const receiptingYears = useMemo(() => orderBy(uniq(allReceipts.map((r) => r.year)), "desc"), [allReceipts]);
  const { receiptingYear, rangeFrom, rangeTo, rangeConfirmed } = stepData;
  const receipts = useMemo(
    () =>
      receiptingYear && rangeFrom
        ? allReceipts.filter(
            (r) =>
              !r.replacedById &&
              r.year === receiptingYear &&
              inRange(r.number, rangeFrom || 0, rangeTo ? rangeTo + 1 : Infinity)
          )
        : [],
    [allReceipts, receiptingYear, rangeFrom, rangeTo]
  );
  const [receiptDonors, issues] = useMemo(() => {
    const donorIds = uniq(receipts.map((r) => r.donorId));
    const donors = donorIds.map(getDonorById).filter(Boolean) as TDonorWithDonations[];
    const issues: { [donorId: string]: string[] } = {};
    donors.forEach((donor) => {
      const donorIssues = getDonorReceiptIssues(donor, stepData.actionType!);
      if (donorIssues.length) {
        issues[donor.id] = donorIssues;
      }
    });
    return [donors, issues];
  }, [getDonorById, getDonorReceiptIssues, receipts, stepData.actionType]);

  // --------------- effects ---------------
  useEffect(
    () => updateStepData({ receipts, issues: Object.keys(issues).length }),
    [updateStepData, issues, receipts]
  );

  useEffect(() => {
    if (!receiptingYear && receiptingYears.length) {
      const defaultReceiptingYear = receiptingYears[0];
      const rangeTo = maxBy(
        allReceipts.filter((r) => r.year === defaultReceiptingYear),
        (r) => r.number
      )?.number;
      updateStepData({ receiptingYear: defaultReceiptingYear, rangeFrom: 1, rangeTo });
    }
  }, [updateStepData, allReceipts, receiptingYear, receiptingYears]);

  // --------------- actions ---------------
  const handleReceiptingYear = (e: SelectChangeEvent<number>) => {
    const val = e.target.value;
    const receiptingYear = isNumber(val) ? val : parseInt(val);
    const rangeTo = maxBy(
      allReceipts.filter((r) => r.year === receiptingYear),
      (r) => r.number
    )?.number;
    updateStepData({
      receiptingYear: receiptingYear || undefined,
      rangeFrom: 1,
      rangeTo,
      rangeConfirmed: false,
    });
  };

  const handleFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val.length < 7) {
      updateStepData({ rangeFrom: parseInt(e.target.value) || undefined, rangeConfirmed: false });
    }
  };

  const handleToChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val.length < 7) {
      updateStepData({ rangeTo: parseInt(e.target.value) || undefined, rangeConfirmed: false });
    }
  };

  const handleConfirmReissue = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ rangeConfirmed: e.target.checked });
  };

  return (
    <Grid container spacing={0}>
      <Outlet />

      <Grid item xs={12} sx={{ mb: 2 }}>
        <FilterStack
          receiptingYears={receiptingYears}
          receiptingYear={receiptingYear}
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          rangeConfirmed={rangeConfirmed}
          onReceiptingYear={handleReceiptingYear}
          onFromChange={handleFromChange}
          onToChange={handleToChange}
          onConfirm={handleConfirmReissue}
        />
      </Grid>

      <Grid item xs={12}>
        <ReceiptList
          receipts={receipts}
          receiptDonors={receiptDonors}
          issues={issues}
          reissue={stepData.reissue}
        />
      </Grid>
    </Grid>
  );
}
