import { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PageOrientation, PageSize } from '@typedefs/app';
import useOrg from '@hooks/useOrg';
import Dialog from '@components/Dialog';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import ReportLayout from '@pages/reports/ReportLayout';
import ReportViewer from '@pages/reports/ReportViewer';
import * as analytics from '@fire/analytics';
import { CashCountOption } from '@shared/types/cashCount';
import useFormat from '@hooks/useFormat';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';

type Props = {
  open: boolean;
  onClose: () => void;
  options: CashCountOption[];
  counts: Record<string, number>;
  totals: { total: number; coins: number; bills: number };
  date: Date;
};

export default function CashCountExport({ open, onClose, options, counts, totals, date }: Props) {
  const { org } = useOrg();
  const { fCurrency, fDate } = useFormat();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [reportLoading, setReportLoading] = useState(false);

  const tableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Count', width: 10, align: 'left' },
      { name: '', width: 6, align: 'center' },
      { name: 'Denomination', width: 15, align: 'left' },
      { name: '', width: 6, align: 'center' },
      { name: 'Total', width: 15, align: 'right' },
    ];

    const filteredOptions = options.filter((option) => counts[option.id] !== undefined);
    const items = filteredOptions.map((option) => {
      const count = counts[option.id] || 0;
      return [
        count ? count.toString() : '',
        'X',
        option.name || `${fCurrency(option.denomination).replace('.00', '')} Bills`, // only correct if all coins have names!
        '=',
        fCurrency(count * option.denomination),
      ];
    });

    const summary: PdfTableSummaryField[][] = [
      [
        { columns: 4, text: 'Total Coins:' },
        { columns: 1, text: fCurrency(totals.coins) },
      ],
      [
        { columns: 4, text: 'Total Bills:' },
        { columns: 1, text: fCurrency(totals.bills) },
      ],
      [
        { columns: 4, text: 'Total Cash:' },
        { columns: 1, text: fCurrency(totals.total) },
      ],
    ];

    return { header, items, summary };
  }, [options, counts, totals, fCurrency]);

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleComplete = (pdfBlob: Blob) => {
    setPdfBlob(pdfBlob);
  };

  const handleReportLoading = (isLoading: boolean) => {
    setReportLoading(isLoading);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{ '& .MuiPaper-root': { height: '100vh' } }}
      title="Cash Count Export"
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          disabled={!pdfBlob}
          loading={reportLoading}
          onClick={handleOpenMenu}
        >
          Download
        </LoadingButton>
      }
    >
      <PdfCsvMenu
        tableData={tableData}
        pdfBlob={pdfBlob}
        basename="cash_count"
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={onClose}
        analyticsPdfFn={() => analytics.donation.exportPDF()}
        analyticsCsvFn={() => analytics.donation.exportCSV()}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" height="100%">
        <Grid item xs={12} md={3}>
          <ReportLayout
            orientation={orientation}
            setOrientation={setOrientation}
            size={size}
            setSize={setSize}
          />
        </Grid>

        <Grid item xs={12} md={9} height="100%" display="flex">
          <ReportViewer
            onComplete={handleComplete}
            onLoading={handleReportLoading}
            documentProps={{
              title: 'Cash Count Report',
              description: `Cash count for ${fDate(date)}`,
              dateFrom: date,
              dateTo: date,
              dateReport: new Date(),
              orgName: org?.name || '',
              data: tableData,
              orientation: orientation,
              size: PageSize[size],
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
