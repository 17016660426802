import { useCallback, useMemo } from 'react';
import { Stack, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { isUndefined } from 'lodash';
import { useParams } from 'react-router';

import { Organization, RecurringDonation as RD } from '@shared/types';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import useFormat from '@hooks/useFormat';

import {
  recurringDonationSchemaResolver,
  RecurringDonationSchemaForm,
} from '@/schemas/recurringDonation';
import { calcNextOccurrence } from '@utils/recurringDonation';

import Dialog from '@components/Dialog';

import getDefaultValues from './getDefaultValues';
import DonationForm from './DonationForm';
import RecurringForm from './RecurringForm';
import InfoForm from './InfoForm';
import DonationRecurringActions from './Actions';

export type DonationRecurringContext = {
  readonly isEdit: boolean;
  readonly isExpired: boolean;
  readonly willNeverRepeat: boolean;
  readonly immediateDonations: string[];
  readonly nextOccurrence: Date;
  readonly org?: Organization.Organization;
  readonly recurringDonation?: RD.RecurringDonation;
};

type Props = {
  readonly recurringDonationId?: string;
  readonly open?: boolean;
  readonly onClose?: () => void;
};

// ----------------------------------------------------------------------
export default function DonationRecurring({ open, recurringDonationId, onClose }: Props) {
  const navigate = useNavigate();
  const params = useParams();

  const { org } = useOrg();
  const { fDateToDayStart, fDateToISO } = useFormat();

  const { getRecurringDonationById, getCategoryById, getDonorById, getPaymentMethodById } =
    useDonation();

  const recId = recurringDonationId || params.recurringDonationId;
  const recurringDonation = useMemo(
    () => getRecurringDonationById(recId),
    [getRecurringDonationById, recId]
  );

  // --------------- form ---------------
  const methods = useForm<RecurringDonationSchemaForm>({
    resolver: recurringDonationSchemaResolver,
    defaultValues: getDefaultValues(
      getDonorById,
      getCategoryById,
      getPaymentMethodById,
      recurringDonation,
      org
    ),
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    watch,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const wState = watch('state');
  const wStartDate = watch('startDate');
  const wNextOccurrence = watch('nextOccurrence');
  const wPeriod = watch('period');
  const wEndingOption = watch('endingOption');
  const wOccurrenceLimit = watch('occurrenceLimit');
  const wEndDate = watch('endDate');

  const isEdit = useMemo(() => !!recurringDonation, [recurringDonation]);
  const isExpired = useMemo(() => wState === RD.RecurringState.expired, [wState]);

  // return the number of donations up to the current date or end date
  // for expired of willNeverRepeat states, nextOccurrence is last execution
  const [immediateDonations, willNeverRepeat, nextOccurrence] = useMemo(() => {
    // NOTE: current behavior doesn't allow editing...
    if (isEdit) {
      return [[], isExpired, wNextOccurrence];
    }

    const today = fDateToDayStart();
    const startDate = fDateToDayStart(wStartDate);
    if (startDate > today) {
      // recurring donation runs only in the future
      return [[], false, startDate];
    }

    let currentDate = startDate;
    let occurrenceCount = 0;
    const occurrences: string[] = [];
    let goNext = true;
    let willNeverRepeat = false;
    do {
      occurrenceCount++;
      occurrences.push(fDateToISO(currentDate));
      currentDate = fDateToDayStart(calcNextOccurrence(currentDate, wPeriod));

      if (
        wEndingOption === RD.RecurringEnding.after &&
        wOccurrenceLimit &&
        occurrenceCount >= Number(wOccurrenceLimit)
      ) {
        goNext = false;
        willNeverRepeat = true;
      } else if (wEndingOption === RD.RecurringEnding.on && currentDate >= wEndDate) {
        goNext = false;
        willNeverRepeat = true;
      } else if (currentDate > today) {
        goNext = false;
      }
    } while (goNext);
    return [occurrences, willNeverRepeat, currentDate];
  }, [
    isEdit,
    fDateToDayStart,
    wStartDate,
    isExpired,
    wNextOccurrence,
    fDateToISO,
    wPeriod,
    wEndingOption,
    wOccurrenceLimit,
    wEndDate,
  ]);

  const context: DonationRecurringContext = {
    isEdit,
    isExpired,
    willNeverRepeat,
    immediateDonations,
    nextOccurrence,
    org,
    recurringDonation,
  };

  // --------------- routing ---------------
  const handleClose = useCallback(() => {
    if (isUndefined(open)) {
      navigate(-1);
    } else {
      onClose?.();
    }
  }, [navigate, onClose, open]);

  return (
    <Dialog
      open={isUndefined(open) ? true : open}
      title={`${isEdit ? 'Edit' : 'Create'} Recurring Donation`}
      onClose={handleClose}
      maxWidth="md"
      isDirty={isDirty && !isExpired}
      isLoading={isSubmitting}
      methods={methods}
      sx={{
        '& .MuiDialogContent-root': {
          minHeight: { sx: '100vh', sm: '35vh' },
          maxHeight: { sx: '100vh', sm: '70vh' },
        },
        '& form': {
          flex: '1 1 auto !important',
        },
      }}
      actions={<DonationRecurringActions context={context} handleClose={handleClose} />}
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
        <DonationForm context={context} />
        <RecurringForm context={context} />
      </Stack>

      <InfoForm context={context} />

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
    </Dialog>
  );
}
