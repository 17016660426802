import { Meta } from '../index';
export * from './requests';

export enum RecurringEnding {
  never = 'never',
  on = 'on', // date
  after = 'after', // # occurrences
}

export enum RecurringPeriod {
  weekly = 'weekly',
  biWeekly = 'bi_weekly',
  monthly = 'monthly',
}

export enum RecurringState {
  expired = 'expired',
  active = 'active',
}

export type RecurringDonation = {
  readonly _meta: Meta;
  readonly id: string;
  readonly orgId: string;

  readonly donorId: string;
  readonly amount: number;
  readonly categoryId: string;
  readonly paymentMethodId?: string;
  readonly nonReceiptable: boolean;

  state: RecurringState;
  readonly startDate: string;
  readonly period: RecurringPeriod;
  nextOccurrence: string; // if not active, this is last execution
  readonly occurrenceCount: number; // starts with 0

  readonly endingOption: RecurringEnding;
  readonly endDate?: string;
  readonly occurrenceLimit?: number;

  readonly v1MemId?: number;
};
