import { BankDeposit } from '@shared/types';
import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------

export type BankDepositListItem = {
  id: string;
  date: string;
  bankAccount: string;
  description: string | null;
  accountNumber: string | null;
  totalDeposit: string;
  type: BankDeposit.BankDepositType;
};

// ----------------------------------------------------------------------
export type BankDepositListHeader =
  | 'date'
  | 'bankAccount'
  | 'accountNumber'
  | 'description'
  | 'cashCount'
  | 'totalDeposit'
  | 'actions';

export const BANK_DEPOSIT_LIST_HEADERS: TColumn<BankDepositListHeader>[] = [
  {
    id: 'date',
    type: 'string',
    label: 'Date',
    align: 'left',
    visible: true,
    pdfWidth: 20,
  },
  {
    id: 'bankAccount',
    type: 'string',
    label: 'Bank Name',
    align: 'left',
    visible: true,
    pdfWidth: 20,
  },
  {
    id: 'accountNumber',
    type: 'string',
    label: 'Account #',
    align: 'left',
    visible: true,
    pdfWidth: 20,
  },
  {
    id: 'description',
    type: 'string',
    label: 'Description',
    align: 'left',
    visible: true,
    pdfWidth: 20,
  },
  {
    id: 'cashCount',
    type: 'string',
    label: 'Cash Count',
    align: 'left',
    visible: true,
    pdfWidth: 20,
  },
  {
    id: 'totalDeposit',
    type: 'currency',
    label: 'Total Deposit',
    align: 'left',
    visible: true,
    pdfWidth: 20,
  },
  {
    id: 'actions',
    type: 'string',
    label: 'Actions',
    align: 'center',
    visible: true,
    pdfWidth: 10,
  },
];
