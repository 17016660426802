import { useCallback, useState } from 'react';
import { Tab, Box } from '@mui/material';
import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import Dialog from '@components/Dialog';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PaymentMethods from '@pages/donations/dialogs/Settings/PaymentMethods';
import DonationCategories from '@pages/donations/dialogs/Settings/DonationCategories';

import useOrg from '@hooks/useOrg';
import { Outlet } from 'react-router';
import Pledges from './Pledges';
import BankDeposits from './BankDeposits';
import useDonation from '@hooks/useDonation';
import useLocalState from '@hooks/useLocalState';

type ContextTab = 'categories' | 'payment-methods' | 'pledges' | 'bank-deposits';

type Props = {
  context?: ContextTab;
};

// --------------- effects ---------------
export default function Settings({ context }: Props) {
  const { org } = useOrg();
  const navigate = useNavigate();
  const { hasBankDeposits } = useDonation();
  const [value, setValue] = useState<ContextTab>(context || 'categories');
  const [activeMainTab, setActiveMainTab] = useLocalState<string>('donations-active-tab', 'all');

  const handleChange = (event: React.SyntheticEvent, tab: ContextTab) => {
    setValue(tab);

    switch (tab) {
      case 'categories':
        navigate(getPath(PATHS.org.donations.settings.categories, { orgId: org!.id }));
        break;
      case 'payment-methods':
        navigate(getPath(PATHS.org.donations.settings.paymentMethods, { orgId: org!.id }));
        break;
      case 'pledges':
        navigate(getPath(PATHS.org.donations.settings.pledges, { orgId: org!.id }));
        break;
      case 'bank-deposits':
        navigate(getPath(PATHS.org.donations.settings.bankDeposits, { orgId: org!.id }));
        break;
      default:
        console.warn(`Invalid tab ${tab}`);
    }
  };

  const handleClose = useCallback(() => {
    if (
      (activeMainTab === 'bank-deposits' && !hasBankDeposits) ||
      (activeMainTab === 'pledges' && !org?.pledgesEnabled)
    ) {
      setActiveMainTab('all');
      navigate(getPath(PATHS.org.donations.all.root));
    } else {
      switch (activeMainTab) {
        case 'all':
          navigate(getPath(PATHS.org.donations.all.root));
          break;
        case 'bank-deposits':
          navigate(getPath(PATHS.org.donations.bankDeposits.root));
          break;
        case 'recurring-donations':
          navigate(getPath(PATHS.org.donations.recurringDonations.root));
          break;
        case 'pledges':
          navigate(getPath(PATHS.org.donations.pledges.root));
          break;
        default:
          navigate(getPath(PATHS.org.donations.all.root));
      }
    }
  }, [activeMainTab, hasBankDeposits, org?.pledgesEnabled, setActiveMainTab, navigate]);

  return (
    <>
      <Dialog
        title="Donation Settings"
        onClose={handleClose}
        maxWidth="md"
        sx={{
          '& .MuiDialogContent-root': {
            minHeight: { sx: '100vh', sm: '35vh' },
            maxHeight: { sx: '100vh', sm: '70vh' },
            height: { sx: '100vh', sm: '60vh' },
          },
          '& form': {
            flex: '1 1 auto !important',
          },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Categories" value="categories" />
                <Tab label="Payment Methods" value="payment-methods" />
                <Tab label="Pledges" value="pledges" />
                <Tab label="Bank Deposits" value="bank-deposits" />
              </TabList>
            </Box>
            <TabPanel value="categories">
              <DonationCategories />
            </TabPanel>
            <TabPanel value="payment-methods">
              <PaymentMethods />
            </TabPanel>
            <TabPanel value="pledges">
              <Pledges />
            </TabPanel>
            <TabPanel value="bank-deposits">
              <BankDeposits />
            </TabPanel>
          </TabContext>
        </Box>
        <Outlet />
      </Dialog>
    </>
  );
}
