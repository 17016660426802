import { useSnackbar } from 'notistack';
import useSteps from './steps/useSteps';
import { BankDepositSchemaForm } from '@/schemas/bankDeposit';
import useOrg from '@hooks/useOrg';
import { BankDepositType } from '@shared/types/bankDeposit';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';

export default function BankDepositDialog() {
  const { fDateToISO } = useFormat();
  const { createBankDeposit } = useDonation();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();

  const onComplete = (stepData: BankDepositSchemaForm) => onSubmit(stepData);

  const { ui, setFailed, setError, setSubmittingData, handleClose } = useSteps({
    onComplete,
    title: 'Create Bank Deposit',
    actionText: 'Next',
  });

  const onSubmit = async (stepData: BankDepositSchemaForm) => {
    try {
      setSubmittingData({ isSubmitting: true, progress: 1 });

      if (!org?.id) {
        setError('Organization not found');
        return;
      }

      const payload = {
        bankDeposit: {
          orgId: org.id,
          date: fDateToISO(stepData.date),
          bankAccount: stepData.bankAccount,
          accountNumber: stepData.accountNumber,
          description: stepData.description,
          advantageAccount: stepData.advantageAccount,
          advantageClass: stepData.advantageClass,
          type: BankDepositType.cash,
        },
        donationIds: stepData.selectedDonationIds,
      };

      await createBankDeposit(payload);
      enqueueSnackbar('Bank Deposit Created Successfully!');
      handleClose();
    } catch (e) {
      enqueueSnackbar('Error Creating Bank Deposit!', {
        variant: 'error',
      });
      console.error(e);
      setError(e.errors?.[0] || 'An error occurred');
      setFailed();
    } finally {
      setSubmittingData({ isSubmitting: false, progress: 0 });
    }
  };

  return <>{ui}</>;
}
