import { useCallback, useEffect, useState } from 'react';
import { Stack, TextField, InputAdornment, Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
// import { Category, Tag } from '@shared/types';

// import renderTags, { renderTagOption } from '@pages/settings/Organization/Tags/utils/renderTags';
// import renderCategories, {
//   renderCategoryOption,
// } from '@pages/settings/Organization/Categories/utils/renderCategories';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import PATHS, { getPath } from '@routes/paths';
import { TableColumns, TableDateRange } from '@components/table';
import Iconify from '@components/Iconify';
import { Link } from 'react-router-dom';
import useOrg from '@hooks/useOrg';
import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------
type Props = {
  // filterTags: Tag.Tag[];
  // onFilterTagChange: (newValue: Tag.Tag[]) => void;
  // filterCategories: Category.Category[];
  // onFilterCategoryChange: (newValue: Category.Category[]) => void;
  ctaReissueDisabled?: boolean;
  ctaTooltip?: string;
  dateFrom?: Date;
  setDateFrom: (date: Date | undefined) => void;
  dateTo?: Date;
  setDateTo: (date: Date | undefined) => void;
  search: string;
  onSearchChange: (search: string) => void;
  columns: TColumn[];
  setColumns: (columns: TColumn<any>[]) => void;
  actions: React.ReactNode;
};

// ----------------------------------------------------------------------
export default function Toolbar({
  // filterTags,
  // onFilterTagChange,
  // filterCategories,
  // onFilterCategoryChange,
  ctaReissueDisabled,
  ctaTooltip,
  columns,
  setColumns,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  search,
  onSearchChange,
  actions,
}: Props) {
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const [filter, setFilter] = useState(search);
  const canEdit = hasAccess([Role.editor]);
  // const { categories, tags } = useDonation();

  const handleFilterSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setFilter(event.target.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeFilter = useCallback(debounce(onSearchChange, 400), []);
  useEffect(() => changeFilter(filter), [changeFilter, filter]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={2}>
        <TextField
          value={filter}
          onChange={handleFilterSearchChange}
          placeholder="Search"
          inputProps={{ sx: { p: 1 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            width: 320,
            background: (theme) => theme.palette.common.white,
          }}
        />

        <TableDateRange
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
        />
        <TableColumns columns={columns} setColumns={setColumns} />
        {/* <Stack spacing={1.5} direction={{ xs: 'column', md: 'row' }}>
          <Autocomplete
            multiple
            size="small"
            autoComplete
            sx={{ minWidth: 240, background: (theme) => theme.palette.common.white }}
            limitTags={2}
            disableCloseOnSelect
            value={filterTags}
            onChange={(e, newValue) => {
              onFilterTagChange(newValue);
            }}
            options={sortBy(tags, (t) => t.name.toUpperCase())}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField label="Donor Tags" {...params} />}
            getOptionLabel={(option) => option.name}
            renderTags={renderTags}
            renderOption={renderTagOption}
          />

          <Autocomplete
            multiple
            size="small"
            autoComplete
            sx={{ minWidth: 240, background: (theme) => theme.palette.common.white }}
            limitTags={2}
            disableCloseOnSelect
            value={filterCategories}
            onChange={(e, newValue) => {
              onFilterCategoryChange(newValue);
            }}
            options={sortBy(categories, (c) => c.name.toUpperCase())}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField label="Donation Categories" {...params} />}
            renderTags={renderCategories}
            renderOption={renderCategoryOption}
            getOptionLabel={(option) => option.name}
          />
        </Stack> */}
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button
          color="info"
          variant="contained"
          startIcon={<Iconify icon="uil:file-export" />}
          component={Link}
          to={getPath(PATHS.org.receipts.export, { orgId: org!.id })}
        >
          Export
        </Button>
        {canEdit && (
          <Tooltip title={ctaTooltip}>
            <span>
              <Button
                color="info"
                variant="contained"
                startIcon={<Iconify icon="bi:trash" />}
                component={Link}
                to={getPath(PATHS.org.receipts.deleteBatch.root, { orgId: org!.id })}
              >
                Delete Receipts
              </Button>
            </span>
          </Tooltip>
        )}
        {canEdit && (
          <Tooltip title={ctaTooltip}>
            <span>
              <Button
                color="info"
                variant="contained"
                disabled={ctaReissueDisabled}
                startIcon={<Iconify icon="bi:arrow-repeat" />}
                component={Link}
                to={getPath(PATHS.org.receipts.reissueBatch.root, { orgId: org!.id })}
              >
                Reissue Receipts
              </Button>
            </span>
          </Tooltip>
        )}

        {actions}
      </Stack>
    </Stack>
  );
}
