import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Category } from '@shared/types';
import { TDonorWithDonations } from '@typedefs/donation';

const addYear = (d: Date) => new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());

// ----------------------------------------------------------------------
const PledgeSchema = () =>
  Yup.object().shape({
    donor: Yup.object().nullable().required('Donor is required'),
    dateStart: Yup.date().typeError('Start Date is required').required('Start Date is required'),
    dateEnd: Yup.date()
      .typeError('End Date is required')
      .required('End Date is required')
      .test('endLaterTest', 'End Date must be after Start Date', function (dateEnd) {
        const { dateStart } = this.parent;
        return !dateStart || dateEnd.getTime() > dateStart.getTime();
      })
      .test('oneYearTest', 'End Date must be within a year of Start Date', function (dateEnd) {
        const { dateStart } = this.parent;
        return !dateStart || addYear(dateStart) > dateEnd;
      }),
    amount: Yup.number()
      .typeError('Amount is required')
      .required('Amount is required')
      .min(0, "Amount can't be negative."),
    category: Yup.object().nullable().required('Category is required'),
  });

export const pledgeSchemaResolver = () => yupResolver(PledgeSchema());
export type PledgeSchemaForm = {
  donor: TDonorWithDonations | null;
  dateStart: Date;
  dateEnd: Date;
  amount: number | string;
  category: Category.Category | null;

  afterSubmit?: string;
};

// ----------------------------------------------------------------------
const PledgeSettingsSchema = Yup.object().shape({
  pledgesEnabled: Yup.bool(),
});

export const pledgeSettingsSchemaResolver = yupResolver(PledgeSettingsSchema);

export type PledgeSettingsSchemaForm = {
  pledgesEnabled: boolean;
  defaultPledgeCategory: Category.Category | null;
  afterSubmit?: string;
};
