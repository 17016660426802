import { useState } from 'react';
import { Button, Stack, Divider } from '@mui/material';

import { Category, Tag } from '@shared/types';
import Dialog from '@components/Dialog';
import Iconify from '@components/Iconify';
import { TableFilters } from '@components/table';
import { TColumn, TColumnFilter } from '@typedefs/app';
import { DonorListHeader } from '@pages/donors/List/config';
import { DonationListHeader } from '@pages/donations/tab/AllDonations/List/config';
import FilterCategories from '@components/table/TableFilters/FilterCategories';
import FilterTags from '@components/table/TableFilters/FilterTags';

// ----------------------------------------------------------------------
export type FilterProps = {
  columns: TColumn<DonorListHeader | DonationListHeader>[];
  filterTags: Tag.Tag[];
  onFilterTagChange: (newValue: Tag.Tag[]) => void;
  filterCategories: Category.Category[];
  onFilterCategoryChange: (newValue: Category.Category[]) => void;
  filters: TColumnFilter[];
  setFilters: (filters: TColumnFilter[]) => void;
};

// ----------------------------------------------------------------------
export default function ToolbarFilters({
  columns,
  filterTags,
  onFilterTagChange,
  filterCategories,
  onFilterCategoryChange,
  filters,
  setFilters,
}: FilterProps) {
  const [openDialog, setOpenDialog] = useState<HTMLElement | null>(null);

  const handleOpenFilters = (e: React.MouseEvent<HTMLElement>) => setOpenDialog(e.currentTarget);
  const handleCloseFilters = () => setOpenDialog(null);
  const handleClearAll = () => {
    onFilterTagChange([]);
    onFilterCategoryChange([]);
    setFilters([]);
  };

  const filterCount =
    filters.length + (!!filterTags.length ? 1 : 0) + (!!filterCategories.length ? 1 : 0);

  return (
    <>
      <Button
        variant="text"
        startIcon={<Iconify icon="material-symbols:filter-alt-outline" />}
        onClick={handleOpenFilters}
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontWeight: 400,
        }}
      >
        Filters {!!filterCount && `(${filterCount})`}
      </Button>
      <Dialog
        open={!!openDialog}
        title="Filters"
        maxWidth="sm"
        onClose={handleCloseFilters}
        actions={
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Button variant="text" onClick={handleClearAll}>
              Clear all
            </Button>
            <Button variant="contained" onClick={handleCloseFilters}>
              Close
            </Button>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FilterTags
              filterTags={filterTags}
              setFilterTags={onFilterTagChange}
              sxProps={{ minWidth: 240 }}
            />
            <FilterCategories
              filterCategories={filterCategories}
              setFilterCategories={onFilterCategoryChange}
              sxProps={{ minWidth: 240 }}
            />
          </Stack>

          <Divider />

          <TableFilters columns={columns} filters={filters} setFilters={setFilters} />
        </Stack>
      </Dialog>
    </>
  );
}
