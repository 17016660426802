import { Stack, Typography, Link, Alert, Checkbox } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ReceiptAction } from '@shared/types';
import useOrg from '@hooks/useOrg';
import Iconify from '@components/Iconify';
import BubbleButton from '@components/BubbleButton';
import PATHS, { getPath } from '@routes/paths';
import { TStepProps } from '../../../useSteps';

// ----------------------------------------------------------------------
export default function SendType({ stepData, updateStepData }: TStepProps) {
  const { org } = useOrg();
  const isEmailEnabled =
    !!org?.emailSignature?.confirmed && !!org?.emailSignature && !!org?.signatorySignature;
  const emailDisabledText =
    !org?.emailSignature?.confirmed || !org?.emailSignature
      ? 'Not activated'
      : 'No signature image';
  const isEmailSelected = stepData.actionType === 'email';
  const isPrintSelected = stepData.actionType === 'print';

  const handleSelectEmail = () => {
    updateStepData({ actionType: isEmailSelected ? undefined : ReceiptAction.ReceiptActionType.email });
  };

  const handleSelectPrint = () => {
    updateStepData({ actionType: isPrintSelected ? undefined : ReceiptAction.ReceiptActionType.print });
  };

  const handleSelectPrintAllDonors = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ printAllDonors: e.target.checked });
  };

  const renderHelper = () => {
    if (!stepData.actionType) return null;

    if (isEmailSelected) {
      return (
        <Stack maxWidth={510}>
          <Typography variant="subtitle2">
            Email receipts to donors with an email address on file.
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, lineHeight: '15px' }}>
            Note: You can also print out receipts in this flow.
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Stack maxWidth={510}>
          <Typography variant="subtitle2">
            <strong>Print receipts for donors without an email address on file.</strong>
          </Typography>

          <Typography variant="body2" sx={{ mt: 1, lineHeight: '15px' }}>
            Alternatively, if you want to include all donors (even those with an email address on
            file), check this box:{' '}
            <Checkbox
              checked={stepData.printAllDonors}
              onChange={handleSelectPrintAllDonors}
              sx={{ display: 'inline-block', p: 0 }}
            />
          </Typography>
        </Stack>
      );
    }
  };

  return (
    <Stack width="100%" margin="auto !important" alignItems="center" flex="1 1 auto">
      <Stack
        width="50%"
        margin="auto"
        marginBottom={0}
        alignItems="center"
        flexDirection={{ sx: 'column', md: 'row' }}
      >
        <BubbleButton
          onClick={handleSelectEmail}
          disabled={!isEmailEnabled}
          selected={isEmailSelected}
        >
          <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
            <Iconify icon="mdi:email" width={32} height={32} mb={1.5} />
            <Typography variant="subtitle1">Email</Typography>
          </Stack>

          {!isEmailEnabled && (
            <Stack
              direction="row"
              alignItems="flex-end"
              spacing={1}
              sx={{ color: (theme) => theme.palette.common.black }}
            >
              <Iconify
                icon="material-symbols:warning-rounded"
                width={26}
                height={26}
                sx={{ color: (theme) => theme.palette.warning.main }}
              />
              <Typography variant="body2">{emailDisabledText} &nbsp;-</Typography>
              <Link
                variant="body2"
                component={RouterLink}
                to={getPath(PATHS.org.receipts.settings.receipts, {
                  orgId: org?.id,
                })}
              >
                Configure
              </Link>
            </Stack>
          )}
        </BubbleButton>

        <Stack
          width={40}
          height={40}
          mx={{ xs: 0, md: 2 }}
          my={{ xs: 2, md: 0 }}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: (theme) => theme.palette.common.white,
          }}
        >
          <Typography variant="button" sx={{ mx: 4 }}>
            OR
          </Typography>
        </Stack>

        <BubbleButton onClick={handleSelectPrint} selected={isPrintSelected}>
          <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
            <Iconify icon="fa6-solid:file-pdf" width={32} height={32} mb={1.5} />
            <Typography variant="subtitle1">Print</Typography>
          </Stack>
        </BubbleButton>
      </Stack>

      <Stack marginBottom="auto" marginTop={6} minHeight={110} minWidth={500}>
        {stepData.actionType && (
          <Alert
            severity="info"
            icon={false}
            sx={{
              '.MuiAlert-message': { px: 2 },
              border: (theme) => `1px solid ${theme.palette.grey[600]}14`,
              background: (theme) => theme.palette.grey[100],
            }}
          >
            {renderHelper()}
          </Alert>
        )}
      </Stack>
    </Stack>
  );
}
