import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import { Receipt } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
export const receiptCreateReceipts = httpsCallable<
  Receipt.CreateReceiptsReq,
  Receipt.CreateReceiptsRes
>(firebaseFunctions, 'receipt-createReceipts');

export const receiptReissueReceipt = httpsCallable<
  Receipt.ReissueReceiptReq,
  Receipt.ReissueReceiptRes
>(firebaseFunctions, 'receipt-reissueReceipt');

export const receiptReissueReceipts = httpsCallable<
  Receipt.ReissueReceiptsReq,
  Receipt.ReissueReceiptsRes
>(firebaseFunctions, 'receipt-reissueReceipts');

export const receiptDeleteReceipts = httpsCallable<
  Receipt.DeleteReceiptsReq,
  Receipt.DeleteReceiptsRes
>(firebaseFunctions, 'receipt-deleteReceipts');

export const receiptSendTestEmail = httpsCallable<Receipt.SendTestEmailReq, void>(
  firebaseFunctions,
  'receipt-sendTestEmail'
);

// TODO: maybe change to be generic?
export const receiptSendEmail = httpsCallable<Receipt.SendEmailReq, void>(
  firebaseFunctions,
  'receipt-sendEmail'
);

// TODO: correct donation should replace this
// export const receiptInvalidateReceipt = httpsCallable<
//   Receipt.InvalidateReceiptReq,
//   Receipt.InvalidateReceiptRes
// >(firebaseFunctions, 'receipt-invalidateReceipt');
