import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
  Box,
  TablePagination,
} from '@mui/material';
import { TableHeadCustom, TableEmptyRows, TableNoData } from '@components/table';
import useTable, { emptyRows } from '@hooks/useTable';
import useFormat from '@hooks/useFormat';
import { TColumn } from '@typedefs/app';
import useDonation from '@hooks/useDonation';
import { Donation } from '@shared/types';
import { useMemo } from 'react';
import { orderBy as _orderBy } from 'lodash';

type Props = {
  donations: Donation.Donation[];
  selectedIds: string[];
  onSelectionChange: (id: string) => void;
  columns: TColumn[];
  readOnly?: boolean;
};

export default function DonationsTable({
  donations,
  selectedIds,
  onSelectionChange,
  columns,
  readOnly = false,
}: Props) {
  const { page, order, orderBy, rowsPerPage, onChangePage, onChangeRowsPerPage, onSort } = useTable(
    {
      defaultRowsPerPage: 25,
      defaultOrderBy: 'date',
      defaultOrder: 'desc',
    }
  );

  const { getDonationDonor, getCategoryById, getPaymentMethod } = useDonation();
  const { fCurrency, fDate, fReversedName } = useFormat();

  const handleSelectRow = (id: string) => {
    onSelectionChange(id);
  };

  const parsedData = useMemo(
    () =>
      donations.map((donation) => {
        const donor = getDonationDonor(donation);
        const category = getCategoryById(donation.categoryId);
        const paymentMethodName = getPaymentMethod(donation.paymentMethodId, donation.paymentInfo);

        return {
          id: donation.id,
          reversedName: fReversedName(donor),
          date: fDate(donation.date),
          amount: fCurrency(donation.amount),
          category: category?.name || '',
          paymentMethodName,
        };
      }),
    [
      donations,
      fCurrency,
      fDate,
      fReversedName,
      getCategoryById,
      getDonationDonor,
      getPaymentMethod,
    ]
  );

  const orderedData = useMemo(
    () => _orderBy(parsedData, orderBy, order),
    [parsedData, orderBy, order]
  );

  return (
    <>
      <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
        <Table stickyHeader size="small">
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            columns={columns}
            rowCount={orderedData.length}
            onSort={onSort as (id: string) => void}
          />

          <TableBody>
            {orderedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((donation) => {
                const isSelected = selectedIds.includes(donation.id);

                return (
                  <TableRow
                    hover={!readOnly}
                    key={donation.id}
                    onClick={() => handleSelectRow(donation.id)}
                    selected={!readOnly && isSelected}
                    sx={{
                      cursor: readOnly ? 'default' : 'pointer',
                    }}
                  >
                    {!readOnly && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                    )}
                    <TableCell>{donation.reversedName}</TableCell>
                    <TableCell>{donation.date}</TableCell>
                    <TableCell align="right">{donation.amount}</TableCell>
                    <TableCell>{donation.category}</TableCell>
                    <TableCell>{donation.paymentMethodName}</TableCell>
                  </TableRow>
                );
              })}

            <TableEmptyRows
              height={44}
              emptyRows={emptyRows(page, rowsPerPage, donations.length)}
            />
            <TableNoData isNotFound={!orderedData.length} />
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={donations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
