import { addDays, addMonths, addWeeks, format, getDate, lastDayOfMonth } from 'date-fns';
import numeral from 'numeral';
import { RecurringDonation as RD } from '@shared/types';

export const PeriodLabel: { [key in RD.RecurringPeriod]: string } = {
  [RD.RecurringPeriod.weekly]: 'Weekly',
  [RD.RecurringPeriod.biWeekly]: 'Bi-Weekly',
  [RD.RecurringPeriod.monthly]: 'Monthly',
};

// --------------------
// Returns label for user display
// matches behavior of other calc functions in regards to Monthly calculation
export function getFrequencyLabel(startDate: Date, period: string) {
  if (period === RD.RecurringPeriod.monthly) {
    const dayOfMonth = startDate.getDate();
    if (dayOfMonth >= 28) {
      // we condense all last couple of days of each month as its last day
      return 'on the last day of the month';
    }
    // on the 16th
    return `on the ${numeral(dayOfMonth).format('0o')}`;
  }
  // on Friday
  return `on ${format(startDate, 'EEEE')}`;
}

// --------------------
// IMPORTANT: has to be identical to backend function that calculates next occurrence
// Calculates final occurrence date either based on given occurrences or based on given date
// returns final occurrence date and number of occurrences until it finishes
export const calcNextOccurrence = (startDate: Date, period: string): Date => {
  switch (period) {
    case RD.RecurringPeriod.weekly:
      return addDays(startDate, 7);
    case RD.RecurringPeriod.biWeekly:
      return addDays(startDate, 14);
    case RD.RecurringPeriod.monthly: {
      const nextMonthDate = addMonths(startDate, 1);
      // condensed last couple of days in month to always be last day
      if (getDate(startDate) >= 28) {
        return lastDayOfMonth(nextMonthDate);
      }
      return nextMonthDate;
    }
    default:
      throw new Error(`Unknown repeat type: ${period}`);
  }
};

// --------------------
// Calculates final occurrence date either based on given occurrences or based on given date
// returns final occurrence date and number of occurrences until it finishes
type RecurringDonationEnding = {
  readonly endingOption?: RD.RecurringEnding;
  readonly occurrenceCount?: number;
  readonly occurrenceLimit?: number;
  readonly endDate?: Date;
};
export function calcFinalOccurrence(
  startDate: Date,
  period: string,
  { endingOption, occurrenceLimit, endDate }: RecurringDonationEnding
): [Date | undefined, number | undefined] {
  if (endingOption === RD.RecurringEnding.after && occurrenceLimit) {
    switch (period) {
      case RD.RecurringPeriod.weekly:
        return [addWeeks(startDate, occurrenceLimit - 1), occurrenceLimit];
      case RD.RecurringPeriod.biWeekly:
        return [addWeeks(startDate, (occurrenceLimit - 1) * 2), occurrenceLimit];
      case RD.RecurringPeriod.monthly:
        const lastMonthDate = addMonths(startDate, occurrenceLimit - 1);
        if (getDate(startDate) >= 28) {
          return [lastDayOfMonth(lastMonthDate), occurrenceLimit];
        }
        return [lastMonthDate, occurrenceLimit];
      default:
        throw new Error(`Unknown repeat type: ${period}`);
    }
  }

  if (endingOption === RD.RecurringEnding.on && endDate) {
    let occurrenceCount = 0;
    let previousDate = startDate;
    let currentDate = startDate;

    while (currentDate <= endDate) {
      occurrenceCount++;
      previousDate = currentDate;
      currentDate = calcNextOccurrence(currentDate, period);
    }
    return [previousDate, occurrenceCount];
  }

  return [undefined, undefined];
}
