import { Meta } from '../index';
export * from './requests';

export type CashCountOption = {
  readonly id: string;
  readonly denomination: number; // amount in dollars or fractional dollars of this coin or bill
  readonly name?: string; // optional plural "nice name" for display when doing data entry
  readonly type: CashCountMoneyType;
};

export enum CashCountMoneyType {
  coin = 'Coin',
  bill = 'Bill',
}

export const CashCountOptionsCanada: CashCountOption[] = [
  { id: 'Coin.05', denomination: 0.05, name: 'Nickels', type: CashCountMoneyType.coin },
  { id: 'Coin.10', denomination: 0.1, name: 'Dimes', type: CashCountMoneyType.coin },
  { id: 'Coin.25', denomination: 0.25, name: 'Quarters', type: CashCountMoneyType.coin },
  { id: 'Coin1', denomination: 1, name: 'Loonies', type: CashCountMoneyType.coin },
  { id: 'Coin2', denomination: 2, name: 'Toonies', type: CashCountMoneyType.coin },
  { id: 'Bill5', denomination: 5, type: CashCountMoneyType.bill },
  { id: 'Bill10', denomination: 10, type: CashCountMoneyType.bill },
  { id: 'Bill20', denomination: 20, type: CashCountMoneyType.bill },
  { id: 'Bill50', denomination: 50, type: CashCountMoneyType.bill },
  { id: 'Bill100', denomination: 100, type: CashCountMoneyType.bill },
];

export const CashCountOptionsUSA: CashCountOption[] = [
  { id: 'Coin.01', denomination: 0.01, name: 'Pennies', type: CashCountMoneyType.coin },
  { id: 'Coin.05', denomination: 0.05, name: 'Nickels', type: CashCountMoneyType.coin },
  { id: 'Coin.10', denomination: 0.1, name: 'Dimes', type: CashCountMoneyType.coin },
  { id: 'Coin.25', denomination: 0.25, name: 'Quarters', type: CashCountMoneyType.coin },
  { id: 'Coin.50', denomination: 0.5, name: 'Half Dollars', type: CashCountMoneyType.coin },
  { id: 'Coin1', denomination: 1, name: 'Dollar Coins', type: CashCountMoneyType.coin },
  { id: 'Bill1', denomination: 1, type: CashCountMoneyType.bill },
  { id: 'Bill2', denomination: 2, type: CashCountMoneyType.bill },
  { id: 'Bill5', denomination: 5, type: CashCountMoneyType.bill },
  { id: 'Bill10', denomination: 10, type: CashCountMoneyType.bill },
  { id: 'Bill20', denomination: 20, type: CashCountMoneyType.bill },
  { id: 'Bill50', denomination: 50, type: CashCountMoneyType.bill },
  { id: 'Bill100', denomination: 100, type: CashCountMoneyType.bill },
];

export type CashCountItem = {
  readonly count: number; // count of this type of coin or bill within this overall count
  readonly optionsId: string; // id field from a CashCountOption object
};

export type CashCount = {
  readonly id: string;
  readonly _meta: Meta;
  readonly orgId: string;

  readonly date: string;
  readonly bankDepositId?: string; // id of a BankDeposit this is for, if that's where we did this
  readonly items: CashCountItem[];
};
