import { Stack, Grid } from '@mui/material';
import { TStepProps } from './useSteps';
import FormProvider from '@components/hook-form/FormProvider';
import { RHFTextField, RHFDatePicker, RHFAutocomplete } from '@components/hook-form';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useMemo } from 'react';
import { SummarySchemaResolver } from '@/schemas';
import useDonation from '@hooks/useDonation';
import { Donation } from '@shared/types';
import { parseDateTimezone } from '@redux/slices/donation';

export default function StepSummary({ stepData, updateStepData, setIsValid }: TStepProps) {
  const { isShowingClasses, bankDeposits } = useDonation();

  const bankAccounts = useMemo(() => {
    const uniqueAccounts = new Map<
      string,
      {
        name: string;
        accountNumber?: string;
      }
    >();

    bankDeposits.forEach((deposit) => {
      const key = deposit.bankAccount.toLowerCase();
      if (!uniqueAccounts.has(key)) {
        uniqueAccounts.set(key, {
          name: deposit.bankAccount,
          accountNumber: deposit.accountNumber,
        });
      }
    });

    return Array.from(uniqueAccounts.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [bankDeposits]);

  const [hasAdvantage, setHasAdvantage] = useState(false);

  const methods = useForm({
    resolver: SummarySchemaResolver,
    defaultValues: {
      date: stepData.date ? parseDateTimezone(stepData.date) : new Date(),
      bankAccount: stepData.bankAccount || '',
      accountNumber: stepData.accountNumber || '',
      description: stepData.description || '',
      advantageAccount: stepData.advantageAccount || '',
      advantageClass: stepData.advantageClass || '',
    },
    mode: 'onChange',
  });

  const {
    watch,
    setValue,
    formState: { isValid },
  } = methods;

  const watchedBankAccount = watch('bankAccount');

  useEffect(() => {
    const subscription = watch((value) => {
      updateStepData(value);
      setIsValid(isValid);
    });

    setHasAdvantage(
      stepData.donations.some(
        (donation: Donation.Donation) =>
          stepData.selectedDonationIds.includes(donation.id) && donation.withAdvantage
      )
    );

    return () => subscription.unsubscribe();
  }, [watch, updateStepData, setIsValid, isValid, stepData]);

  useEffect(() => {
    if (!watchedBankAccount) return;

    const account = bankAccounts.find(
      (acc) => acc.name.toLowerCase() === watchedBankAccount.toLowerCase()
    );

    if (account?.accountNumber) {
      setValue('accountNumber', account.accountNumber);
    }
  }, [watchedBankAccount, bankAccounts, setValue]);

  return (
    <FormProvider methods={methods}>
      <Stack spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <RHFAutocomplete
              name="bankAccount"
              label="Bank Name"
              options={bankAccounts.map((acc) => acc.name)}
              freeSolo
              autoFocus
              required
              fullWidth
              onInputChange={(event, value) => {
                setValue('bankAccount', value, { shouldValidate: true });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <RHFAutocomplete
              name="accountNumber"
              label="Account Number"
              options={bankAccounts
                .map((acc) => acc.accountNumber)
                .filter((num): num is string => !!num)}
              freeSolo
              fullWidth
              onInputChange={(event, value) => {
                setValue('accountNumber', value, { shouldValidate: true });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <RHFDatePicker name="date" label="Deposit Date" required fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <RHFTextField name="description" label="Description" fullWidth />
          </Grid>

          {hasAdvantage && (
            <>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="advantageAccount" label="Advantage Account" fullWidth />
              </Grid>
              {isShowingClasses && (
                <Grid item xs={12} sm={6}>
                  <RHFTextField name="advantageClass" label="Advantage Class" fullWidth />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Stack>
    </FormProvider>
  );
}
