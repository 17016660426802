import { Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { TReceiptDonorGroup, TReceiptRenderGroup, TReceiptVariables } from '@typedefs/donation';
import { Category, PaymentMethod } from '@shared/types';
// import { fonts } from '@components/pdf';
// Font.register(fonts);

import PdfLetter from './PdfLetter';
import ReceiptCA from './PdfReceipt/ReceiptCA';
import ReceiptUS from './PdfReceipt/ReceiptUS';
import { ReceiptOptions } from '@/schemas';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Helvetica',
    fontSize: 11,
    position: 'relative',
  },
  pageNumbers: {
    position: 'absolute',
    top: 24,
    right: 32,
    flexDirection: 'row',
    width: 100,
    textAlign: 'right',
    fontSize: 9,
  },
  watermark: {
    zIndex: -5,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',

    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  watermarkText: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 80,
    color: 'rgba(0, 0, 0, 0.2)',
    transform: 'rotate(-45deg)',
  },
});

// ----------------------------------------------------------------------
export type PdfDocumentProps = {
  readonly title: string;
  readonly description: string;
  readonly size: { width: number; height: number };
  readonly donorGroups: TReceiptDonorGroup[];
  readonly withLetter?: boolean;
  readonly preview?: boolean;
  readonly options: ReceiptOptions;
  readonly year: number;
};
// ----------------------------------------------------------------------
export default function PdfDocument({
  title,
  description,
  size,
  donorGroups,
  withLetter,
  preview,
  options,
  year,
}: PdfDocumentProps) {
  return (
    <Document
      title={title}
      subject={description}
      author="Software4Nonprofits"
      creator="s4np web-app"
      producer="s4np web-app"
      pdfVersion="1.7"
    >
      {donorGroups.map(({ donorId, vars, groups, categories, paymentMethods }) => (
        <PdfDonorReceipt
          key={donorId}
          size={size}
          vars={vars}
          groups={groups}
          categories={categories}
          paymentMethods={paymentMethods}
          withLetter={withLetter}
          preview={preview}
          options={options}
          year={year}
        />
      ))}
    </Document>
  );
}

// ----------------------------------------------------------------------
type PdfDonorReceiptProps = {
  readonly size: { width: number; height: number };
  readonly vars: TReceiptVariables;
  readonly groups: TReceiptRenderGroup[];
  readonly withLetter?: boolean;
  readonly preview?: boolean;
  readonly options: ReceiptOptions;
  readonly year: number;
  readonly categories: Category.Category[];
  readonly paymentMethods: PaymentMethod.PaymentMethodsWithNameAndType[];
};
// ----------------------------------------------------------------------
export function PdfDonorReceipt({
  size,
  vars,
  groups,
  withLetter,
  preview,
  options,
  categories,
  paymentMethods,
  year,
}: PdfDonorReceiptProps) {
  const isCA = vars['org.address'].country === 'ca';
  const Template = isCA ? ReceiptCA : ReceiptUS;
  // Since we have receipt numbers available here, we can sort receipts by number
  const sortedGroups = groups.sort((a, b) => a.number - b.number);

  return (
    <Page style={styles.page} size={size} wrap>
      {preview && (
        <View style={styles.watermark} fixed>
          <Text style={styles.watermarkText}>PREVIEW</Text>
        </View>
      )}

      {withLetter && <PdfLetter year={groups[0].year} vars={vars} />}
      {sortedGroups.map((g, i) => (
        <View key={g.number} break={i !== 0 || withLetter}>
          <Template
            key={g.number}
            vars={vars}
            group={g}
            categories={categories}
            paymentMethods={paymentMethods}
            options={options}
            year={year}
          />
        </View>
      ))}
    </Page>
  );
}
