// import { Suspense, lazy, ElementType } from 'react';
import { Routes, Route } from 'react-router-dom';

import PATHS from '@routes/paths';
import { Role } from '@shared/types/organization';

import DefaultNavigate from './DefaultNavigate';
import AuthGuard from '@/guards/AuthGuard';
import GuestGuard from '@/guards/GuestGuard';
import OrgGuard from '@/guards/OrgGuard';
import AppLayout from '@/layouts/AppLayout';
// import LoadingScreen from '@components/LoadingScreen';
import OrgsGuard from '@/guards/OrgsGuard';
import PaywallDonorGuard from '@/guards/PaywallDonorGuard';
import RoleGuard from '@/guards/RoleGuard';

// ----------------------------------------------------------------------
// pages
import OrganizationCreate from '../pages/OrganizationCreate';
import Overview from '../pages/Overview';

// DONORS
import Donors from '../pages/donors';
import DonorDialog from '../pages/donors/dialogs/Donor';

import DonorsExport from '../pages/donors/dialogs/Export';
import ImportData from '../pages/ImportData';
import DonorsEditView from '../pages/donors/dialogs/EditView';
import DonorsManageViews from '../pages/donors/dialogs/ManageViews';

// DONATIONS
import Donations from '../pages/donations';
import DonationDialog from '../pages/donations/dialogs/Donation';
import DonationBatchDialog from '../pages/donations/dialogs/DonationBatch';
import DonationRecurring from '../pages/donations/dialogs/DonationRecurring';
import BankDepositDialog from '../pages/donations/dialogs/BankDeposit';
import BankDepositEditDialog from '../pages/donations/dialogs/BankDeposit/BankDepositEdit';

// PLEDGES
import PledgeDialog from '../pages/pledges/dialogs';

// REPORTS
import Reports from '../pages/reports';
import ReportPicker from '../pages/reports/Picker';

// RECEIPTS
import Receipts from '../pages/receipts/List';
import ReceiptsExport from '../pages/receipts/Export';
import ReceiptsView from '../pages/receipts/View';
import ReceiptsCreate from '../pages/receipts/Create';
import ReceiptsReissue from '../pages/receipts/Reissue';
import ReceiptsReissueBatch from '../pages/receipts/ReissueBatch';
import ReceiptsDeleteBatch from '../pages/receipts/DeleteBatch';

// AUTHENTICATION
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import Register from '../pages/auth/Register';
import ResetPassword from '../pages/auth/ResetPassword';
import NewPassword from '../pages/auth/NewPassword';
import VerifyCode from '../pages/auth/VerifyCode';

// SETTINGS
import Account from '../pages/settings/Account';
import ConfirmEmail from '../pages/settings/ConfirmEmail';
import SettingsAccountGeneral from '../pages/settings/Account/General';
import SettingsAccountSecurity from '../pages/settings/Account/Security';

import Organization from '../pages/settings/Organization';
import SettingsGeneral from '../pages/settings/Organization/General';
import SettingsPreferences from '../pages/settings/Organization/Preferences';
import AssignMemberNumbers from '../pages/donors/dialogs/Settings/MemberEnvelope/AssignNumbers';
import SettingsBilling from '../pages/settings/Organization/Billing';
import SettingsPaymentMethod from '../pages/donations/dialogs/Settings/PaymentMethods/Dialog';
import SettingsDonationCategory from '../pages/donations/dialogs/Settings/DonationCategories/Dialog';
import SettingsReceipts from '../pages/receipts/dialogs/Settings';
import SettingsUsers from '../pages/settings/Organization/Users';
import Settings from '../pages/donations/dialogs/Settings';
import SettingsDonors from '../pages/donors/dialogs/Settings';
import SettingsDonorsTag from '../pages/donors/dialogs/Settings/DonorTags/Dialog';

// ----------------------------------------------------------------------
export default function Router() {
  return (
    <Routes>
      {/* safety exit route */}
      <Route path={PATHS.logout} element={<Logout />} />
      {/* Email confirmation route */}
      <Route
        path={PATHS.confirmEmail}
        element={
          <AuthGuard>
            <ConfirmEmail />
          </AuthGuard>
        }
      />

      {/* PUBLIC routes */}
      <Route element={<GuestGuard />}>
        <Route path={PATHS.login} element={<Login />} />
        <Route path={PATHS.register} element={<Register />} />
        <Route path={PATHS.resetPassword} element={<ResetPassword />} />
        <Route path={PATHS.newPassword} element={<NewPassword />} />
        <Route path={PATHS.verify} element={<VerifyCode />} />
      </Route>

      {/* AUTH routes */}
      <Route
        path={PATHS.root}
        element={
          <AuthGuard>
            <OrgsGuard />
          </AuthGuard>
        }
      >
        {/* HOME routes - only accessible when user has 0 organizations */}
        {/* <Route path={PATHS.root} element={<Home />}>
          <Route path={PATHS.account} element={<HomeAccount />} />
        </Route> */}

        {/* CREATE ORGANIZATION flow */}
        <Route path={PATHS.createOrganization} element={<OrganizationCreate />} />

        {/* ORGANIZATION routes */}
        <Route
          path={PATHS.org.root}
          element={
            <OrgGuard>
              <AppLayout />
            </OrgGuard>
          }
        >
          <Route index element={<DefaultNavigate to={PATHS.org.overview} />} />

          <Route
            path={PATHS.org.import.root}
            element={
              <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.donors.root}>
                <ImportData />
              </RoleGuard>
            }
          />

          <Route path={PATHS.org.donors.root} element={<Donors />}>
            <Route
              path={PATHS.org.donors.export}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.donorOnly]}
                  fallback={PATHS.org.donors.root}
                >
                  <DonorsExport />
                </RoleGuard>
              }
            />

            <Route
              path={PATHS.org.donors.saveView}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.donorOnly]}
                  fallback={PATHS.org.donors.root}
                >
                  <DonorsEditView context="donors" />
                </RoleGuard>
              }
            />
            <Route
              path={PATHS.org.donors.manageViews}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.donorOnly]}
                  fallback={PATHS.org.donors.root}
                >
                  <DonorsManageViews context="donors" />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.donors.manageView}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.donorOnly]}
                    fallback={PATHS.org.donors.root}
                  >
                    <DonorsEditView context="donors" />
                  </RoleGuard>
                }
              />
            </Route>

            <Route
              path={PATHS.org.donors.donor.create}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.donorOnly]}
                  fallback={PATHS.org.donors.root}
                >
                  <PaywallDonorGuard>
                    <DonorDialog context="donors" />
                  </PaywallDonorGuard>
                </RoleGuard>
              }
            />

            <Route
              path={PATHS.org.donors.donor.edit}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.donorOnly, Role.readOnly]}
                  fallback={PATHS.org.donors.root}
                >
                  <DonorDialog context="donors" />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.donors.donor.donation.create}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor]}
                    fallback={PATHS.org.donors.root}
                  >
                    <DonationDialog context="donors" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donors.donor.donation.edit}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.readOnly]}
                    fallback={PATHS.org.donors.root}
                  >
                    <DonationDialog context="donors" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donors.donor.pledge.create}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor]}
                    fallback={PATHS.org.donors.root}
                  >
                    <PledgeDialog context="donors" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donors.donor.pledge.edit}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.readOnly]}
                    fallback={PATHS.org.donors.root}
                  >
                    <PledgeDialog context="donors" />
                  </RoleGuard>
                }
              />
            </Route>

            <Route path={PATHS.org.donors.settings.root}>
              <Route
                index
                element={<DefaultNavigate to={PATHS.org.donors.settings.donors.tags} />}
              />

              <Route
                path={PATHS.org.donors.settings.donors.tags}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.donorOnly]}
                    fallback={PATHS.org.root}
                  >
                    <SettingsDonors />
                  </RoleGuard>
                }
              >
                <Route
                  path={PATHS.org.donors.settings.donors.tagCreate}
                  element={<SettingsDonorsTag />}
                />
                <Route
                  path={PATHS.org.donors.settings.donors.tagsEdit}
                  element={<SettingsDonorsTag />}
                />
              </Route>
              <Route
                path={PATHS.org.donors.settings.donors.customFields}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.readOnly]}
                    fallback={PATHS.org.root}
                  >
                    <SettingsDonors context="custom-fields" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donors.settings.donors.memberEnvelope}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <SettingsDonors context="member-envelope" />
                  </RoleGuard>
                }
              >
                <Route
                  path={PATHS.org.donors.settings.donors.memberEnvelopeAssign}
                  element={<AssignMemberNumbers />}
                />
              </Route>
              <Route
                path="*"
                element={<DefaultNavigate to={PATHS.org.donors.settings.donors.tags} />}
              />
            </Route>
          </Route>

          <Route path={PATHS.org.donations.root}>
            <Route index element={<DefaultNavigate to={PATHS.org.donations.all.root} />} />

            <Route
              path={PATHS.org.donations.all.root}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.readOnly]}
                  fallback={PATHS.org.root}
                >
                  <Donations context="all" />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.donations.all.export}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <DonorsExport />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donations.all.saveView}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <DonorsEditView context="donations" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donations.all.manageViews}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <DonorsManageViews context="donations" />
                  </RoleGuard>
                }
              >
                <Route
                  path={PATHS.org.donations.all.manageView}
                  element={
                    <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                      <DonorsEditView context="donations" />
                    </RoleGuard>
                  }
                />
              </Route>
              <Route
                path={PATHS.org.donations.all.create}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <DonationDialog context="donations" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donations.all.batch}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <DonationBatchDialog />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donations.all.edit}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.readOnly]}
                    fallback={PATHS.org.root}
                  >
                    <DonationDialog context="donations" />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.donations.all.recurring}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <DonationRecurring />
                  </RoleGuard>
                }
              />
            </Route>

            <Route
              path={PATHS.org.donations.bankDeposits.root}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.readOnly]}
                  fallback={PATHS.org.root}
                >
                  <Donations context="bank-deposits" />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.donations.bankDeposits.bankDepositsCreate}
                element={<BankDepositDialog />}
              />
              <Route
                path={PATHS.org.donations.bankDeposits.bankDepositsEdit}
                element={<BankDepositEditDialog />}
              />
            </Route>

            <Route
              path={PATHS.org.donations.recurringDonations.root}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.readOnly]}
                  fallback={PATHS.org.root}
                >
                  <Donations context="recurring-donations" />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.donations.recurringDonations.recurringDonationsCreate}
                element={<DonationRecurring />}
              />
              <Route
                path={PATHS.org.donations.recurringDonations.recurringDonationsEdit}
                element={<DonationRecurring />}
              />
            </Route>

            <Route
              path={PATHS.org.donations.pledges.root}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.readOnly]}
                  fallback={PATHS.org.root}
                >
                  <Donations context="pledges" />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.donations.pledges.edit}
                element={<PledgeDialog context="pledges" />}
              />
              <Route
                path={PATHS.org.donations.pledges.create}
                element={<PledgeDialog context="pledges" />}
              />
            </Route>

            <Route path={PATHS.org.donations.settings.root}>
              <Route
                index
                element={<DefaultNavigate to={PATHS.org.donations.settings.categories} />}
              />

              <Route
                path={PATHS.org.donations.settings.categories}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.readOnly]}
                    fallback={PATHS.org.root}
                  >
                    <Settings />
                  </RoleGuard>
                }
              >
                <Route
                  path={PATHS.org.donations.settings.categoriesCreate}
                  element={<SettingsDonationCategory />}
                />
                <Route
                  path={PATHS.org.donations.settings.categoriesEdit}
                  element={<SettingsDonationCategory />}
                />
              </Route>

              <Route
                path={PATHS.org.donations.settings.paymentMethods}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor, Role.readOnly]}
                    fallback={PATHS.org.root}
                  >
                    <Settings context="payment-methods" />
                  </RoleGuard>
                }
              >
                <Route
                  path={PATHS.org.donations.settings.PaymentMethodsCreate}
                  element={<SettingsPaymentMethod />}
                />
                <Route
                  path={PATHS.org.donations.settings.PaymentMethodsEdit}
                  element={<SettingsPaymentMethod />}
                />
              </Route>

              <Route
                path={PATHS.org.donations.settings.pledges}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <Settings context="pledges" />
                  </RoleGuard>
                }
              />

              <Route
                path={PATHS.org.donations.settings.bankDeposits}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <Settings context="bank-deposits" />
                  </RoleGuard>
                }
              />

              <Route
                path="*"
                element={<DefaultNavigate to={PATHS.org.donations.settings.categories} />}
              />
            </Route>

            <Route path="*" element={<DefaultNavigate to={PATHS.org.donations.all.root} />} />
          </Route>

          <Route
            path={PATHS.org.overview}
            element={
              <RoleGuard
                roles={[Role.editor, Role.contributor, Role.readOnly]}
                fallback={PATHS.org.donors.root}
              >
                <Overview />
              </RoleGuard>
            }
          />

          <Route
            path={PATHS.org.reports.root}
            element={
              <RoleGuard
                roles={[Role.editor, Role.contributor, Role.readOnly]}
                fallback={PATHS.org.root}
              >
                <Reports />
              </RoleGuard>
            }
          >
            <Route path={PATHS.org.reports.report} element={<ReportPicker />} />
          </Route>

          <Route
            path={PATHS.org.receipts.root}
            element={
              <RoleGuard
                roles={[Role.editor, Role.contributor, Role.readOnly]}
                fallback={PATHS.org.root}
              >
                <Receipts />
              </RoleGuard>
            }
          >
            <Route
              path={PATHS.org.receipts.export}
              element={
                <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                  <ReceiptsExport />
                </RoleGuard>
              }
            />
            <Route
              path={PATHS.org.receipts.deleteBatch.root}
              element={
                <RoleGuard roles={[Role.editor]} fallback={PATHS.org.root}>
                  <ReceiptsDeleteBatch />
                </RoleGuard>
              }
            />
            <Route
              path={PATHS.org.receipts.reissueBatch.root}
              element={
                <RoleGuard roles={[Role.editor]} fallback={PATHS.org.root}>
                  <ReceiptsReissueBatch />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.receipts.reissueBatch.receipt}
                element={<ReceiptsView context="reissue-batch" />}
              />
            </Route>

            <Route
              path={PATHS.org.receipts.reissue.root}
              element={
                <RoleGuard roles={[Role.editor]} fallback={PATHS.org.root}>
                  <ReceiptsReissue />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.receipts.reissue.donation}
                element={<DonationDialog context="reissue" />}
              />
              <Route
                path={PATHS.org.receipts.reissue.donor}
                element={<DonorDialog context="reissue" />}
              />
            </Route>

            <Route
              path={PATHS.org.receipts.create.root}
              element={
                <RoleGuard roles={[Role.editor]} fallback={PATHS.org.root}>
                  <ReceiptsCreate />
                </RoleGuard>
              }
            >
              <Route
                path={PATHS.org.receipts.create.donor}
                element={<DonorDialog context="issue" />}
              >
                <Route
                  path={PATHS.org.receipts.create.donation}
                  element={<DonationDialog context="issue" />}
                />
              </Route>
            </Route>

            <Route path={PATHS.org.receipts.view} element={<ReceiptsView context="receipts" />} />
            <Route
              path={PATHS.org.receipts.settings.receipts}
              element={
                <RoleGuard roles={[Role.editor]} fallback={PATHS.org.receipts.root}>
                  <SettingsReceipts />
                </RoleGuard>
              }
            />
          </Route>

          <Route path={PATHS.org.settings.root}>
            <Route index element={<DefaultNavigate to={PATHS.org.settings.account.root} />} />

            <Route path={PATHS.org.settings.account.root} element={<Account />}>
              <Route index element={<DefaultNavigate to={PATHS.org.settings.account.general} />} />
              <Route
                path={PATHS.org.settings.account.general}
                element={<SettingsAccountGeneral />}
              />
              <Route
                path={PATHS.org.settings.account.security}
                element={<SettingsAccountSecurity />}
              />
              <Route
                path="*"
                element={<DefaultNavigate to={PATHS.org.settings.account.general} />}
              />
            </Route>

            <Route
              path={PATHS.org.settings.org.root}
              element={
                <RoleGuard
                  roles={[Role.editor, Role.contributor, Role.donorOnly]}
                  fallback={PATHS.org.root}
                >
                  <Organization />
                </RoleGuard>
              }
            >
              <Route index element={<DefaultNavigate to={PATHS.org.settings.org.general} />} />

              <Route
                path={PATHS.org.settings.org.general}
                element={
                  <RoleGuard
                    roles={[Role.editor, Role.contributor]}
                    fallback={PATHS.org.settings.org.donors}
                  >
                    <SettingsGeneral />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.settings.org.preferences}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <SettingsPreferences />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.settings.org.billing}
                element={
                  <RoleGuard roles={[Role.editor, Role.contributor]} fallback={PATHS.org.root}>
                    <SettingsBilling />
                  </RoleGuard>
                }
              />
              <Route
                path={PATHS.org.settings.org.users}
                element={
                  <RoleGuard roles={[]} fallback={PATHS.org.root}>
                    <SettingsUsers />
                  </RoleGuard>
                }
              />
              <Route path="*" element={<DefaultNavigate to={PATHS.org.settings.org.general} />} />
            </Route>

            <Route path="*" element={<DefaultNavigate to={PATHS.org.settings.root} />} />
          </Route>

          <Route path="*" element={<DefaultNavigate to={PATHS.org.overview} />} />
        </Route>
      </Route>
    </Routes>
  );
}

// before enabling, read this: https://github.com/orgs/vercel/discussions/812
// ----------------------------------------------------------------------
// const Loadable =
//   (Component: ElementType, withLoader = false) =>
//   (props: any) => (
//     <Suspense fallback={<LoadingScreen withLoader={withLoader} id="loadable" />}>
//       <Component {...props} />
//     </Suspense>
//   );

// ----------------------------------------------------------------------
// lazy loading - vercel purges cache, so we can't have old chunks available anymore
// const OrganizationCreate = Loadable(lazy(() => import('../pages/OrganizationCreate')));
// const Overview = Loadable(lazy(() => import('../pages/Overview')));

// // DONORS
// const Donors = Loadable(lazy(() => import('../pages/donors')));
// const DonorDialog = Loadable(lazy(() => import('../pages/donors/dialogs/Donor')));

// const DonorsExport = Loadable(lazy(() => import('../pages/donors/dialogs/Export')));
// const DonorsEditView = Loadable(lazy(() => import('../pages/donors/dialogs/EditView')));
// const DonorsManageViews = Loadable(lazy(() => import('../pages/donors/dialogs/ManageViews')));

// // DONATIONS
// const Donations = Loadable(lazy(() => import('../pages/donations')));
// const DonationDialog = Loadable(lazy(() => import('../pages/donations/dialogs/Donation')));
// const DonationBatchDialog = Loadable(
//   lazy(() => import('../pages/donations/dialogs/DonationBatch'))
// );

// // REPORTS
// const Reports = Loadable(lazy(() => import('../pages/reports')));
// const ReportPicker = Loadable(lazy(() => import('../pages/reports/Picker')));

// // RECEIPTS
// const Receipts = Loadable(lazy(() => import('../pages/receipts/List')));
// const ReceiptsExport = Loadable(lazy(() => import('../pages/receipts/Export')));
// const ReceiptsView = Loadable(lazy(() => import('../pages/receipts/View')));
// const ReceiptsCreate = Loadable(lazy(() => import('../pages/receipts/Create')));
// const ReceiptsReissue = Loadable(lazy(() => import('../pages/receipts/Reissue')));
// const ReceiptsReissueBatch = Loadable(lazy(() => import('../pages/receipts/ReissueBatch')));

// // AUTHENTICATION
// const Login = Loadable(
//   lazy(() => import('../pages/auth/Login')),
//   true
// );
// const Logout = Loadable(
//   lazy(() => import('../pages/auth/Logout')),
//   true
// );
// const Register = Loadable(
//   lazy(() => import('../pages/auth/Register')),
//   true
// );
// const ResetPassword = Loadable(
//   lazy(() => import('../pages/auth/ResetPassword')),
//   true
// );
// const NewPassword = Loadable(
//   lazy(() => import('../pages/auth/NewPassword')),
//   true
// );
// const VerifyCode = Loadable(
//   lazy(() => import('../pages/auth/VerifyCode')),
//   true
// );

// // SETTINGS
// const Account = Loadable(lazy(() => import('../pages/settings/Account')));
// const ConfirmEmail = Loadable(lazy(() => import('../pages/settings/ConfirmEmail')));
// const SettingsAccountGeneral = Loadable(lazy(() => import('../pages/settings/Account/General')));
// const SettingsAccountSecurity = Loadable(lazy(() => import('../pages/settings/Account/Security')));

// const Organization = Loadable(lazy(() => import('../pages/settings/Organization')));
// const SettingsGeneral = Loadable(lazy(() => import('../pages/settings/Organization/General')));
// const SettingsPreferences = Loadable(
//   lazy(() => import('../pages/settings/Organization/Preferences'))
// );
// const SettingsPreferencesAssign = Loadable(
//   lazy(() => import('../pages/settings/Organization/Preferences/AssignNumbers'))
// );
// const SettingsBilling = Loadable(lazy(() => import('../pages/settings/Organization/Billing')));
// const SettingsTags = Loadable(lazy(() => import('../pages/settings/Organization/Tags')));
// const SettingsTagsEdit = Loadable(lazy(() => import('../pages/settings/Organization/Tags/Dialog')));
// const SettingsCategories = Loadable(
//   lazy(() => import('../pages/settings/Organization/Categories'))
// );
// const SettingsCategoriesEdit = Loadable(
//   lazy(() => import('../pages/settings/Organization/Categories/Dialog'))
// );
// const SettingsPaymentMethods = Loadable(
//   lazy(() => import('../pages/settings/Organization/PaymentMethods'))
// );
// const SettingsPaymentMethodsEdit = Loadable(
//   lazy(() => import('../pages/settings/Organization/PaymentMethods/Dialog'))
// );
// const SettingsReceipts = Loadable(lazy(() => import('../pages/settings/Organization/Receipts')));
// const SettingsUsers = Loadable(lazy(() => import('../pages/settings/Organization/Users')));
// const SettingsSecurity = Loadable(lazy(() => import('../pages/settings/Organization/Security')));
