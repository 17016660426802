import { useState, useMemo, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useOrg from '@hooks/useOrg';
import { Grid, Button, SelectChangeEvent } from '@mui/material';
import Dialog from '@components/Dialog';
import ConfirmDialog from '@components/ConfirmDialog';
import FilterStack from '@pages/receipts/shared/FilterStack';
import useNavigate from '@hooks/useNavigate';
import useDonation from '@hooks/useDonation';
import { isNumber, sortBy, reverse, uniq, maxBy } from 'lodash';

// ----------------------------------------------------------------------
export default function ReceiptsDelete() {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [receiptingYear, setReceiptingYear] = useState<number | undefined>(undefined);
  const [rangeFrom, setRangeFrom] = useState<number | undefined>(1);
  const [rangeTo, setRangeTo] = useState<number | undefined>(undefined);
  const [rangeConfirmed, setRangeConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { org } = useOrg();
  const { enqueueSnackbar } = useSnackbar();
  const { receipts: allReceipts, deleteReceipts } = useDonation();
  const receiptingYears = useMemo(() => reverse(sortBy(uniq(allReceipts.map((r) => r.year)))), [allReceipts]);
  
  useEffect(() => {
    const defaultReceiptingYear = receiptingYears[0];
    const rangeTo = maxBy(
      allReceipts.filter((r) => r.year === defaultReceiptingYear),
      (r) => r.number
    )?.number;

    setReceiptingYear(defaultReceiptingYear);
    setRangeTo(rangeTo);
  }, [receiptingYears, allReceipts]);

  const confirmText = "CAUTION: Deleting these receipts is permanent and cannot be undone. Are you sure you want to proceed?";

  const handleClose = () => {
    setConfirmDialog(false);
    navigate(-1);
  }

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await deleteReceipts({
        orgId: org!.id,
        year: receiptingYear!,
        range: { from: rangeFrom! },
      });
  
      handleClose();
      enqueueSnackbar('Receipts deleted successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error deleting receipts', { variant: 'error' });
    }
  }

  const handleReceiptingYearChange = (event: SelectChangeEvent<number>) => {
    const year = event.target.value as number;
    setReceiptingYear(year);

    const rangeTo = maxBy(
      allReceipts.filter((r) => r.year === year),
      (r) => r.number
    )?.number;
    setRangeTo(rangeTo);
  }

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setRangeFrom(isNumber(value) ? value : undefined);
  }

  return (
    <Dialog
      title="Delete Receipts"
      onClose={handleClose}
      maxWidth="sm"
      contentProps={{ sx: { pt: 1, height: '10vh' } }}
      actions={
        <>
        <ConfirmDialog
          title="Confirm Delete?"
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          loading={isSubmitting}
          onConfirm={handleDelete}
        >
          {confirmText}
        </ConfirmDialog>
        <Button
          size="large"
          variant="contained"
          disabled={false}
          onClick={() => setConfirmDialog(true)}
          sx={{ minWidth: 180 }}
        >
          Next
        </Button>
        </>
      }
    >
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <FilterStack
            isDeleteRange={true}
            receiptingYears={receiptingYears}
            receiptingYear={receiptingYear}
            rangeFrom={rangeFrom}
            rangeTo={rangeTo}
            rangeConfirmed={rangeConfirmed}
            onReceiptingYear={handleReceiptingYearChange}
            onFromChange={handleFromChange}
            onConfirm={() => setRangeConfirmed(true)}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
