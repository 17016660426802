import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { BankDepositSchemaForm } from '@/schemas/bankDeposit';
import { StepId } from './useSteps';

type Props = {
  stepData: BankDepositSchemaForm;
  handleNext: () => void;
  handleBack?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  hasFailed?: boolean;
  isSubmitting?: boolean;
  error?: string;
  actionText: string;
};

export default function StepActions({
  stepData,
  handleNext,
  handleBack,
  onDelete,
  disabled = false,
  hasFailed = false,
  isSubmitting = false,
  error = '',
  actionText,
}: Props) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      <Box>
        {error && (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        )}
        {onDelete && (
          <Button color="error" onClick={onDelete} sx={{ ml: error ? 2 : 0 }}>
            Delete Bank Deposit
          </Button>
        )}
      </Box>

      <Stack direction="row" spacing={2}>
        {handleBack && (
          <Button variant="outlined" onClick={handleBack} disabled={isSubmitting}>
            Back
          </Button>
        )}

        <Button
          variant="contained"
          onClick={handleNext}
          disabled={disabled || isSubmitting}
          endIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : undefined}
        >
          {stepData.step === StepId.donations ? (hasFailed ? 'Try Again' : actionText) : 'Save'}
        </Button>
      </Stack>
    </Stack>
  );
}
