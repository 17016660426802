import { Meta } from '../index';
export * from './requests';

export type BankDeposit = {
  readonly id: string;
  readonly _meta: Meta;
  readonly orgId: string;

  readonly date: string; // ISO format 8-character date, format YYYY-MM-DD
  readonly bankAccount: string; // name of account we are depositing to
  readonly accountNumber?: string; // number of that account
  readonly description?: string; // distinguishes multiple deposits to the same account on the same date
  readonly advantageAccount?: string; // Income account for any Advantage amounts for donations w/ advantage
  readonly advantageClass?: string; // class used with the advantageAccount, in QuickBooks
  readonly type: BankDepositType;

  // the next three only apply to Individual Transactions bank deposits!
  readonly feeAccount?: string; // Income account for any fee amounts in donations
  readonly feeClass?: string; // class used with the feeAccount, in QuickBooks
  readonly paymentMethodId?: string; // the id of the Payment Method of the included donations
  readonly v1DepositNum?: number; // the DEPOSITNUM value from v1, for back-migrations
};

export enum BankDepositType {
  cash = 'cash', // cash, checks, money orders - anything you actually go and deposit in your bank
  indivTxns = 'indiv', // individual transactions for things like eTransfer, DirectDeposit, ACH etc. that just appear in your bank account
  abundant = 'abundant', // not in v2, but used in v1 so can be migrated! For its Abundant online donations service.
}
