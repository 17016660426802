import AllDonations from './AllDonations';
import TotalDonationByMonth from './TotalDonationByMonth';
import AllReceipts from './AllReceipts';
import FirstTimeGivers from './FirstTimeGivers';
import LYBUNT from './LYBUNT';
import MailingLabels from './MailingLabels';
import NonReceiptableDonations from './NonReceiptableDonations';
import OneDateDonation from './OneDateDonation';
import OnetimeDonors from './OnetimeDonors';
import ReceiptedDonations from './ReceiptedDonations';
import SummaryByCategory from './SummaryByCategory';
import SummaryByDateAndCategory from './SummaryByDateAndCategory';
import SummaryByDonor from './SummaryByDonor';
import SummaryByDonorAndCategory from './SummaryByDonorAndCategory';
import SummaryByPaymentMethod from './SummaryByPaymentMethod';
import UnreceiptedDonations from './UnreceiptedDonations';
import Pledges from './Pledges';
import SingleDonorDetails from './SingleDonorDetails';
import EnvelopeBoxLabels from './EnvelopeBoxLabels';
import BankDepositDetails from './BankDepositDetails';
import AllDonationsWithDepositStatus from './AllDonationsWithDepositStatus';
import BankDepositSlip from './BankDepositSlip';

// ----------------------------------------------------------------------
const allReports = [
  {
    id: 'all-donations',
    name: 'All Donations',
    description: 'All donations within a date range',
    category: 'Donation Details',
    Component: AllDonations,
  },
  {
    id: 'single-donor',
    name: 'Single Donor Donations',
    description: 'All donations for one donor within a date range',
    category: 'Donation Details',
    Component: SingleDonorDetails,
  },
  {
    id: 'total-Donations-by-Month',
    name: 'Summary by Month',
    description: 'The total amount of donations in each month',
    category: 'Donation Summaries',
    Component: TotalDonationByMonth,
  },
  {
    id: 'one-date-donation',
    name: 'One Date Donation',
    description: 'Donation details and/or summaries for one date',
    category: 'Donation Details',
    Component: OneDateDonation,
  },
  {
    id: 'first-time-givers',
    name: 'First-Time Givers',
    description: 'People who have donated for the first time',
    category: 'Donation Details',
    Component: FirstTimeGivers,
  },
  {
    id: 'lybunt',
    name: 'Old Donors',
    description: 'LYBUNT / SYBUNT: Donors Last Year (or Some Year) But Unfortunately Not This Year',
    category: 'Donation Summaries',
    Component: LYBUNT,
  },
  {
    id: 'one-time-donors',
    name: 'One-Time Donors',
    description: 'Donors who have only given once',
    category: 'Donation Details',
    Component: OnetimeDonors,
  },
  {
    id: 'summary-by-donor',
    name: 'Summary by Donor',
    description: 'Total donation amounts by donor',
    category: 'Donation Summaries',
    Component: SummaryByDonor,
  },
  {
    id: 'summary-by-category',
    name: 'Summary by Category',
    description: 'Total donation amounts by donation category',
    category: 'Donation Summaries',
    Component: SummaryByCategory,
  },
  {
    id: 'summary-by-date-and-category',
    name: 'Summary by Date and Category',
    description: 'Total donation amounts by date and donation category',
    category: 'Donation Summaries',
    Component: SummaryByDateAndCategory,
  },
  {
    id: 'summary-by-donor-and-category',
    name: 'Summary by Donor and Category',
    description: 'Total donation amounts by category, for each donor',
    category: 'Donation Summaries',
    Component: SummaryByDonorAndCategory,
  },
  {
    id: 'summary-by-payment-method',
    name: 'Summary by Payment Method',
    description: 'Total donation amounts by payment method',
    category: 'Donation Summaries',
    Component: SummaryByPaymentMethod,
  },
  {
    id: 'pledges',
    name: 'Pledges and Actuals',
    description: 'Pledges and their actual donation totals',
    longDescription:
      'Includes all pledges whose date range is entirely contained in the date range specified for the report',
    category: 'Donation Summaries',
    Component: Pledges,
  },
  {
    id: 'donor-mailing-labels',
    name: 'Donor Mailing Labels',
    description: 'Labels for all or some donors, 20 or 30 per page',
    category: 'Donors',
    Component: MailingLabels,
  },
  {
    id: 'all-receipts',
    name: 'All Receipts',
    description: 'Summary details of all receipts issued for a selected year',
    category: 'Receipts',
    Component: AllReceipts,
  },
  {
    id: 'receipt-mailing-labels',
    name: 'Receipt Mailing Labels',
    description: 'Labels for all donors with receipts issued on a single date, 20 or 30 per page',
    category: 'Receipts',
    Component: MailingLabels,
  },
  {
    id: 'receipted-donations',
    name: 'Receipted Donations',
    description: 'All donations with receipt numbers for a selected year',
    category: 'Receipts',
    Component: ReceiptedDonations,
  },
  {
    id: 'unreceipted-donations',
    name: 'Unreceipted Donations',
    description:
      "Donations eligible for being included in a receipt that don't have a receipt number",
    category: 'Receipts',
    Component: UnreceiptedDonations,
  },
  {
    id: 'nonreceiptable-donations',
    name: 'Non-Receiptable Donations',
    description: 'Donations that are non-receiptable',
    longDescription:
      'Donations that are non-receiptable, either because their donor is marked Non-Receiptable or the donation itself is marked Non-Receiptable',
    category: 'Receipts',
    Component: NonReceiptableDonations,
  },
  {
    id: 'env-box-labels',
    name: 'Envelope Box Labels',
    description: 'Labels with Envelope # and Name, 20 or 30 per page',
    category: 'Donors',
    Component: EnvelopeBoxLabels,
  },
  {
    id: 'bank-deposit-details',
    name: 'Bank Deposit Details',
    description: 'All details of one bank deposit',
    category: 'Bank Deposits',
    Component: BankDepositDetails,
  },
  {
    id: 'all-donations-with-deposit-status',
    name: 'All Donations with Deposit Status',
    description: 'Shows all donations with their bank deposit status',
    category: 'Bank Deposits',
    Component: AllDonationsWithDepositStatus,
  },
  {
    id: 'bank-deposit-slip',
    name: 'Bank Deposit Slip',
    description: 'Generate a deposit slip for bank deposits',
    category: 'Bank Deposits',
    Component: BankDepositSlip,
  },
  // {
  //   name: 'Household averages',
  //   description: 'Average raised this fiscal year and last fiscal year by household',
  //   Component: OnetimeDonors,
  // },
  // {
  //   name: 'Projected cash flow',
  //   description: 'Pledges and recurring donations with projected revenue this fiscal year',
  //   Component: OnetimeDonors,
  // },
];

export default allReports;
