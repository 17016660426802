import { useState, useMemo, useEffect } from 'react';
import { Typography, TextField, Stack, Box, Button } from '@mui/material';
import {
  CashCountMoneyType,
  CashCountOptionsCanada,
  CashCountOptionsUSA,
  CashCount,
} from '@shared/types/cashCount';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import { sumBy } from 'lodash';
import Dialog from '@components/Dialog';
import ConfirmDialog from '@components/ConfirmDialog';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import CashCountExport from './CashCountExport';
import useFormat from '@hooks/useFormat';
import { subDays } from 'date-fns';

type Props = {
  open: boolean;
  onClose: () => void;
  bankDepositId?: string;
  expectedCashTotal?: number;
  date: Date;
};

export default function CashCountModal({
  open,
  onClose,
  bankDepositId,
  expectedCashTotal = 0,
  date,
}: Props) {
  const { org } = useOrg();
  const { fCurrency, fDate, fDateToDayStart, fDateToDayEnd, isDateBetween, fDateToISO } =
    useFormat();
  const { enqueueSnackbar } = useSnackbar();
  const { createCashCount, updateCashCount, cashCounts } = useDonation();
  const [isConfirmingMismatch, setIsConfirmingMismatch] = useState(false);
  const [isConfirmingExportMismatch, setIsConfirmingExportMismatch] = useState(false);
  const [isConfirmingLoad, setIsConfirmingLoad] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [existingCount, setExistingCount] = useState<CashCount | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const options = useMemo(
    () => (org?.address.country === 'ca' ? CashCountOptionsCanada : CashCountOptionsUSA),
    [org?.address.country]
  );

  const [counts, setCounts] = useState<Record<string, number>>({});

  const findCashCountForDate = (searchDate: Date) => {
    const dayStart = fDateToDayStart(searchDate);
    const dayEnd = fDateToDayEnd(searchDate);

    return cashCounts.find(
      (count) =>
        !count.bankDepositId && // only counts from batch entry
        isDateBetween(new Date(count.date), dayStart, dayEnd)
    );
  };

  const findCashCountForBankDeposit = (depositId: string) =>
    cashCounts.find((count) => count.bankDepositId === depositId);

  const findRecentCashCount = (searchDate: Date) => {
    const weekBefore = fDateToDayStart(subDays(searchDate, 6));
    const dateEnd = fDateToDayEnd(searchDate);

    return cashCounts
      .filter(
        (count) =>
          !count.bankDepositId && // only counts from batch entry
          isDateBetween(new Date(count.date), weekBefore, dateEnd)
      )
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
  };

  const loadExistingCount = (count: CashCount) => {
    const newCounts: Record<string, number> = {};
    count.items.forEach((item) => {
      newCounts[item.optionsId] = item.count;
    });
    setCounts(newCounts);
    setIsUpdating(true);
    setExistingCount(count);
  };

  useEffect(() => {
    if (!open) return;

    if (bankDepositId) {
      // For bank deposit, first check for count linked to this deposit
      const depositCount = findCashCountForBankDeposit(bankDepositId);
      if (depositCount) {
        loadExistingCount(depositCount);
        return;
      }

      // If no linked count, check for recent counts
      const recentCount = findRecentCashCount(date);
      if (recentCount) {
        setExistingCount(recentCount);
        setIsConfirmingLoad(true);
      }
    } else {
      // For batch entry, check for count on same date
      const dateCount = findCashCountForDate(date);
      if (dateCount) {
        setExistingCount(dateCount);
        setIsConfirmingLoad(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, bankDepositId, date]);

  const totals = useMemo(() => {
    const coinTotal = sumBy(
      options.filter((opt) => opt.type === CashCountMoneyType.coin),
      (opt) => (counts[opt.id] || 0) * opt.denomination
    );
    const billTotal = sumBy(
      options.filter((opt) => opt.type === CashCountMoneyType.bill),
      (opt) => (counts[opt.id] || 0) * opt.denomination
    );
    return {
      coins: coinTotal,
      bills: billTotal,
      total: coinTotal + billTotal,
    };
  }, [counts, options]);

  const hasAnyCounts = useMemo(() => Object.values(counts).some((count) => count > 0), [counts]);

  const dialogTitle = useMemo(
    () => `Cash ${isUpdating ? 'Count Update' : 'Counting'} for ${fDate(date)}`,
    [date, fDate, isUpdating]
  );

  const handleExport = () => {
    if (expectedCashTotal > 0 && totals.total !== expectedCashTotal) {
      setIsConfirmingExportMismatch(true);
      return;
    }
    setOpenExport(true);
  };

  const handleClose = () => {
    setCounts({});
    setIsUpdating(false);
    setExistingCount(null);
    onClose();
  };

  const handleCountChange = (id: string, value: string) => {
    const numValue = parseInt(value) || 0;
    setCounts((prev) => ({
      ...prev,
      [id]: numValue,
    }));
  };

  const handleSave = async () => {
    if (expectedCashTotal > 0 && totals.total !== expectedCashTotal) {
      setIsConfirmingMismatch(true);
      return;
    }
    await saveCashCount();
  };

  const saveCashCount = async () => {
    try {
      setIsSaving(true);
      const items = Object.entries(counts)
        .filter(([_, count]) => count > 0)
        .map(([optionsId, count]) => ({
          optionsId,
          count,
        }));

      if (isUpdating && existingCount) {
        await updateCashCount({
          cashCountId: existingCount.id,
          update: {
            orgId: org?.id || '',
            bankDepositId: bankDepositId || '',
            date: fDateToISO(date),
            items,
          },
        });
        enqueueSnackbar('Cash count updated!');
      } else {
        await createCashCount({
          orgId: org?.id || '',
          bankDepositId: bankDepositId || '',
          date: fDateToISO(date),
          items,
        });
        enqueueSnackbar('Cash count created!');
      }
      setCounts({});
      setIsUpdating(false);
      setExistingCount(null);
      onClose();
    } catch (error) {
      console.error('Failed to save cash count:', error);
    } finally {
      setIsSaving(false);
      setIsConfirmingMismatch(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        title={dialogTitle}
        maxWidth="sm"
        sx={{ '& .MuiDialog-paper': { height: '100%' } }}
        actions={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={handleExport} variant="outlined" disabled={!hasAnyCounts}>
              Export
            </Button>
            <LoadingButton
              onClick={handleSave}
              variant="contained"
              loading={isSaving}
              disabled={!hasAnyCounts}
            >
              {isUpdating ? 'Update' : 'Save'}
            </LoadingButton>
          </Box>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.2)',
                borderRadius: '4px',
              },
            }}
          >
            <Stack spacing={1}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '100px 20px auto 20px 100px',
                  gap: 1,
                  pr: 2,
                  mb: 1,
                  flex: 'none',
                }}
              >
                <Typography fontWeight="bold"># Coins / Bills</Typography>
                <Typography fontWeight="bold">X</Typography>
                <Typography fontWeight="bold">Denomination</Typography>
                <Typography fontWeight="bold">=</Typography>
                <Typography fontWeight="bold" align="right">
                  Total
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1, overflow: 'auto', minHeight: 0 }}>
                <Stack spacing={1}>
                  {options.map((option) => (
                    <Box
                      key={option.id}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '100px 20px auto 20px 100px',
                        gap: 1,
                        pr: 2,
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        size="small"
                        type="number"
                        value={counts[option.id] || ''}
                        onChange={(e) => handleCountChange(option.id, e.target.value)}
                        inputProps={{ style: { textAlign: 'right' } }}
                      />
                      <Typography>X</Typography>
                      <Typography>
                        {option.name ||
                          `${fCurrency(option.denomination).replace('.00', '')} Bills`}
                      </Typography>
                      <Typography>=</Typography>
                      <Typography align="right">
                        {counts[option.id]
                          ? fCurrency((counts[option.id] || 0) * option.denomination)
                          : ''}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Box>

          <Box
            sx={{
              borderTop: '1px solid #ddd',
              mt: 1,
              backgroundColor: 'background.paper',
              flexShrink: 0,
            }}
          >
            {expectedCashTotal > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Expected Total:</Typography>
                <Typography>{fCurrency(expectedCashTotal)}</Typography>
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Total Coins:</Typography>
              <Typography>{fCurrency(totals.coins)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Total Bills:</Typography>
              <Typography>{fCurrency(totals.bills)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography fontWeight="bold">Total Cash:</Typography>
              <Typography fontWeight="bold">{fCurrency(totals.total)}</Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <ConfirmDialog
        open={isConfirmingMismatch}
        onClose={() => setIsConfirmingMismatch(false)}
        onConfirm={saveCashCount}
        loading={isSaving}
        title="Total Mismatch"
        contentText={`Your entered total (${fCurrency(totals.total)}) doesn't match the expected total (${fCurrency(expectedCashTotal)}). Do you still want to save?`}
        bgColorConfirm="warning"
        textConfirmButton="Save Anyway"
      />
      <ConfirmDialog
        open={isConfirmingExportMismatch}
        onClose={() => setIsConfirmingExportMismatch(false)}
        onConfirm={() => {
          setIsConfirmingExportMismatch(false);
          setOpenExport(true);
        }}
        title="Total Mismatch"
        contentText={`Your entered total (${fCurrency(totals.total)}) doesn't match the expected total (${fCurrency(expectedCashTotal)}). Do you still want to export?`}
        bgColorConfirm="warning"
        textConfirmButton="Export Anyway"
      />

      <ConfirmDialog
        open={isConfirmingLoad}
        onClose={() => setIsConfirmingLoad(false)}
        onConfirm={() => {
          if (existingCount) {
            loadExistingCount(existingCount);
          }
          setIsConfirmingLoad(false);
        }}
        title="Load Existing Count?"
        contentText={`There is an existing cash count from ${fDate(existingCount ? new Date(existingCount.date) : date)}. Would you like to load it as your starting point?`}
        textConfirmButton="Yes, load count"
        textCancelButton="No, start fresh"
      />

      <CashCountExport
        open={openExport}
        onClose={() => setOpenExport(false)}
        options={options}
        counts={counts}
        totals={totals}
        date={date}
      />
    </>
  );
}
