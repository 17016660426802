import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const CategorySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string(),
  colorBackground: Yup.string().required(),
  colorText: Yup.string().required(),
  accountingAccount: Yup.string(),
  accountingClass: Yup.string(),
});

export const categorySchemaResolver = yupResolver(CategorySchema);
export type CategorySchemaForm = {
  name: string;
  description: string;
  colorBackground: string;
  colorText: string;
  accountingAccount: string;
  accountingClass: string;

  afterSubmit?: string;
};
