import { useMemo } from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import useFormat from '@hooks/useFormat';
import { RecurringDonationSchemaForm } from '@/schemas';
import { RecurringDonation as RD } from '@shared/types';

import { getFrequencyLabel, calcFinalOccurrence, PeriodLabel } from '@utils/recurringDonation';

import { DonationRecurringContext } from './index';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  readonly context: DonationRecurringContext;
};
// ----------------------------------------------------------------------
export default function InfoForm({ context }: Props) {
  const { fShortDate } = useFormat();

  const { watch } = useFormContext<RecurringDonationSchemaForm>();
  const wStartDate = watch('startDate');
  const wPeriod = watch('period');
  const wEndingOption = watch('endingOption');
  const wOccurrenceCount = watch('occurrenceCount');
  const wOccurrenceLimit = watch('occurrenceLimit');
  const wEndDate = watch('endDate');

  const [finalOccurrence, finalNumOccurrences] = useMemo(() => {
    const numOccurrences = Number(wOccurrenceLimit);
    const [date, occurrences] = calcFinalOccurrence(wStartDate, wPeriod, {
      endingOption: wEndingOption,
      occurrenceLimit: numOccurrences,
      endDate: wEndDate,
    });
    return [date && fShortDate(date), occurrences];
  }, [fShortDate, wEndDate, wEndingOption, wOccurrenceLimit, wPeriod, wStartDate]);

  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        '.MuiAlert-message': { px: 2 },
        border: (theme) => `1px solid ${theme.palette.grey[600]}14`,
        background: (theme) => theme.palette.grey[100],
      }}
    >
      {context.isEdit && (
        <Stack direction="row" mb={1.5} ml={-0.5} spacing={1}>
          <Iconify icon={'dashicons:warning'} width={22} height={22} color="orange" />
          <Typography variant="body2">
            {context.isExpired
              ? "Recurring donation has expired, you can't edit anymore."
              : 'Only the donation details can be changed when you are updating a recurring donation.'}{' '}
          </Typography>
        </Stack>
      )}

      <Typography variant="body2">
        Repeats:{' '}
        <strong>
          {PeriodLabel[wPeriod]} {getFrequencyLabel(wStartDate, wPeriod)}
        </strong>
        {wOccurrenceCount > 0 && (
          <span>
            , occurred <strong>{wOccurrenceCount}</strong> time(s) already
          </span>
        )}
      </Typography>

      {!!context.immediateDonations.length && (
        <Typography variant="body2">
          Immediately created donations: <strong>{context.immediateDonations.length}</strong>
        </Typography>
      )}

      <Typography variant="body2">
        Next occurrence:{' '}
        <strong>
          {context.recurringDonation?.state === RD.RecurringState.expired || context.willNeverRepeat
            ? 'Never'
            : fShortDate(context.nextOccurrence)}
        </strong>
      </Typography>

      <Typography variant="body2">
        Final occurrence: <strong>{finalOccurrence || '-'}</strong>
        {!!finalNumOccurrences && (
          <Typography variant="body2" component="span">
            , after a total of <strong>{finalNumOccurrences}</strong> occurrences
          </Typography>
        )}
      </Typography>
    </Alert>
  );
}
