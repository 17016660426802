import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Stack, Typography, Alert, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Outlet } from 'react-router';

import { BankDepositSettingsSchemaForm, bankDepositSettingsSchemaResolver } from '@/schemas';

import useOrg from '@hooks/useOrg';

import { FormProvider, RHFCheckbox, RHFSelect } from '@components/hook-form';
import { Organization } from '@shared/types';
import useDonation from '@hooks/useDonation';

// ----------------------------------------------------------------------
export default function BankDeposits() {
  const { org, updateOrg } = useOrg();
  const { enqueueSnackbar } = useSnackbar();
  const { isTypeQuickBooks } = useDonation();
  
  const defaultValues: BankDepositSettingsSchemaForm = useMemo(
    () => ({
      type: org?.bankDepositSettings.type || Organization.BankDepositAccountingProgram.None,
      useClasses: org?.bankDepositSettings.useClasses || false,
      dayFirst: org?.bankDepositSettings.dayFirst || false,
    }),
    [
      org?.bankDepositSettings.dayFirst,
      org?.bankDepositSettings.type,
      org?.bankDepositSettings.useClasses,
    ]
  );

  // --------------- form ---------------
  const methods = useForm<BankDepositSettingsSchemaForm>({
    resolver: bankDepositSettingsSchemaResolver,
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    watch,
  } = methods;

  const watchedType = watch('type');

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const onSubmit = async (data: BankDepositSettingsSchemaForm) => {
    if (!org) return null;

    try {
      const isQB = isTypeQuickBooks(data.type);
      const res = await updateOrg({
        orgId: org.id,
        update: {
          bankDepositSettings: {
            type: data.type,
            ...(isQB ? { useClasses: data.useClasses } : {}),
            ...(isQB ? { dayFirst: data.dayFirst } : {}),
          },
        },
      });

      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Outlet />
      <Stack marginTop={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RHFSelect fullWidth name="type" label="Type" required>
              {Object.values(Organization.BankDepositAccountingProgram).map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>

          {(watchedType === Organization.BankDepositAccountingProgram.QBD ||
            watchedType === Organization.BankDepositAccountingProgram.QBO) && (
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle2">For QuickBooks:</Typography>
              </Grid>
              <Grid item xs={12}>
                <RHFCheckbox
                  name="useClasses"
                  label="Use Classes as well as Accounts"
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 28, width: 28, height: 28 },
                    '& .MuiFormControlLabel-label': { fontSize: 18 },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFCheckbox
                  name="dayFirst"
                  label="Export dates as DD/MM/YYYY instead of standard MM/DD/YYYY"
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 28, width: 28, height: 28 },
                    '& .MuiFormControlLabel-label': { fontSize: 18 },
                  }}
                />
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty}
              sx={{ width: { xs: '50%', md: 'auto' } }}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </FormProvider>
  );
}
