import Scrollbar from '@components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import useTable, { emptyRows } from '@hooks/useTable';
import {
  Grid,
  Box,
  Button,
  Stack,
  TablePagination,
  TableContainer,
  Card,
  Table,
  TableBody,
} from '@mui/material';
import Iconify from '@components/Iconify';
import { Outlet } from 'react-router';
import { BANK_DEPOSIT_LIST_HEADERS, BankDepositListHeader } from './List/config';
import Row from './List/Row';
import useData from './List/useData';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';
import getPage from '@utils/getPage';

export default function BankDepositList() {
  const { columns, page, rowsPerPage, onChangePage, onChangeRowsPerPage, onSort } =
    useTable<BankDepositListHeader>({
      defaultRowsPerPage: 25,
      defaultColumns: BANK_DEPOSIT_LIST_HEADERS,
      defaultOrderBy: 'date',
      defaultOrder: 'desc',
    });

  const data = useData();
  const navigate = useNavigate();
  const { org } = useOrg();

  const handleAdd = () => {
    navigate(getPath(PATHS.org.donations.bankDeposits.bankDepositsCreate, { orgId: org!.id }));
  };

  const handleEdit = (bankDepositId: string) => {
    navigate(
      getPath(PATHS.org.donations.bankDeposits.bankDepositsEdit, {
        orgId: org!.id,
        bankDepositId,
      })
    );
  };

  return (
    <Box sx={{ paddingTop: 3 }}>
      <Grid container spacing={2}>
        <Outlet />
        <Grid item xs={12} md={12}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleAdd}
            >
              New Bank Deposit
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <TableContainer sx={{ minWidth: '100%', position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table stickyHeader size="small">
                  <TableHeadCustom
                    columns={columns}
                    rowCount={data.length}
                    onSort={onSort as (id: string) => void}
                  />

                  <TableBody>
                    {getPage(data, page, rowsPerPage).map((item: any) => (
                      <Row key={item.id} item={item} onEdit={handleEdit} />
                    ))}

                    <TableEmptyRows
                      height={52}
                      emptyRows={emptyRows(page, rowsPerPage, data.length)}
                    />
                    <TableNoData isNotFound={!data.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
