import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import useFormat from '@hooks/useFormat';
import { Organization } from '@shared/types';
import { RecurringDonationSchemaForm } from '@/schemas';

import ConfirmDialog from '@components/ConfirmDialog';
import useRole from '@hooks/useRole';
import useDonation from '@hooks/useDonation';

import { DonationRecurringContext } from './index';
import onSubmit from './onSubmit';

type Props = {
  readonly context: DonationRecurringContext;
  readonly handleClose: VoidFunction;
};

// ----------------------------------------------------------------------
export default function DonationRecurringActions({ handleClose, context }: Props) {
  const { fShortDate, fDateToISO } = useFormat();
  const { hasAccess } = useRole();
  const {
    createRecurringDonation,
    updateRecurringDonation,
    deleteRecurringDonation,
    createBatchDonation,
  } = useDonation();
  const { enqueueSnackbar } = useSnackbar();

  const canEdit = hasAccess([Organization.Role.editor, Organization.Role.contributor]);
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isConfirmationCreate, setConfirmationCreate] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const {
    control,
    setError,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
  } = useFormContext<RecurringDonationSchemaForm>();
  const [watchPeriod, watchStartDate] = useWatch({
    control,
    name: ['period', 'startDate'],
  });

  const handleDelete = async () => {
    if (!canEdit) {
      setError('afterSubmit', { message: 'You lack permissions!' });
      return;
    }
    if (!context.org) {
      setError('afterSubmit', { message: '[internal] Missing organization!' });
      return;
    }
    if (!context.recurringDonation) {
      setError('afterSubmit', { message: 'Missing Recurring Donation to delete!' });
      return;
    }

    setDeleting(true);
    try {
      await deleteRecurringDonation({
        orgId: context.org.id,
        recurringDonationId: context.recurringDonation.id,
      });
    } catch (e) {
      setError('afterSubmit', { message: e.message });
    }
    setDeleting(false);
    handleClose();
  };

  const handleConfirmCreation = () => {
    setConfirmationCreate(false);
    handleSubmit(
      onSubmit(
        context,
        createRecurringDonation,
        updateRecurringDonation,
        createBatchDonation,
        handleClose,
        setError,
        enqueueSnackbar,
        fDateToISO
      )
    )();
  };

  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent={context.isEdit ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        <ConfirmDialog
          open={isConfirmingDelete}
          onClose={() => setConfirmingDelete(false)}
          onConfirm={handleDelete}
          loading={isDeleting}
        />
        {context.isEdit && (
          <LoadingButton
            color="error"
            onClick={() => setConfirmingDelete(true)}
            loading={isDeleting}
          >
            Delete Recurring Donation
          </LoadingButton>
        )}

        <LoadingButton
          size="large"
          variant="contained"
          loading={isSubmitting}
          disabled={context.isExpired}
          onClick={() => {
            if (isDirty && !Object.keys(errors).length) {
              setConfirmationCreate(true);
            } else {
              handleConfirmCreation();
            }
          }}
        >
          {context.isEdit ? 'Save' : 'Create'}
        </LoadingButton>
      </Stack>

      <ConfirmDialog
        open={isConfirmationCreate}
        onClose={() => setConfirmationCreate(false)}
        onConfirm={handleConfirmCreation}
        title="Are you sure?"
        textConfirmButton="Continue"
        textCancelButton="No, go back"
        sizeButton="small"
        bgColorConfirm="primary"
      >
        {context.isEdit ? (
          <Typography>{`Your changes will take effect with the next occurrence on ${fShortDate(context.nextOccurrence)}`}</Typography>
        ) : (
          <Typography>
            {context.willNeverRepeat
              ? `Creating this recurring donation immediately adds ${context.immediateDonations.length} donations, but doesn’t schedule any donations in the future.`
              : context.immediateDonations.length
                ? `Creating this recurring donation will immediately add ${context.immediateDonations.length} donations and continue adding them ${watchPeriod?.toLowerCase()} as specified.`
                : `Creating this recurring donation will add donations ${watchPeriod?.toLowerCase()} starting on ${fShortDate(watchStartDate)}.`}
          </Typography>
        )}
      </ConfirmDialog>
    </>
  );
}
