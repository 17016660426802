import { useEffect, useState } from 'react';
import { Container, Button, Tab, Box } from '@mui/material';

import useView from '@hooks/useView';

import { View } from '@shared/types';
import useQueryParams from '@hooks/useQueryParams';
import Page from '@components/Page';
import BreadcrumbsHeader from '@components/BreadcrumbsHeader';

import Iconify from '@components/Iconify';
import PATHS, { getPath } from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useLocalState from '@hooks/useLocalState';
import RecurringDonationList from './tab/RecurringDonations';
import DonationList from './tab/AllDonations';
import Pledges from './tab/Pledges';
import BankDeposits from './tab/BankDeposits';
import useDonation from '@hooks/useDonation';

type ContextTab = 'all' | 'bank-deposits' | 'recurring-donations' | 'pledges';
type SettingsTab = 'categories' | 'paymentMethods' | 'pledges' | 'bankDeposits';

const TAB_TO_SETTINGS_MAP: Record<ContextTab, SettingsTab> = {
  all: 'categories',
  'recurring-donations': 'categories',
  'bank-deposits': 'bankDeposits',
  pledges: 'pledges',
} as const;

type Props = {
  context?: ContextTab;
};

// ----------------------------------------------------------------------
export default function DonationsView({ context }: Props) {
  const {
    queryParams: { viewId },
    setQueryParams,
  } = useQueryParams();
  const { getViewById } = useView();
  const navigate = useNavigate();
  const { org } = useOrg();
  const { hasBankDeposits } = useDonation();
  const [activeTab, setActiveTab] = useLocalState<ContextTab>(
    'donations-active-tab',
    context || 'all'
  );

  // ------- STATE -------
  const [cachedView, setCachedView] = useState<View.View>();

  // ------- EFFECTS -------
  useEffect(() => {
    if (viewId) {
      const view = getViewById(viewId);
      if (view) {
        setCachedView(view);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  const clearCache = () => {
    setCachedView(undefined);
    setQueryParams({});
  };

  const handleSetting = () => {
    const settingsTab = TAB_TO_SETTINGS_MAP[activeTab];
    navigate(getPath(PATHS.org.donations.settings[settingsTab], { orgId: org!.id }));
  };

  const handleChange = (event: React.SyntheticEvent, tab: ContextTab) => {
    setActiveTab(tab);

    switch (tab) {
      case 'all':
        navigate(getPath(PATHS.org.donations.all.root));
        break;
      case 'bank-deposits':
        navigate(getPath(PATHS.org.donations.bankDeposits.root));
        break;
      case 'recurring-donations':
        navigate(getPath(PATHS.org.donations.recurringDonations.root));
        break;
      case 'pledges':
        navigate(getPath(PATHS.org.donations.pledges.root));
        break;
      default:
        console.warn(`Invalid tab ${tab}`);
    }
  };

  // ------- UI -------
  return (
    <Page title="Donations">
      <Container maxWidth={'xl'}>
        <BreadcrumbsHeader heading="Donations">
          <Button onClick={handleSetting}>
            <Iconify color="#818991" icon="mdi:gear" style={{ fontSize: 26 }} />
          </Button>
        </BreadcrumbsHeader>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="All Donations" value="all" />
                {hasBankDeposits && <Tab label="Bank Deposits" value="bank-deposits" />}
                <Tab label="Recurring Donations" value="recurring-donations" />
                {org?.pledgesEnabled && <Tab label="Pledges" value="pledges" />}
              </TabList>
            </Box>
            <TabPanel value="all">
              <DonationList cachedView={cachedView} clearCache={clearCache} />
            </TabPanel>
            {hasBankDeposits && (
              <TabPanel value="bank-deposits">
                <BankDeposits />
              </TabPanel>
            )}
            <TabPanel value="recurring-donations">
              <RecurringDonationList cachedView={cachedView} clearCache={clearCache} />
            </TabPanel>
            {org?.pledgesEnabled && (
              <TabPanel value="pledges">
                <Pledges />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
