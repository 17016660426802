import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Alert, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { isUndefined } from 'lodash';

import { Role } from '@shared/types/organization';
import useRole from '@hooks/useRole';
// import { paymentMethodSchemaResolver, PaymentMethodSchemaForm } from '@/schemas';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import * as analytics from '@fire/analytics';

import { RHFCheckbox, RHFTextField } from '@components/hook-form';
import ConfirmDialog from '@components/ConfirmDialog';
import Dialog from '@components/Dialog';
import { PaymentMethodSchemaForm, paymentMethodSchemaResolver } from '@/schemas';
import { Donation } from '@shared/types';
import useLocales from '@hooks/useLocales';

type Props = {
  readonly paymentMethodId?: string;
  readonly open?: boolean;
  readonly onClose?: () => void;
};

// ----------------------------------------------------------------------
export default function PaymentMethodDialog({ open, paymentMethodId, onClose }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const {
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    getPaymentMethodById,
    isPaymentMethodInUse,
    paymentMethods,
    hasBankDeposits,
  } = useDonation();
  const { t } = useLocales();
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  // --------------- routing ---------------
  const handleClose = () => {
    if (isUndefined(open)) {
      navigate(-1);
    } else {
      onClose?.();
    }
  };

  // --------------- effects ---------------

  const pmId = params.paymentMethodId || paymentMethodId;
  const paymentMethod = useMemo(() => getPaymentMethodById(pmId), [getPaymentMethodById, pmId]);

  const inUse = useMemo(
    () => !!paymentMethod && isPaymentMethodInUse(paymentMethod.id),
    [isPaymentMethodInUse, paymentMethod]
  );

  // can't edit or delete the special values 'cash', 'check' or 'giftInKind' that have
  // special associated functionality
  const isRestricted = useMemo(
    () =>
      (
        [
          Donation.PaymentMethodInitialList.cash,
          Donation.PaymentMethodInitialList.check,
          Donation.PaymentMethodInitialList.giftInKind,
        ] as string[]
      ).includes(paymentMethod?.type || ''),
    [paymentMethod]
  );

  const canEdit = useMemo(
    () => hasAccess([Role.editor, Role.contributor]) && !isRestricted,
    [hasAccess, isRestricted]
  );

  const canDelete = useMemo(
    () =>
      canEdit &&
      !!paymentMethod &&
      !isRestricted &&
      !inUse &&
      paymentMethod.id !== org?.defaultPaymentMethodId,
    [canEdit, paymentMethod, isRestricted, inUse, org?.defaultPaymentMethodId]
  );

  useEffect(() => paymentMethod && analytics.donation.paymentMethodView(), [paymentMethod]);

  const defaultValues: PaymentMethodSchemaForm = useMemo(
    () => ({
      name: paymentMethod?.name || '',
      bankDeposit: paymentMethod?.bankDeposit || false,
    }),
    [paymentMethod]
  );

  // --------------- form ---------------
  const methods = useForm<PaymentMethodSchemaForm>({
    resolver: paymentMethodSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const isLoading = isSubmitting || isDeleting;
  const watchedName = watch('name');
  const watchedBankDeposit = watch('bankDeposit');

  const isDuplicate = useMemo(
    () =>
      paymentMethods.some(
        (c) => c.id !== paymentMethod?.id && c.name.toUpperCase() === watchedName.toUpperCase()
      ),
    [paymentMethods, paymentMethod?.id, watchedName]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const handleError = (msg: string) => {
    enqueueSnackbar(msg, { variant: 'error' });
    setConfirmingDelete(false);
  };
  const handleDelete = async () => {
    if (!canEdit) {
      handleError('You lack permissions!');
      return;
    }
    if (!org) {
      handleError('[internal] Missing organization!');
      return;
    }
    if (!paymentMethod) {
      handleError('Missing paymentMethod to delete!');
      return;
    }
    if (isPaymentMethodInUse(paymentMethod.id)) {
      handleError("Can't delete a paymentMethod in use!");
      return;
    }

    setConfirmingDelete(false);
    setDeleting(true);
    try {
      await deletePaymentMethod({ orgId: org.id, paymentMethodId: paymentMethod.id });
      handleClose();
    } catch (e) {
      handleError(e.message);
    }
    setDeleting(false);
  };
  const handleSave = async (data: PaymentMethodSchemaForm) => {
    if (!canEdit) return;

    delete data.afterSubmit;
    data.bankDeposit = watchedBankDeposit; // shouldn't need this but otherwise it's always undefined!

    try {
      if (!paymentMethod) {
        await createPaymentMethod({ orgId: org!.id, ...data, type: 'custom' });

        enqueueSnackbar('PaymentMethod created');
        handleClose();
      } else {
        await updatePaymentMethod({
          orgId: org!.id,
          paymentMethodId: paymentMethod.id,
          update: { orgId: org!.id, ...data, type: 'custom' },
        });

        enqueueSnackbar('PaymentMethod updated');
        handleClose();
      }
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      open={isUndefined(open) ? true : open}
      title={!paymentMethod ? 'Create Payment Method' : 'Payment Method'}
      maxWidth="sm"
      isDirty={isDirty}
      isLoading={isLoading}
      methods={methods}
      onClose={() => handleClose()}
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          {canDelete ? (
            <LoadingButton color="error" onClick={() => setConfirmingDelete(true)}>
              Delete
            </LoadingButton>
          ) : (
            <div />
          )}
          {canEdit && (
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
              disabled={
                isDuplicate ||
                (!isDirty &&
                  watchedName === defaultValues.name &&
                  watchedBankDeposit === defaultValues.bankDeposit)
              }
              onClick={handleSubmit(handleSave)}
            >
              {!paymentMethod ? 'Create' : 'Save'}
            </LoadingButton>
          )}
        </Stack>
      }
    >
      <ConfirmDialog
        open={isConfirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        onConfirm={handleDelete}
      />

      <Grid container spacing={2}>
        <Grid item xs={7}>
          <RHFTextField
            autoFocus
            name="name"
            label="Name"
            size="small"
            disabled={isLoading || !canEdit}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        {hasBankDeposits && (
          <Grid item xs={12}>
            <RHFCheckbox
              name="bankDeposit"
              disabled={isLoading || !canEdit}
              label={`Include in bank deposits for cash and ${t('Check').toLowerCase()}s`}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28, width: 28, height: 28 },
                '& .MuiFormControlLabel-label': { fontSize: 18 },
              }}
            />
          </Grid>
        )}
      </Grid>
      {inUse && watchedName !== defaultValues.name && (
        <Alert severity="info" variant="standard" sx={{ marginTop: 1, backgroundColor: 'white' }}>
          Clicking Save will update this Name immediately in all Donations using it.
        </Alert>
      )}
      {isRestricted && (
        <Alert severity="info" variant="standard" sx={{ marginTop: 1, backgroundColor: 'white' }}>
          You cannot edit this payment method because it has a special meaning in the app.
        </Alert>
      )}
      {isDuplicate && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          You cannot save this because it has the same name as another Payment Method.
        </Alert>
      )}
      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
    </Dialog>
  );
}
