import { FormControl, MenuItem, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { addYears, subYears } from 'date-fns';

import useFormat from '@hooks/useFormat';
import { RecurringDonation as RN } from '@shared/types';
import { RecurringDonationSchemaForm } from '@/schemas';

import { RHFDatePicker, RHFRadioGroup, RHFSelect, RHFTextField } from '@components/hook-form';

import { DonationRecurringContext } from './index';
import { PeriodLabel } from '@utils/recurringDonation';

// ----------------------------------------------------------------------
type Props = {
  readonly context: DonationRecurringContext;
};
// ----------------------------------------------------------------------
export default function RecurringForm({ context }: Props) {
  const { fDateToYearStart } = useFormat();

  const periods = Object.values(RN.RecurringPeriod);

  const {
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<RecurringDonationSchemaForm>();
  const watchedEndingOption = watch('endingOption');

  // --------------- data ---------------

  // --------------- actions ---------------

  return (
    <Stack width="100%" direction="column" spacing={2}>
      <RHFDatePicker
        required
        name="startDate"
        label="Start Date"
        disabled={context.isEdit}
        minDate={subYears(fDateToYearStart(new Date()), 3)}
        maxDate={addYears(new Date(), 1)}
      />

      <Stack direction="row" alignItems="center" justifyContent="center">
        <FormControl
          variant="outlined"
          error={!!errors.period}
          sx={{ width: '100%', height: '72px' }}
        >
          <RHFSelect fullWidth name="period" label="Repeat" disabled={context.isEdit}>
            {periods.map((p) => (
              <MenuItem key={p} value={p}>
                {PeriodLabel[p]}
              </MenuItem>
            ))}
          </RHFSelect>
        </FormControl>
      </Stack>

      <Stack paddingLeft={1} sx={{ width: '100%', margin: '0 !important' }}>
        <RHFRadioGroup
          name="endingOption"
          label="Ends"
          sx={{ width: '100%', marginRight: '-16px' }}
          onChange={(event) => {
            const selectedOption = event.target.value as RN.RecurringEnding;
            setValue('endingOption', selectedOption);
            clearErrors('endDate');
            clearErrors('occurrenceLimit');
          }}
          options={[
            {
              value: RN.RecurringEnding.never,
              disabled: context.isEdit,
              label: (
                <Typography variant="body2" sx={{ width: 100 }}>
                  Never
                </Typography>
              ),
            },
            {
              value: RN.RecurringEnding.on,
              disabled: context.isEdit,
              label: (
                <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ width: 100 }}>
                    On
                  </Typography>

                  <RHFDatePicker
                    required
                    fullWidth
                    disabled={context.isEdit}
                    sx={{
                      flexGrow: 1,
                      visibility:
                        watchedEndingOption !== RN.RecurringEnding.on ? 'hidden' : 'initial',
                    }}
                    name="endDate"
                    label="Ending Date"
                    minDate={subYears(new Date(), 10)}
                    maxDate={addYears(new Date(), 1)}
                  />
                </Stack>
              ),
            },
            {
              value: RN.RecurringEnding.after,
              disabled: context.isEdit,
              label: (
                <Stack alignItems="center" flexDirection="row">
                  <Typography variant="body2" sx={{ width: 100 }}>
                    After
                  </Typography>

                  <RHFTextField
                    fullWidth
                    disabled={context.isEdit}
                    sx={{
                      flexGrow: 1,
                      visibility:
                        watchedEndingOption !== RN.RecurringEnding.after ? 'hidden' : 'initial',
                    }}
                    type="number"
                    name="occurrenceLimit"
                    label="Occurrences"
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}
