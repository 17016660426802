import Dialog from '@components/Dialog';
import useFormat from '@hooks/useFormat';
import { Button, Typography } from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  onContinue: () => void;
  existingDeposit: {
    id: string;
    date: string;
    bankAccount: string;
    accountNumber?: string;
    description?: string;
  };
};

export default function DuplicateConfirmDialog({
  open,
  onClose,
  onEdit,
  onContinue,
  existingDeposit,
}: Props) {
  const { fDate } = useFormat();

  return (
    <Dialog
      title="Duplicate Bank Deposit Found"
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      actions={
        <>
          <Button color="error" variant="contained" onClick={onClose}>
            Cancel and Change Summary Info
          </Button>
          <Button onClick={onEdit} variant="outlined">
            Edit Existing
          </Button>
          <Button onClick={onContinue} variant="contained">
            Create New
          </Button>
        </>
      }
    >
      <Typography sx={{ mb: 1 }}>A bank deposit already exists with these details:</Typography>
      <Typography>Date: {fDate(existingDeposit.date)}</Typography>
      <Typography>Bank Account: {existingDeposit.bankAccount}</Typography>
      {existingDeposit.accountNumber && (
        <Typography>Account Number: {existingDeposit.accountNumber}</Typography>
      )}
      {existingDeposit.description && (
        <Typography>Description: {existingDeposit.description}</Typography>
      )}
      <Typography sx={{ mb: 2, mt: 2 }}>
        Note: You can create multiple deposits for the same date and account by using different
        Description values.
      </Typography>
      <Typography>
        Would you like to edit the existing deposit or continue creating a new one?
      </Typography>
    </Dialog>
  );
}
