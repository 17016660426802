import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Alert, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { random, isUndefined } from 'lodash';

import { Category } from '@shared/types';
import { Role } from '@shared/types/organization';
import useRole from '@hooks/useRole';
import { categorySchemaResolver, CategorySchemaForm } from '@/schemas';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import * as analytics from '@fire/analytics';

import { RHFTextField } from '@components/hook-form';
import ConfirmDialog from '@components/ConfirmDialog';
import Dialog from '@components/Dialog';
import ColorPalette from '@pages/donors/dialogs/Settings/DonorTags/Dialog/ColorPalette';
import colors from '@utils/colors';

// ----------------------------------------------------------------------
type Props = {
  readonly catId?: string;
  readonly open?: boolean;
  readonly onClose?: (category?: Category.Category) => void;
};
// ----------------------------------------------------------------------
export default function CategoryDialog({ catId, open, onClose }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const {
    createCategory,
    updateCategory,
    deleteCategory,
    getCategoryById,
    categoryUseCountInDonations,
    categoryUseCountInPledges,
    categories,
    hasBankDeposits,
    isShowingClasses,
  } = useDonation();
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  // --------------- routing ---------------
  const handleClose = (newCategory?: Category.Category) => {
    if (isUndefined(open)) {
      navigate(-1);
    } else {
      onClose?.(newCategory);
    }
  };

  // --------------- effects ---------------
  const canEdit = hasAccess([Role.editor, Role.contributor]);

  const categoryId = params.catId || catId;

  const category = useMemo(() => getCategoryById(categoryId), [getCategoryById, categoryId]);

  const [donationsUsing, pledgesUsing] = useMemo(
    () => [categoryUseCountInDonations(category?.id), categoryUseCountInPledges(category?.id)],
    [category?.id, categoryUseCountInDonations, categoryUseCountInPledges]
  );

  const [canDelete, showCantDelete, cantDeleteReason] = useMemo(() => {
    if (!canEdit || !category) return [false, false, ''];
    if (categories.length === 1)
      return [false, true, "Cannot delete this category because it's the only category"];
    if (donationsUsing > 0)
      return [
        false,
        true,
        `Cannot delete this category because it's used by ${donationsUsing} donation${donationsUsing > 1 ? 's' : ''}`,
      ];
    if (org?.defaultCategoryId === category.id)
      return [false, true, "Cannot delete this category because it's the default category"];
    if (pledgesUsing > 0)
      return [
        false,
        true,
        `Cannot delete this category because it's used by ${pledgesUsing} pledge${pledgesUsing > 1 ? 's' : ''}`,
      ];
    if (org?.defaultPledgeCategoryId === category.id)
      return [false, true, "Cannot delete this category because it's the default pledge category"];
    return [true, false, ''];
  }, [
    canEdit,
    categories.length,
    category,
    donationsUsing,
    org?.defaultCategoryId,
    org?.defaultPledgeCategoryId,
    pledgesUsing,
  ]);

  useEffect(() => category && analytics.donation.categoryView(), [category]);

  const defaultValues: CategorySchemaForm = useMemo(() => {
    const randomColor = random(colors.length - 1);
    return {
      name: category?.name || '',
      description: category?.description || '',
      colorText: category?.colorText || colors[randomColor].colorText,
      colorBackground: category?.colorBackground || colors[randomColor].colorBackground,
      accountingAccount: category?.accountingAccount || category?.name || '',
      accountingClass: category?.accountingClass || '',
    };
  }, [
    category?.accountingAccount,
    category?.accountingClass,
    category?.colorBackground,
    category?.colorText,
    category?.description,
    category?.name,
  ]);

  // --------------- form ---------------
  const methods = useForm<CategorySchemaForm>({
    resolver: categorySchemaResolver,
    defaultValues,
  });

  const {
    reset,
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;
  const isLoading = isSubmitting || isDeleting;
  const watchedName = watch('name');
  const watchedColorText = watch('colorText');
  const watchedColorBackground = watch('colorBackground');

  const isDuplicate = useMemo(
    () =>
      categories.some(
        (c) => c.id !== category?.id && c.name.toUpperCase() === watchedName.toUpperCase()
      ),
    [categories, category?.id, watchedName]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [open, reset, defaultValues]);

  // --------------- actions ---------------
  const handleError = (msg: string) => {
    enqueueSnackbar(msg, { variant: 'error' });
    setConfirmingDelete(false);
  };
  const handleDelete = async () => {
    if (!canEdit) {
      handleError('You lack permissions!');
      return;
    }
    if (!org) {
      handleError('[internal] Missing organization!');
      return;
    }
    if (!category) {
      handleError('Missing category to delete!');
      return;
    }
    if (!canDelete) {
      handleError("Can't delete this category!");
      return;
    }

    setConfirmingDelete(false);
    setDeleting(true);
    try {
      await deleteCategory({ orgId: org.id, categoryId: category.id });
      handleClose();
    } catch (e) {
      handleError(e.message);
    }
    setDeleting(false);
  };
  const handleSave = async (data: CategorySchemaForm) => {
    if (!canEdit) return;

    delete data.afterSubmit;
    try {
      if (!category) {
        const newCategory = await createCategory({ orgId: org!.id, ...data });
        reset(defaultValues);

        enqueueSnackbar('Category created');
        handleClose(newCategory);
      } else {
        await updateCategory({
          orgId: org!.id,
          categoryId: category.id,
          update: { orgId: org!.id, ...data },
        });

        enqueueSnackbar('Category updated');
        handleClose();
      }
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      open={isUndefined(open) ? true : open}
      onClose={() => handleClose()}
      title={!category ? 'Create category' : 'Category'}
      maxWidth="sm"
      isDirty={isDirty}
      isLoading={isLoading}
      methods={methods}
      actions={
        <Stack direction="row" justifyContent={canDelete || showCantDelete ? 'space-between' : 'flex-end'} width="100%">
          {canDelete && (
            <LoadingButton color="error" onClick={() => setConfirmingDelete(true)}>
              Delete
            </LoadingButton>
          )}
          {showCantDelete && (
            <Tooltip title={cantDeleteReason} placement="top" enterDelay={600}>
              <Typography color={'error'} sx={{ pt: 2 }}>
                Cannot Delete
              </Typography>
            </Tooltip>
          )}
          {canEdit && (
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
              disabled={isDuplicate || (!isDirty && watchedName === defaultValues.name)}
              onClick={handleSubmit(handleSave)}
            >
              {!category ? 'Create' : 'Save'}
            </LoadingButton>
          )}
        </Stack>
      }
    >
      <ConfirmDialog
        open={isConfirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        onConfirm={handleDelete}
      />

      <Grid container spacing={3}>
        <Grid item xs={7}>
          <RHFTextField
            autoFocus
            name="name"
            label="Name"
            size="small"
            disabled={isLoading || !canEdit}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => setValue('name', e.target.value, { shouldDirty: true })}
          />
        </Grid>

        <Grid item xs={3}>
          <ColorPalette
            colorText={watchedColorText}
            colorBackground={watchedColorBackground}
            onSelect={(cT, cB) => {
              setValue('colorText', cT, { shouldDirty: true });
              setValue('colorBackground', cB, { shouldDirty: true });
            }}
            disabled={isLoading || !canEdit}
          />
        </Grid>

        <Grid item xs={12}>
          <RHFTextField
            name="description"
            size="small"
            label="Description"
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
            disabled={isLoading || !canEdit}
          />
        </Grid>
        {hasBankDeposits && (
          <Grid item xs={12}>
            <RHFTextField
              name="accountingAccount"
              label="Accounting Account"
              size="small"
              disabled={isLoading || !canEdit}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => setValue('accountingAccount', e.target.value, { shouldDirty: true })}
            />
          </Grid>
        )}
        {isShowingClasses && (
          <Grid item xs={12}>
            <RHFTextField
              name="accountingClass"
              label="Accounting Class"
              size="small"
              disabled={isLoading || !canEdit}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => setValue('accountingClass', e.target.value, { shouldDirty: true })}
            />
          </Grid>
        )}
      </Grid>
      {donationsUsing + pledgesUsing > 0 && watchedName !== defaultValues.name && (
        <Alert severity="info" variant="standard" sx={{ marginTop: 1, backgroundColor: 'white' }}>
          {`Clicking Save will update this Name immediately in all ${donationsUsing > 0 ? 'Donations' : ''}${donationsUsing > 0 && pledgesUsing > 0 ? ' and ' : ''}${pledgesUsing > 0 ? 'Pledges' : ''} using it.`}
        </Alert>
      )}
      {isDuplicate && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          You cannot save this because it has the same name as another Category.
        </Alert>
      )}
      {!!errors.afterSubmit && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          {errors.afterSubmit.message}
        </Alert>
      )}
    </Dialog>
  );
}
