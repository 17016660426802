import { useMemo, useState } from 'react';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { filter, isArray, sortBy } from 'lodash';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { Category, Donation, Donor } from '@shared/types';
import { RecurringDonationSchemaForm } from '@/schemas';

import PaywallDonorGuard from '@/guards/PaywallDonorGuard';
import DonorDialog from '@pages/donors/dialogs/Donor';
import CategoryDialog from '@pages/donations/dialogs/Settings/DonationCategories/Dialog';
import { RHFAutocomplete, RHFCheckbox, RHFTextField } from '@components/hook-form';
import { renderCategoryOption } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import { renderPaymentMethodOption } from '@pages/donations/dialogs/Settings/PaymentMethods/utils/renderPaymentMethods';

import Iconify from '@components/Iconify';
import { DonationRecurringContext } from './index';

// ----------------------------------------------------------------------
type Props = {
  readonly context: DonationRecurringContext;
};
// ----------------------------------------------------------------------
export default function DonationForm({ context }: Props) {
  const { fReversedName } = useFormat();
  const { categories, donors, paymentMethods } = useDonation();

  const currentYear = new Date().getFullYear();
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  const [createDonorOpen, setCreateDonorOpen] = useState(false);

  const { setValue, clearErrors } = useFormContext<RecurringDonationSchemaForm>();

  // --------------- data ---------------
  const donorList = useMemo(
    () => [
      ...sortBy(
        donors.map((d) => ({ ...d, name: fReversedName(d, currentYear) })),
        'name'
      ),
      { id: 'create-new', name: '', memberNumbers: {} } as any,
    ],
    [fReversedName, donors, currentYear]
  );

  const categoryList = useMemo(
    () => [
      ...sortBy(categories, (c) => c.name.toUpperCase()),
      { id: 'create-new', name: '', description: '' } as Category.Category,
    ],
    [categories]
  );

  const paymentMethodsNoGIK = useMemo(
    () =>
      sortBy(
        filter(paymentMethods, (p) => p.type !== Donation.PaymentMethodInitialList.giftInKind),
        (p) => p.name.toUpperCase()
      ),
    [paymentMethods]
  );

  // --------------- actions ---------------
  const handleDonorCreate = (newDonor?: Donor.Donor) => {
    if (newDonor) {
      setValue('donor', newDonor);
      clearErrors('donor');
    }
    setCreateDonorOpen(false);
  };

  const handleCategoryCreate = (newCategory?: Category.Category) => {
    if (newCategory) {
      setTimeout(() => {
        setValue('category', newCategory);
        clearErrors('category');
      }, 250);
    }
    setCreateCategoryOpen(false);
  };

  return (
    <Stack width="100%" direction="column" spacing={2}>
      {createDonorOpen && (
        <PaywallDonorGuard onLimitClose={handleDonorCreate}>
          <DonorDialog context="donations" onClose={handleDonorCreate} />
        </PaywallDonorGuard>
      )}

      <RHFAutocomplete
        autoFocus
        name={`donor`}
        label="Donor"
        required
        fullWidth
        freeSolo={false}
        disabled={context.isEdit}
        options={donorList}
        getOptionLabel={(donor) => donor.name || fReversedName(donor, currentYear)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) =>
          option.id === 'create-new' ? (
            <li key="create-new" {...props}>
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ fontWeight: 700, color: (theme) => theme.palette.primary.dark }}
              >
                <Iconify icon="icons8:plus" width={20} height={20} />
                <Typography variant="body2">Create New Donor</Typography>
              </Stack>
            </li>
          ) : (
            <li {...props} key={option.id}>
              {option.name || fReversedName(option, currentYear)}
            </li>
          )
        }
        beforeOnChange={(newValue, commitChange) => {
          if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
            setCreateDonorOpen(true);
          } else {
            commitChange();
          }
        }}
      />

      <CategoryDialog open={createCategoryOpen} onClose={handleCategoryCreate} />
      <RHFAutocomplete
        name={`category`}
        label="Category"
        required
        fullWidth
        freeSolo={false}
        disabled={context.isExpired}
        options={categoryList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={renderCategoryOption}
        getOptionLabel={(option) => option.name}
        beforeOnChange={(newValue, commitChange) => {
          if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
            setCreateCategoryOpen(true);
          } else {
            commitChange();
          }
        }}
      />

      <RHFTextField
        required
        name="amount"
        type="number"
        label="Amount"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        disabled={context.isExpired}
        inputProps={{ maxLength: 6 }}
      />

      <RHFAutocomplete
        name="paymentMethod"
        label="Payment Method"
        fullWidth
        freeSolo={false}
        disabled={context.isExpired}
        options={paymentMethodsNoGIK}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={renderPaymentMethodOption}
      />

      <RHFCheckbox
        name="nonReceiptable"
        label="Non-receiptable"
        disabled={context.isExpired}
        sx={{ minWidth: 140, height: 56 }}
      />
    </Stack>
  );
}
