import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

import useDonation from '@hooks/useDonation';
import Iconify from '@components/Iconify';
import FeedbackDialog from '@/layouts/AppLayout/Navbar/Feedback/FeedbackDialog';
import { Limits } from '@shared/limits';
import DefaultCategory from './DefaultCategory';
import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import Scrollbar from '@components/Scrollbar';
import { TColumn } from '@typedefs/app';
import useTable from '@hooks/useTable';
import { TableHeadCustom } from '@components/table';

// ----------------------------------------------------------------------
export default function DonationCategories() {
  const { categories, hasBankDeposits, isShowingClasses } = useDonation();
  const [feedbackOpen, feedbackSetOpen] = useState(false);
  const navigate = useNavigate();
  const { org } = useOrg();
  // --------------- effects ---------------
  const sortedCategories = useMemo(
    () => sortBy(categories, (c) => c.name.toUpperCase()),
    [categories]
  );

  const TABLE_HEAD: TColumn[] = [
    { id: 'name', type: 'string', label: 'Name', align: 'left', visible: true },
    { id: 'description', type: 'string', label: 'Description', align: 'left', visible: true },
    {
      id: 'accountingAccount',
      type: 'string',
      label: 'Accounting Account',
      align: 'left',
      visible: hasBankDeposits,
    },
    {
      id: 'accountingClass',
      type: 'string',
      label: 'Accounting Class',
      align: 'left',
      visible: hasBankDeposits && isShowingClasses,
    },
  ];
  const { columns } = useTable({
    defaultRowsPerPage: 100,
    defaultColumns: TABLE_HEAD,
  });

  // --------------- actions ---------------

  const handleCreateCategory = () => {
    navigate(getPath(PATHS.org.donations.settings.categoriesCreate, { orgId: org!.id }));
  };

  const handleEdit = (catId: string) => {
    navigate(getPath(PATHS.org.donations.settings.categoriesEdit, { orgId: org!.id, catId }));
  };

  return (
    <Stack marginTop={3}>
      <DefaultCategory />
      <Divider sx={{ my: 1, borderStyle: 'solid' }} />
      <Stack>
        <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
          <Scrollbar>
            <Table stickyHeader size="small">
              <TableHeadCustom
                // order={order}
                // orderBy={orderBy}
                columns={columns}
                rowCount={sortedCategories.length}
                // onSort={onSort}
              />

              <TableBody>
                {sortedCategories.map((cat) => (
                  <TableRow
                    key={cat.id}
                    hover
                    sx={{ cursor: 'pointer', height: 44 }}
                    onClick={() => handleEdit(cat.id)}
                  >
                    <TableCell align="left">{cat.name}</TableCell>
                    <TableCell align="left">{cat.description}</TableCell>
                    {hasBankDeposits && (
                      <TableCell align="left">{cat.accountingAccount}</TableCell>
                    )}
                    {hasBankDeposits && isShowingClasses && (
                      <TableCell align="left">{cat.accountingClass}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
        <Stack alignItems="flex-end">
          {categories.length < Limits.categories ? (
            <Button
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleCreateCategory()}
            >
              New Category
            </Button>
          ) : (
            <Typography>
              You've reached the limit of {Limits.categories} Categories. Please{' '}
              <Link sx={{ cursor: 'pointer' }} onClick={() => feedbackSetOpen(true)}>
                contact us
              </Link>{' '}
              if this is a problem for you.
            </Typography>
          )}
        </Stack>
      </Stack>

      <FeedbackDialog open={feedbackOpen} setOpen={feedbackSetOpen} />
    </Stack>
  );
}
