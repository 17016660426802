import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Donation, Organization } from '@shared/types';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const BankDepositSettingsSchema = Yup.object().shape({
  type: Yup.string(),
  useClasses: Yup.bool(),
  dayFirst: Yup.bool(),
});

export const bankDepositSettingsSchemaResolver = yupResolver(BankDepositSettingsSchema);

export type BankDepositSettingsSchemaForm = {
  type: Organization.BankDepositAccountingProgram;
  useClasses: boolean;
  dayFirst: boolean;

  afterSubmit?: string;
};

export type SummarySchemaForm = {
  date: Date;
  bankAccount: string;
  accountNumber?: string;
  description?: string;
  advantageAccount?: string;
  advantageClass?: string;
};

const SummarySchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Bank deposit date is required')
    .required('Bank deposit date is required'),
  bankAccount: Yup.string().required('Bank account is required'),
  accountNumber: Yup.string(),
  description: Yup.string(),
  advantageAccount: Yup.string(),
  advantageClass: Yup.string(),
});

export const SummarySchemaResolver = yupResolver(SummarySchema);

export type BankDepositSchemaForm = {
  step: number;
  date: Date;
  startDate: Date | null;
  bankAccount: string;
  accountNumber?: string;
  description?: string;
  advantageAccount?: string;
  advantageClass?: string;
  donations: Donation.Donation[];
  selectedDonationIds: string[];
};

export const BankDepositSchema = Yup.object().shape({
  date: Yup.date().required('Deposit Date is required'),
  startDate: Yup.date().required('Start Date is required'),
  bankAccount: Yup.string().required('Bank Account is required'),
  accountNumber: Yup.string(),
  description: Yup.string(),
  advantageAccount: Yup.string(),
  advantageClass: Yup.string(),
  donations: Yup.array(),
  selectedDonationIds: Yup.array(),
});
