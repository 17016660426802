import { UseFormSetError } from 'react-hook-form';
import { EnqueueSnackbar } from 'notistack';
import { isNumber } from 'lodash';

import { RecurringDonation as RD, Donation } from '@shared/types';
import { RecurringDonationSchemaForm } from '@/schemas/recurringDonation';
import { DonationRecurringContext } from './index';

export default function onSubmit(
  context: DonationRecurringContext,
  createRecurringDonation: (p: RD.CreateReq) => Promise<RD.RecurringDonation>,
  updateRecurringDonation: (p: RD.UpdateReq) => Promise<RD.RecurringDonation>,
  createBatchDonation: (p: Donation.CreateBatchReq) => Promise<void>,
  handleClose: VoidFunction,
  setError: UseFormSetError<RecurringDonationSchemaForm>,
  enqueueSnackbar: EnqueueSnackbar,
  fDateToISO: (date?: Date | number) => string
) {
  const { org, recurringDonation } = context;

  return async (dataForm: RecurringDonationSchemaForm) => {
    if (!org) {
      setError('afterSubmit', { message: 'Missing organization' });
      return;
    }

    if (!isNumber(dataForm.amount)) {
      setError('afterSubmit', { message: 'Missing amount' });
      return;
    }
    if (!dataForm.donor) {
      setError('afterSubmit', { message: 'Missing donor' });
      return;
    }
    if (!dataForm.category) {
      setError('afterSubmit', { message: 'Missing category' });
      return;
    }

    try {
      if (recurringDonation) {
        await updateRecurringDonation({
          recurringDonationId: recurringDonation.id,
          update: {
            orgId: org.id,
            amount: dataForm.amount,
            categoryId: dataForm.category.id,
            paymentMethodId: dataForm.paymentMethod ? dataForm.paymentMethod.id : null,
            nonReceiptable: dataForm.nonReceiptable,
          },
        });
        enqueueSnackbar('Recurring Donation updated');
      } else {
        let periodInfo: Partial<RD.RecurringDonation> = {};
        if (dataForm.endingOption === RD.RecurringEnding.on) {
          periodInfo = {
            endDate: fDateToISO(dataForm.endDate),
          };
        } else if (dataForm.endingOption === RD.RecurringEnding.after) {
          if (!isNumber(dataForm.occurrenceLimit)) {
            setError('afterSubmit', { message: 'Missing occurrence limit' });
            return;
          }
          periodInfo = {
            occurrenceLimit: dataForm.occurrenceLimit,
          };
        }

        const recurringDonation = await createRecurringDonation({
          orgId: org.id,
          donorId: dataForm.donor.id,
          amount: dataForm.amount,
          categoryId: dataForm.category.id,
          nonReceiptable: dataForm.nonReceiptable,
          ...(dataForm.paymentMethod && { paymentMethodId: dataForm.paymentMethod.id }),

          startDate: fDateToISO(dataForm.startDate),
          period: dataForm.period,
          endingOption: dataForm.endingOption,
          ...periodInfo,

          state: context.willNeverRepeat ? RD.RecurringState.expired : RD.RecurringState.active,
          occurrenceCount: context.immediateDonations.length,
          nextOccurrence: fDateToISO(context.nextOccurrence),
        });

        if (context.immediateDonations.length) {
          await createBatchDonation({
            orgId: org.id,
            donations: context.immediateDonations.map((date) => ({
              date,
              orgId: org.id,
              amount: Number(dataForm.amount),
              donorId: dataForm.donor!.id,
              categoryId: dataForm.category!.id,
              ...(dataForm.paymentMethod && { paymentMethodId: dataForm.paymentMethod.id }),
              paymentInfo: '',
              nonReceiptable: dataForm.nonReceiptable,
              recurringDonationId: recurringDonation.id,
            })),
          });
        }

        enqueueSnackbar('Recurring Donation Created!');
      }

      handleClose();
    } catch (e) {
      console.error(e);
      setError('afterSubmit', { message: e.message });
      enqueueSnackbar(`Error! ${e.message}`);
    }
  };
}
