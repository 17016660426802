import Iconify from '@components/Iconify';
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import useTable, { emptyRows } from '@hooks/useTable';
import useNavigate from '@hooks/useNavigate';
import { TableNoData, TableEmptyRows, TableHeadCustom } from '@components/table';
import Scrollbar from '@components/Scrollbar';
import { orderBy as lodashOrderBy } from 'lodash';
import { TColumn } from '@typedefs/app';
import { useMemo } from 'react';
import { Outlet } from 'react-router';
import getPage from '@utils/getPage';

const TABLE_HEAD: TColumn[] = [
  { id: 'name', type: 'string', label: 'Name', align: 'left', visible: true },
  { id: 'dateStart', type: 'date', label: 'Start', align: 'left', visible: true },
  { id: 'dateEnd', type: 'date', label: 'End', align: 'left', visible: true },
  { id: 'categoryName', label: 'Category', align: 'left', visible: true },
  { id: 'amount', type: 'number', label: 'Pledge Amount', align: 'right', visible: true },
  { id: 'donationsTotal', type: 'number', label: 'Actual Amount', align: 'right', visible: true },
];

export default function Pledges() {
  const { org } = useOrg();
  const navigate = useNavigate();
  const { getDonorById, pledges, getPledgeCategory, getPledgeWithActual } = useDonation();
  const { fReversedName, fDate, fCurrency } = useFormat();
  const { columns, page, rowsPerPage, onChangePage, onChangeRowsPerPage, order, orderBy, onSort } =
    useTable({
      defaultRowsPerPage: 25,
      defaultColumns: TABLE_HEAD,
      defaultOrderBy: 'dateStart',
      defaultOrder: 'desc',
    });
  const currentYear = new Date().getFullYear();

  const handlePledgeEdit = (pledgeId: string) => {
    navigate(PATHS.org.donations.pledges.edit, {
      pledgeId: pledgeId,
    });
  };

  const pledgesWithActual = useMemo(
    () =>
      pledges.map((p) => {
        const cat = getPledgeCategory(p);
        return {
          ...getPledgeWithActual(p),
          name: fReversedName(getDonorById(p.donorId), currentYear),
          categoryName: cat?.name || '(All Categories)',
          categoryTooltip:
            cat?.description ||
            (p.categoryId === '' && 'Donations in all categories will satisfy this pledge'),
          categoryBackground: cat?.colorBackground || undefined,
        };
      }),
    [currentYear, fReversedName, getDonorById, getPledgeCategory, getPledgeWithActual, pledges]
  );

  const pledgesOrdered = useMemo(() => {
    switch (orderBy) {
      case 'dateStart':
        return lodashOrderBy(pledgesWithActual, (p) => new Date(p.dateStart).getTime(), order);
      case 'dateEnd':
        return lodashOrderBy(pledgesWithActual, (p) => new Date(p.dateEnd).getTime(), order);
      default:
        return lodashOrderBy(pledgesWithActual, orderBy, order);
    }
  }, [pledgesWithActual, orderBy, order]);

  return (
    <Box sx={{ paddingTop: 3 }}>
      <Grid container spacing={2}>
        <Outlet />
        <Grid item xs={12} md={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <div />
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={Link}
              to={getPath(PATHS.org.donations.pledges.create, {
                orgId: org!.id,
              })}
            >
              New Pledge
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
              <Scrollbar>
                <Table stickyHeader size="small">
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    columns={columns}
                    rowCount={pledges.length}
                    onSort={onSort as (id: string) => void}
                  />

                  <TableBody>
                    {getPage(pledgesOrdered, page, rowsPerPage).map((pledge) => (
                      <TableRow
                        key={pledge.id}
                        hover
                        sx={{ cursor: 'pointer', height: 44 }}
                        onClick={() => handlePledgeEdit(pledge.id)}
                      >
                        <TableCell align="left">{pledge.name}</TableCell>
                        <TableCell align="left">{fDate(pledge.dateStart)}</TableCell>
                        <TableCell align="left">{fDate(pledge.dateEnd)}</TableCell>
                        <TableCell align="left">
                          <Tooltip title={pledge.categoryTooltip} placement="top" enterDelay={600}>
                            <Stack direction="row" alignItems="center">
                              <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  width: 16,
                                  height: 16,
                                  margin: 0.5,
                                  marginRight: 1,
                                  borderRadius: '50%',
                                  background: pledge.categoryBackground,
                                }}
                              />
                              <Typography
                                sx={{ fontSize: (theme) => theme.typography.pxToRem(14) }}
                              >
                                {pledge.categoryName}
                              </Typography>
                            </Stack>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">{fCurrency(pledge.amount)}</TableCell>
                        <TableCell align="right">{fCurrency(pledge.donationsTotal)}</TableCell>
                      </TableRow>
                    ))}

                    <TableEmptyRows
                      height={44}
                      emptyRows={emptyRows(page, rowsPerPage, pledges.length)}
                    />

                    <TableNoData isNotFound={!pledgesOrdered.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={pledges.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
