import { useMemo, useState } from 'react';
import { TableRow, TableCell, Tooltip, Button } from '@mui/material';
import { BankDeposit } from '@shared/types';
import useLocales from '@hooks/useLocales';
import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { sumBy } from 'lodash';
import { CashCountMoneyType, CashCountOptionsCanada, CashCountOptionsUSA } from '@shared/types/cashCount';
import { BankDepositListItem } from './config';
import CashCountModal from '../../../dialogs/CashCount';
import BankDepositSlipReport from '@pages/reports/Builtin/BankDepositSlip';
import BankDepositDetails from '@pages/reports/Builtin/BankDepositDetails';

// ----------------------------------------------------------------------
type Props = {
  readonly item: BankDepositListItem;
  readonly onEdit: (bankDepositId: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ item, onEdit }: Props) {
  const { org } = useOrg();
  const { donations, cashCounts, getPaymentMethodById } = useDonation();
  const [cashCountOpen, setCashCountOpen] = useState(false);
  const [depositSlipOpen, setDepositSlipOpen] = useState(false);
  const [detailsReportOpen, setDetailsReportOpen] = useState(false);
  const { t } = useLocales();
  const tooltip =
    item.type !== BankDeposit.BankDepositType.cash
      ? `This bank deposit was migrated from classic DONATION, and is not for cash and ${t('Check').toLowerCase()}s, so you cannot currently display or edit it.`
      : '';

  const cashDonations = donations.filter(
    (d) =>
      d.bankDepositId === item.id && getPaymentMethodById(d.paymentMethodId)?.type === 'cash'
  );

  const expectedCashTotal = sumBy(cashDonations, 'amount');
  const cashCount = useMemo(() => cashCounts.find((count) => count.bankDepositId === item.id), [
    cashCounts,
    item.id,
  ]);

  const hasValidCashCount = useMemo(() => {
      if (expectedCashTotal === 0) return true;

      const depositCashCount = cashCounts.find((count) => count.bankDepositId === item.id);
      if (!depositCashCount) return false;

      const options = org?.address.country === 'ca' ? CashCountOptionsCanada : CashCountOptionsUSA;

      const counts = depositCashCount.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.optionsId]: item.count,
        }),
        {} as Record<string, number>
      );

      const coinTotal = sumBy(
        options.filter((opt) => opt.type === CashCountMoneyType.coin),
        (opt) => (counts[opt.id] || 0) * opt.denomination
      );

      const billTotal = sumBy(
        options.filter((opt) => opt.type === CashCountMoneyType.bill),
        (opt) => (counts[opt.id] || 0) * opt.denomination
      );

      const total = coinTotal + billTotal;
      return total === expectedCashTotal;
    }, [expectedCashTotal, item.id, cashCounts, org?.address.country]);


  const handleEdit = (item: BankDepositListItem) => {
    item.type === BankDeposit.BankDepositType.cash ? onEdit(item.id) : void 0;
  };

  return (
    <>
      <TableRow
        hover
        sx={{
          cursor: 'pointer',
          '&.MuiTableRow-hover:hover': {
            background: '#007b5514',
          },
          '& strong': { color: (theme) => theme.palette.secondary.light },
        }}
      >
        <Tooltip title={tooltip} placement="top" onClick={() => handleEdit(item)}>
          <TableCell align="left">{item.date}</TableCell>
        </Tooltip>
        <Tooltip title={tooltip} placement="top" onClick={() => handleEdit(item)}>
          <TableCell align="left">{item.bankAccount}</TableCell>
        </Tooltip>
        <Tooltip title={tooltip} placement="top" onClick={() => handleEdit(item)}>
          <TableCell align="left">{item.accountNumber}</TableCell>
        </Tooltip>
        <Tooltip title={tooltip} placement="top" onClick={() => handleEdit(item)}>
          <TableCell align="left">{item.description || ''}</TableCell>
        </Tooltip>
        <Tooltip title={tooltip} placement="top">
          <TableCell align="left">
            {cashCount ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setCashCountOpen(true);
                }}
              >
                View
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setCashCountOpen(true);
                }}
              >
                Add
              </Button>
            )}
          </TableCell>
        </Tooltip>
        <Tooltip title={tooltip} placement="top">
          <TableCell align="left">{item.totalDeposit}</TableCell>
        </Tooltip>
        <Tooltip title={tooltip} placement="top">
          <TableCell align="center">
            <Button
              onClick={() => setDepositSlipOpen(true)}
              disabled={expectedCashTotal > 0 && !hasValidCashCount}
            >
              Deposit Slip
            </Button>
            {" | "}
            <Button
              onClick={() => setDetailsReportOpen(true)}
            >
              Download Report
            </Button>
          </TableCell>
        </Tooltip>
      </TableRow>

      <CashCountModal
        open={cashCountOpen}
        onClose={() => setCashCountOpen(false)}
        bankDepositId={item.id}
        expectedCashTotal={expectedCashTotal}
        date={new Date(item.date)}
      />

      {depositSlipOpen && (
        <BankDepositSlipReport
          id="bank-deposit-slip"
          name="Bank Deposit Slip"
          description="Generate a deposit slip for bank deposits"
          bdId={item.id}
          onClose={() => setDepositSlipOpen(false)}
        />
      )}

      {detailsReportOpen && (
        <BankDepositDetails
          id="bank-deposit-details"
          name="Bank Deposit Details"
          description="All details of one bank deposit"
          bdId={item.id}
          onClose={() => setDetailsReportOpen(false)}
        />
      )}
    </>
  );
}
