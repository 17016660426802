import { Stripe } from '../types';

// how many donors are allowed, per sub plan, in an org
function donors(productId: Stripe.ProductTierKey | undefined): number {
  switch (productId) {
    case Stripe.ProductTierKey.up_to_100:
      return 100;
    case Stripe.ProductTierKey.up_to_250:
      return 250;
    case Stripe.ProductTierKey.up_to_500:
      return 500;
    case Stripe.ProductTierKey.up_to_1000:
      return 1000;
    case Stripe.ProductTierKey.up_to_5000:
      return 5000;
    case Stripe.ProductTierKey.up_to_20000:
      return 20000;
  }
  return 25;
}

// how many users can be a part of a single organization
function users(orgId?: string): number {
  if (orgId === 'ksXOszWhJHT7T8UYqz54') {
    return 10;
  }
  return 5;
}

// how many organizations can a user be a part of
function orgMember(userId?: string): number {
  return 5;
}

const categories = 100;
const tags = 25;
const paymentMethods = 25;
const donorCustomFields = 6;

const recurringDonationOccurrencesMin = 2;
const recurringDonationOccurrencesMax = 100;

const Limits = {
  donors,
  users,
  orgMember,
  categories,
  tags,
  paymentMethods,
  donorCustomFields,
  recurringDonationOccurrencesMin,
  recurringDonationOccurrencesMax,
};
export { Limits };
