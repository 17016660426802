import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Autocomplete, Container, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import { orderBy, sortBy, uniq } from 'lodash';

import PATHS, { getPath } from '@routes/paths';
import useLocales from '@hooks/useLocales';
import useLocalState from '@hooks/useLocalState';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';

import Page from '@components/Page';
import Iconify from '@components/Iconify';
import builtinReports from './Builtin';
import ReportCard from './ReportCard';
import BreadcrumbsHeader from '@components/BreadcrumbsHeader';

// ----------------------------------------------------------------------
export default function Reports() {
  const navigate = useNavigate();
  const { t } = useLocales();
  const { orgId } = useParams();
  const { org } = useOrg();
  const { hasBankDeposits } = useDonation();
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useLocalState('s4np-reports-sort-v1', 'Alphabetical');
  const [category, setCategory] = useState('All');
  // We need the following state vbl because a dependency on the bookmarkedReports
  // vbl in the useEffect to determine the displayed reports doesn't cause a re-execution
  // if just a property of one of the elements of the array is changed!
  const [reportsString, setReportsString] = useState('');

  type SavedReport = {
    id: string;
    bookmarked: boolean;
    lastUsed: number;
  };

  const { current: initSavedReports } = useRef(new Array<SavedReport>());

  const [savedReports, setSavedReports] = useLocalState('s4np-reports-saved-v1', initSavedReports);

  const bookmarkedReports = useMemo(
    () =>
      builtinReports.map((r, index) => {
        const savedReport = savedReports.find((s) => s.id === r.id);
        if (savedReport) {
          return { ...r, bookmarked: savedReport.bookmarked, lastUsed: savedReport.lastUsed };
        } else {
          return { ...r, bookmarked: false, lastUsed: 0 };
        }
      }),
    [savedReports]
  );

  const [filteredReports, setFilteredReports] = useState(bookmarkedReports);

  // ---------- effects
  useEffect(() => {
    /* Select only the reports matching the string search text, if any, in either the name or the description,
     * that are for the selected category, if any, and omitting certain reports if their functionality isn't configured.
     */
    const filtered = bookmarkedReports.filter(
      (report) =>
        (report.name.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
          report.description.toLowerCase().includes(searchText.toLocaleLowerCase())) &&
        (category === 'All' || report.category === category) &&
        (report.id !== 'pledges' || org?.pledgesEnabled) &&
        (report.id !== 'bank-deposit-details' || hasBankDeposits)
    );
    const sorted =
      sort === 'Alphabetical'
        ? orderBy(filtered, ['bookmarked', 'name'], ['desc', 'asc'])
        : orderBy(filtered, ['bookmarked', 'lastUsed', 'name'], ['desc', 'desc', 'asc']);
    setFilteredReports(sorted);
    // see above for why reportsString is in the dependencies list here!
  }, [
    searchText,
    bookmarkedReports,
    sort,
    category,
    org?.pledgesEnabled,
    hasBankDeposits,
    reportsString,
  ]);

  // ---------- actions
  const handleOpenReport = (reportId: string) => {
    let index = bookmarkedReports.findIndex((r) => r.id === reportId);
    const lastUsed = new Date().getTime();
    if (index > -1) {
      bookmarkedReports[index].lastUsed = lastUsed;
      setReportsString(JSON.stringify(bookmarkedReports));
    } else {
      return;
    }

    index = savedReports.findIndex((r) => r.id === reportId);
    if (index > -1) {
      let newSaved = [...savedReports];
      newSaved[index].lastUsed = lastUsed;
      setSavedReports(newSaved);
    } else {
      setSavedReports([...savedReports, { id: reportId, bookmarked: false, lastUsed: lastUsed }]);
    }
    navigate(getPath(PATHS.org.reports.report, { orgId, reportId }));
  };

  const handleClickBookmark = (reportId: string) => {
    let index = bookmarkedReports.findIndex((r) => r.id === reportId);
    let bookmarked = false;
    if (index > -1) {
      bookmarked = !bookmarkedReports[index].bookmarked;
      bookmarkedReports[index].bookmarked = bookmarked;
      setReportsString(JSON.stringify(bookmarkedReports));
    } else {
      return;
    }

    index = savedReports.findIndex((r) => r.id === reportId);
    if (index > -1) {
      let newSaved = [...savedReports];
      newSaved[index].bookmarked = bookmarked;
      setSavedReports(newSaved);
    } else {
      setSavedReports([...savedReports, { id: reportId, bookmarked: bookmarked, lastUsed: 0 }]);
    }
  };

  const sortOptions = ['Alphabetical', 'Last Used'];

  const categoryOptions = ['All', ...sortBy(uniq(builtinReports.map((report) => report.category)))];

  // ---------- UI
  return (
    <Page title="Reports">
      <Outlet />

      <Container maxWidth={'xl'}>
        <BreadcrumbsHeader heading="Reports" />

        <Stack direction="row" spacing={2} mb={2}>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
            sx={{
              width: { sm: 140, xl: 320 },
              background: (theme) => theme.palette.common.white,
            }}
            inputProps={{ sx: { p: 1 } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Autocomplete
            disablePortal
            id="select-sort"
            size="small"
            options={sortOptions}
            autoComplete
            autoHighlight
            disableClearable
            freeSolo={false}
            value={sort}
            renderInput={(params) => <TextField label="Sort by" {...params} />}
            onChange={(e, newValue) => setSort(newValue || '')}
            sx={{ minWidth: 240 }}
          />

          <Autocomplete
            disablePortal
            id="select-categories"
            size="small"
            options={categoryOptions}
            autoComplete
            autoHighlight
            disableClearable
            freeSolo={false}
            value={category}
            renderInput={(params) => <TextField label="Show reports" {...params} />}
            onChange={(e, newValue) => setCategory(newValue || '')}
            sx={{ minWidth: 240 }}
          />
        </Stack>

        <Grid container spacing={8}>
          <Grid container item xs={12} spacing={1.5}>
            {filteredReports.map((r) => (
              <ReportCard
                key={r.id}
                id={r.id}
                name={t(r.name)}
                description={t(r.description)}
                longDescription={t(r.longDescription || '')}
                onClickReport={() => handleOpenReport(r.id)}
                bookmarked={r.bookmarked}
                onClickBookmark={() => handleClickBookmark(r.id)}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
